<template>
    <div>
        <h4 style="margin-bottom: 2rem;">AUTOMATION SETTINGS</h4>
        <form-lookup id="AutomationType" label="Automation Type <sup style='color: var(--text-danger); font-weight: bold'>1</sup>" v-model="model.type" type="enum" source="cartautomationtype" :defaultValue="100" :sorted="false" :nullable="false" :disabled-type="object" />
        <div v-if="model.type === 300">
            <form-numeric id="DownloadInterval" v-model="model.schedule.hourInterval" suffix=" hour(s)" :min="1" :max="48" />
            <form-datetime v-if="model.schedule.lastRunDate" id="LastRunDate" v-model="model.schedule.lastRunDate" :disabled="true" :showCopy="false" />
        </div>
        <form-lookup v-if="model.type !== 100" id="CaptureType" label="Process Order... <sup style='color: var(--text-danger); font-weight: bold'>2</sup>" v-model="model.captureType" type="enum" source="cartprocesstype" :defaultValue="100" :sorted="false" :nullable="false" />
        <div v-if="model.type !== 100">
            <form-boolean id="allowCapture" label="Notify User? <sup style='color: var(--text-danger); font-weight: bold'>2</sup>" v-model="model.notifyUser" width="135px" style="font-weight: bold; margin-top: -1.25rem !important; " />
        </div>
        <div>
            <ul style="list-style: none; font-size: 0.8rem; padding: 0; margin: 0; text-align: justify;" class="p-code-text">
                <li>
                    <div class="p-d-flex p-jc-start">
                        <div style="white-space: nowrap; margin-right: 0.25rem;"><sup style="font-weight: bold; color: var(--text-danger)">1</sup> </div>
                        <div style="border-left: 1px solid var(--gray-300)">COSMOS offers various automation methods to automatically capture orders from the e-commerce platform. Alternatively, the manual download option is always available <router-link to="/facility/store/shoporders">here</router-link>.</div>
                    </div>
                </li>
                <li>
                    <div class="p-d-flex p-jc-start">
                        <div style="white-space: nowrap; margin-right: 0.25rem;"><sup style="font-weight: bold; color: var(--text-danger)">2</sup> </div>
                        <div style="border-left: 1px solid var(--gray-300)">This will prompt COSMOS to how the record will be processed once received from the system.</div>
                    </div>
                </li>
                <li>
                    <div class="p-d-flex p-jc-start">
                        <div style="white-space: nowrap; margin-right: 0.25rem;"><sup style="font-weight: bold; color: var(--text-danger)">3</sup> </div>
                        <div style="border-left: 1px solid var(--gray-300)">This will prompt COSMOS to send a flag to the e-commerce platform to trigger an internal notification.</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: "StoreOrderAutomationForm",
        props: {
            modelValue: { type: Object, default: () => { } },
            parentID: { type: Number, default: null },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: {
                    type: 100,
                    captureType: 200,
                    fulfillOrder: false,
                    partialFulfillment: false,
                    notifyUser: false,
                    schedule: {
                        hourInterval: 1,
                        lastRunDate: null,
                        nextRunDate: null
                    }
                }
            }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            model() { this.onSync(); }
        },
        methods: {
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }
    }
</script>