<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" :viewOnly="model.status > 100">
        <pv-progress-spinner v-if="(model?.id ?? 0) <= 0" style="margin-top: calc(50vh - 10rem); left: calc(50% - 5rem); position: absolute; height: 0.01rem !important; width: 0.01rem !important ">PLEASE WAIT</pv-progress-spinner>
        <div v-else style="width: calc(100% + 2rem); padding: 0; margin: -2rem -1rem; margin-bottom:1rem;">
            <pv-accordion :multiple="true" :activeIndex="allActiveIndex" id="SalesOrderAccordion" @tab-open="onOpen" @tab-close="onClose">
                <pv-accordion-tab v-if="model?.status > 100">
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; SUMMARY</div>
                            </div>
                        </div>
                    </template>
                    <div class="p-fluid p-grid p-d-flex" style="margin-top: -2rem; margin-bottom: -2rem;">
                        <div class="p-col-12 p-md-6 p-lg-6 p-as-stretch">
                            <div style="height:50%">
                                <h4>DELIVERY DETAIL <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.shipTo_CountryID)[0]?.code?.toLowerCase()}`" style="margin-top: -0.25rem;"></i> </h4>
                                <div class="card p-as-stretch" style="background: var(--surface-a); height:85%">
                                    <div class="p-fluid p-grid">
                                        <div class="p-col-12 p-md-12">
                                            <form-contact-info id="shipToContactView" :v$="v$" :modelValue="model.shipToContact" :useRegistrationNumber="true" :showDirectory="false" :disabled="true" disabledView="perline " />
                                            <form-address-info id="shipToAddressView" :validateState="true" :validatePostalCode="true" :v$="v$" :modelValue="model.shipToAddress" :useCompany="true" :forceEnableCompany="true" :showLookup="true" :disabled="true" disabledView="perline" />
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            <div style="height:50%">
                                <h4>BILLING DETAIL <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == model?.soldTo_CountryID)[0]?.code?.toLowerCase()}`" style="margin-top: -0.25rem;"></i> </h4>
                                <div class="card p-as-stretch" style="background: var(--surface-a); height: 85%">
                                    <div class="p-fluid p-grid">
                                        <div class="p-col-12 p-md-12">
                                            <form-contact-info id="soldToContactView" :value="soldToContact" :v$="v$" :modelValue="model.soldToContact" :useRegistrationNumber="true" :showDirectory="false" :disabled="true" disabledView="perline" />
                                            <form-address-info id="soldToAddressView" :value="soldToAddress" :validateState="true" :validatePostalCode="true" :v$="v$" :modelValue="model.soldToAddress" :useCompany="true" :forceEnableCompany="true" :showLookup="true" :disabled="true" disabledView="perline" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 p-as-stretch">
                            <h4>ORDER DETAIL</h4>
                            <div class="card" style="background: var(--surface-a);">
                                <div class="p-fluid p-grid">
                                    <div class="p-col-12 p-md-12">
                                        <table style="width: 100%; padding: -2rem -1rem; margin-top:-1rem;" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                        <h4 style="margin: -0.25rem -0.5rem;">
                                                            {{customerText}}
                                                        </h4>
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Transaction ID</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;">{{model?.code}}</div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 1</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.reference_01}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 2</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.reference_02 ?? ''}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 3</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.reference_03 ?? ''}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">PO Reference</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.pOReference ?? ''}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Order Date</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatDateUTCFormat(model?.orderDate,'DD/MMM/YYYY HH:mm')}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Processed Date</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatDateUTCFormat(model?.createDate,'DD/MMM/YYYY HH:mm')}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Credit Card Number</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.creditCardNumber ?? ''}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Instruction</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.instruction ?? ''}`"></div></td>
                                                </tr>

                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Currency</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$currencies?.filter((x) => x.id == model?.currencyID)[0]?.code?.toUpperCase()}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Weight Unit</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['weightunit'].filter((x) => x.id == model?.weightUnit)[0]?.text?.toUpperCase()}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Length Unit</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['lengthunit'].filter((x) => x.id == model?.lengthUnit)[0]?.text?.toUpperCase()}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Incoterm</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$vm.$enums['incotermtype'].filter((x) => x.id == model?.incoterm)[0]?.text}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Shipping Cost</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${$filters.formatCurrency(model?.shippingCost ?? 0.00,$vm.$currencies?.filter((x) => x.id == model?.currencyID)[0]?.code?.toUpperCase(),$vm.$countries?.filter((x) => x.id == model?.shipFrom_CountryID)[0]?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Shipping Method</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model.serviceGroup}`"></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <h4>ALLOCATION DETAIL</h4>
                            <div class="card" style="background: var(--surface-a);">
                                <div class="p-fluid p-grid">
                                    <div class="p-col-12 p-md-12">
                                        <table style="width: 100%; padding: -2rem -1rem; margin-top:-1rem;" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                        <h4 style="margin: -0.25rem -0.5rem;">
                                                            CONSIGNMENT
                                                        </h4>
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Transaction ID</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;"><a :href="`/track/${model?.consignmentReference}`" target="_self" style="font-weight: bold;">{{model?.consignmentReference}}</a></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference 1</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;"><a :href="`/transaction/consignment/${model?.consignmentReference}/code`" target="_self" style="font-weight: bold;">{{model?.reference_01}}</a></div></td>
                                                </tr>
                                                <tr>
                                                    <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) ">
                                                        <h4 style="margin: -0.25rem -0.5rem;">
                                                            CARRIER
                                                        </h4>
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Reference</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;"><a :href="`${model?.trackingURL}`" target="_blank" style="font-weight: bold;"> {{model?.carrierReference}} </a></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.carrier ?? ''}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                                                    <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                                                    <td class="p-code-text" style="word-break: break-word; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div style="font-family: inherit !important;" v-html="`${model?.service ?? ''}`"></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab v-if="model.status <= 100">
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; DELIVERY & BILLING DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div class="p-fluid p-grid p-d-flex">
                        <div class="p-col-12 p-md-6 p-lg-6 p-as-stretch">
                            <div class="card" style="background: var(--surface-a); height: 100%">
                                <h6>DELIVERY DETAIL</h6>
                                <div class="p-fluid p-grid">
                                    <div class="p-col-12 p-md-6">
                                        <form-address-info id="shipToAddress" :value="model.shipToAddress" :validateState="true" :validatePostalCode="true" :v$="v$" :modelValue="model.shipToAddress" :useCompany="true" :forceEnableCompany="true" :showLookup="true" />
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <form-contact-info id="shipToContact" :value="model.shipToContact" :v$="v$" v-model="model.shipToContact" :useRegistrationNumber="true" :showDirectory="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 p-as-stretch">
                            <div class="card" style="background: var(--surface-a); height: 100%">
                                <h6>BILLING DETAIL</h6>
                                <div class="p-fluid p-grid">
                                    <div class="p-col-12 p-md-6">
                                        <form-address-info id="soldToAddress" :value="model.soldToAddress" :validateState="true" :validatePostalCode="true" :v$="v$" :modelValue="model.soldToAddress" :useCompany="true" :forceEnableCompany="true" :showLookup="true" />
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <form-contact-info id="soldToContact" :value="model.soldToContact" :v$="v$" v-model="model.soldToContact" :useRegistrationNumber="true" :showDirectory="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab v-if="model.status <= 100">
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; ORDER DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div class="p-fluid p-grid p-d-flex">
                        <div class="p-col-12 p-md-6 p-lg-6 p-as-stretch">
                            <div style="height:100%">
                                <div class="card" style="background: var(--surface-a); height: 100%">
                                    <h6>ORDER DETAIL</h6>
                                    <div class="p-fluid p-grid">
                                        <div class="p-col-12 p-md-6">
                                            <form-input id="TransactionID" label="Transaction ID" v-model="model.code" :disabled="true" />
                                            <form-input id="Reference_1" v-model="model.reference_01" label="Reference 1" :disabled="true" :show-copy="false" />
                                            <form-input id="Reference_2" v-model="model.reference_02" label="Reference 2" :disabled="true" :show-copy="false" />
                                            <form-input id="Reference_3" v-model="model.reference_03" label="Reference 3" :disabled="true" :show-copy="false" />
                                            <form-input id="pOReference" v-model="model.pOReference" label="PO Reference" :disabled="true" :show-copy="false" />
                                            <form-datetime id="orderDate" :value="new Date(model.orderDate)" :disabled="true" :show-copy="false" />
                                            <form-datetime id="processDate" :value="new Date(model.createDate)" :disabled="true" :show-copy="false" />
                                            <form-input id="creditCardNumber" v-model="model.creditCardNumber" :disabled="true" :show-copy="false" />
                                        </div>
                                        <div class="p-col-12 p-md-6">
                                            <form-lookup id="currencyID" :v$="v$" v-model="model.currencyID" :required="true" :disabled="true" label="Currency" source="currency" :value="model.currencyID" :show-copy="false" />
                                            <form-lookup id="weightUnit" :v$="v$" v-model="model.weightUnit" :required="true" :disabled="true" label="Weight Unit" type="enum" source="weightunit" :value="model.weightUnit" :show-copy="false" />
                                            <form-lookup id="lengthUnit" :v$="v$" v-model="model.lengthUnit" :required="true" :disabled="true" label="Length Unit" type="enum" source="lengthunit" :value="model.lengthUnit" :show-copy="false" />
                                            <form-input id="instruction" v-model="model.instruction" label="Instruction" :disabled="false" :show-copy="false" :max-length="255" />
                                            <form-lookup id="incoterm" :v$="v$" v-model="model.incoterm" :required="true" label="Incoterms" type="enum" source="incotermtype" :value="model.incoterm" />
                                            <form-numeric id="shippingCost" v-model="model.shippingCost" label="Shipping Cost" decimalDigit="2" type="decimal"></form-numeric>
                                            <form-input id="serviceGroup" v-model="model.serviceGroup" label="Shipping Method" :disabled="true" :show-copy="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 p-as-stretch">
                            <div style="height:100%">
                                <div class="card" style="background: var(--surface-a); height: 100%">
                                    <h6>ALLOCATION DETAIL</h6>
                                    <div class="p-fluid p-grid">
                                        <div class="p-col">
                                            <div class="p-fluid p-grid" style="padding: 0.30rem;">
                                                <div class="p-col-12 p-md-12">
                                                    <div class="p-fluid p-grid">
                                                        <div class="p-col-12 p-md-6">
                                                            <form-lookup id="customerID" :required="true" label="Customer" :source="scopeCustomerDS" :v$="v$" :textField="'name'" :valueField="'id'" v-model="model.customerID" :disabled="true" :showCopy="false" />
                                                        </div>
                                                        <div class="p-col-12 p-md-6">
                                                            <form-lookup id="warehouseID" :required="true" label="Warehouse" :source="scopeWarehouseDS" :v$="v$" :textField="'name'" :valueField="'id'" v-model="model.warehouseID" :disabled="true" :showCopy="false" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-fluid p-grid" style="padding: 0.30rem;">
                                                <allocate-form :lookupSource="lookupSource" :originAddress="warehouseAddress" :destinationAddress="model.shipToAddress" :customerID="model.customerID" :warehouseID="model.warehouseID" :weightUnit="model.weightUnit" :lengthUnit="model.lengthUnit" :currencyID="model.currencyID" :shippingServiceName="model.serviceGroup" :packages="packages" :salesOrderParameter="salesOrderParameter" @is-refresh="onAllocateSuccess" :isValid="!v$.$invalid && !v$.$dirty"></allocate-form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; ITEM DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                        <item-grid :salesOrder="model" v-model="model.items"></item-grid>
                    </div>
                </pv-accordion-tab>
            </pv-accordion>
        </div>
    </form-record>
</template>

<script>
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    import itemGrid from '@/components/facility/marketplace/salesorder/salesorderitem-form.vue';
    import allocateForm from '@/components/facility/marketplace/salesorder/carrierservice-form.vue';
    export default {
        name: "StoreEditForm",
        mixins: [mixins.RECORD],
        components: {
            'item-grid': itemGrid,
            'allocate-form': allocateForm,
        },
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        data() {
            return {
                payload: null,
                platform: null,
                validation: {
                    credential: false,
                    basicInfo: false,
                    notification: false,
                    defaults: false,
                },
                activeIndex: [1, 2],
                sources: {
                    carts: []
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID ?? -100, this.currentWarehouse ?? this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            customerText() {
                let _customer = (this.scopeCustomerDS ?? []).filter(x => x.id == this.model.customerID)[0];
                return _customer?.altName ?? _customer?.name;
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            warehouse() {
                return this.scopeWarehouseDS.filter(x => x.id === this.model?.warehouseID)[0];
            },
            environment() { return (this.$config.common.$environment() ?? []).filter(x => x.id == this?.model.environmentID)[0]},
            cart() { return (this.sources?.carts ?? []).filter(x => x.id == this.model?.cartID)[0]}
            ,
            warehouseAddress() {
                return {
                    company: this.customerText,
                    address1: this.warehouse.address1,
                    address2: this.warehouse.address2,
                    address3: this.warehouse.address3,
                    city: this.warehouse.city,
                    state: this.warehouse.state ?? "",
                    postalCode: this.warehouse.postalCode,
                    countryID: this.warehouse.countryID,
                    establishmentType: 300
                }
;
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            common() { return this.$config.common; }
            , allActiveIndex() { return this.activeIndex }
            ,lookupSource() {
                return {
                    customers: this.scopeCustomerDS ?? [],
                    warehouses: this.scopeWarehouseDS ?? [],
                }
            },
            salesOrderRecord() { return this.$store.getters["salesorder/record"]; },
            salesOrderParameter() {
                return {
                    salesOrder: { mask: this.model?.mask },
                    cart: { mask: this.cart?.mask },
                    customer: { mask: (this.scopeCustomerDS ?? []).filter(x => x.id == this.model.customerID)[0]?.mask },
                    environment: { mask: this.environment?.mask}
                };
            }
            ,packages() {
                let _package = [];
                let _totaldeclaredvalue = 0;
                let _totalinsuranceValue = 0;
                let _totaldutyPaidValue = 0;
                let _totaltaxPaidValue = 0;
                let _totalcustomsValue = 0;
                let _totaldiscountValue = 0;
                let _totalweight = 0;
                _totalcustomsValue;
                _totalinsuranceValue;
                (this.model?.items ?? []).forEach((e) => {
                    _totalweight = _totalweight + (e.unitWeight * e.quantity);
                    _totaldeclaredvalue = _totaldeclaredvalue + (e.retailPrice * e.quantity);
                    _totaldutyPaidValue = _totaldutyPaidValue + (e.dutyPrice * e.quantity);
                    _totaltaxPaidValue = _totaltaxPaidValue + (e.taxPrice * e.quantity);
                    _totaldiscountValue = _totaldiscountValue + (e.discountPrice * e.quantity);
                }, this);
                _package.push({
                    consignmentValue: _totaldeclaredvalue.toFixed(2),
                    insuranceValue: 0,
                    dutyValue: _totaldutyPaidValue.toFixed(2),
                    taxValue: _totaltaxPaidValue.toFixed(2),
                    customsValue: 0,
                    discountValue: _totaldiscountValue.toFixed(2),
                    weight:  Number(_totalweight).toFixed(3),
                });

                return _package;
            }
            
        }, 
        watch: {
            'model.id': {
                handler() {
                    this.getSources();
                    this.$nextTick(() => {
                        this.getCarts();
                    });
                    
                }
            },
            'model.status': {
                handler() {
                    if (this.model.status > 100) {
                        this.activeIndex = [0, 1, 2];
                    }
                    else {
                        this.activeIndex = [0, 1, 2];
                    }

                }
            },
            $route(to, from) {
                if (
                    (to?.params?.allocated ?? from?.params?.allocated ?? false) || (to?.params?.deallocated ?? from?.params?.deallocated ?? false) || (to?.params?.cloned ?? from?.params?.cloned ?? false)
                ) {
                    this.$nextTick(() => {
                        this.model = this.init;
                        if (to?.params.mask ?? from?.params.mask)
                            this.get(to?.params.mask ?? from?.params.mask).then((response) => {
                                this.model = response?.result ?? this.record;
                                this.$nextTick(() => { this.v$.$reset(); });
                            });
                        this.activity.submitted = this.$vm.$submitted = false;
                    });
                }

            },
        },
        methods: {
            async getEntityRecord(mask) { return await this.$store.dispatch("salesorder/getRecord", mask); },
            onAllocateSuccess() {
                let _self = this;
                _self.getEntityRecord(this.model?.mask).then((response) => {
                    _self.model = response?.result ?? _self.salesOrderRecord;
                    _self.$toast.add({ severity: 'success', summary: 'Record Allocate Success!', detail: 'The record was allocated successfully.', life: 6000 });
                    _self.$router.push({ name: 'salesoder-edit', params: { mask: _self.model.mask } });
                })
            },
            getCarts() {
                this.$store.dispatch("dynamicreport/getURL", { url: `/services/facility/cart` }).then((response) => {
                    let carts = [];
                    ((response?.result ?? []).filter(x => x.status > 100) ?? []).forEach((x) => carts.push(x));
                    this.sources.carts = carts;
                });
            },
            getSources() {
                if (this.model?.mask)
                    this.$store.dispatch("store/getURL", { url: `/services/facility/salesorderitem/bysalesorder/${this.model.mask}`.replaceAll("//", "/") })
                        .then((response) => {
                            this.model.items = response?.result ?? [];
                            this.activity.loading = false;
                        });
            }
            ,onOpen(e) {
                var index = this.activeIndex.findIndex(x => x == e.index);
                if (index === -1)
                    this.activeIndex.push(e.index);
            }
            ,onClose(e) {
                var index = this.activeIndex.findIndex(x => x == e.index);
                if (index !== -1)
                    this.activeIndex.splice(index, 1);
            }
        },
        mounted() {
            if (this.model?.mask) {
                this.getSources();
            }
                
        },
        created() {
            //this.accessScope = this.common.$accessScope(this.user.mask);
        }
    }
</script>
<style>
    #SalesOrderAccordion .p-accordion-header-link {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid var(--gray-200);
    }

    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child),
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child) {
        width: 100% !important;
    }

    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:first-child,
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > th:first-child {
        max-width: 2rem !important;
    }
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr.p-datatable-emptymessage > td {
        max-width: 100% !important;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr td:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr th:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
</style>