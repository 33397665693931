<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" :saveOnly="model.status == 200">
        <div class="p-grid">
            <div class="p-col-12 p-md-6 p-lg-4">
                <h4 style="margin-bottom: 1.5rem; padding: 0 0.5rem;">BASIC INFO</h4>
                <form-lookup id="Customer" :disabled="model.status == 200" v-model="model.customerID" :defaultValue="accessScope.customers[0]?.id" :source="accessScope.customers" :required="true" @is-sync="customer = $event" :show-copy="false" />
                <basicinfo-form v-model="model"
                                v-model:modelInvalid="validation.basicInfo" />
            </div>
            <div class="p-col-12 p-md-6 p-lg-4">
                <h4 style="margin-bottom: 1.5rem; padding: 0 0.5rem;">CREDENTIALS</h4>
                <credential-form v-model="model.credentials"
                                 v-model:modelPlatform="platform"
                                 v-model:modelInvalid="validation.credential"
                                 v-model:modelPayload="payload"
                                 :disabled="model.status == 200" />
            </div>
        </div>
        <pv-tab style="margin: 0 -1rem;">
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-map-pin fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Shop and Locations</span>
                    </div>
                </template>
                <div class="p-grid" style="margin-top: 1rem; padding: 0 1rem;">
                    <div class="p-col-12 p-lg-6 p-xl-4">
                        <instruction-form v-model="model.shopInfo"
                                          v-model:modelPlatform="platform"
                                          :parentID="model.id" />
                    </div>
                    <div class="p-col-12 p-lg-6 p-xl-4">
                        <location-form v-model="model.locations"
                                       v-model:modelPlatform="platform"
                                       v-model:modelAccessScope="accessScope"
                                       :printers="printers"
                                       :customerID="model.customerID" />
                    </div>
                </div>
            </pv-tabpanel>

            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-bars fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Defaults<i v-if="validation.defaults || validation.notification" class="ml-2 fas fa-triangle-exclamation fa-fw" style="margin-left: 0.5rem; color: var(--text-danger)"></i></span>
                    </div>
                </template>
                <div class="p-grid" style="margin-top: 1rem; padding: 0 1rem;">
                    <div class="p-col-12 p-xl-8">
                        <defaults-form :customerID="model.customerID"
                                       v-model="model.defaults"
                                       v-model:modelAccessScope="accessScope"
                                       v-model:modelInvalid="validation.defaults"
                                       :oneColumn="false" />
                    </div>

                </div>
                <div class="p-grid" style="margin-top: 1rem; padding: 0 1rem;">
                    <div class="p-col-12 p-lg-6 p-xl-4">
                        <printer-form v-if="model.defaults" v-model="model.defaults.warehouseID"
                                      v-model:modelPrinter="model.printers"
                                      v-model:modelAccessScope="accessScope"
                                      :customerID="model.customerID"
                                      :printers="printers" />
                    </div>
                    <div class="p-col-12 p-lg-6 p-xl-4">
                        <notification-form v-model="model.notifications"
                                           v-model:modelInvalid="validation.notification" />
                    </div>
                </div>
            </pv-tabpanel>
            <pv-tabpanel>
                <template #header>
                    <div class="p-d-flex p-ai-center gap-2">
                        <i class="fa fa-cogs fa-fw mx-2"></i>
                        <span style="font-weight: 700; white-space: nowrap; margin-left: 0.5rem;">Automation</span>
                    </div>
                </template>
                <div style="margin-top: 1.5rem; padding: 0 1rem;">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6 p-xl-4">
                            <orderautomation-form v-model="model.orderAutomation" />
                        </div>
                        <div class="p-col-12 p-md-6 p-xl-4">
                            <webhook-form v-if="(model?.orderAutomation?.type ?? 100) == 200"
                                          v-model="model.webhooks"
                                          v-model:modelPlatform="platform"
                                          v-model:modelAccessScope="accessScope"
                                          :parentMask="model.mask"
                                          :parentID="model.Id"
                                          :customerID="model.customerID"
                                          @is-force-refresh="getSources()" />
                        </div>
                    </div>
                </div>
            </pv-tabpanel>
        </pv-tab>
    </form-record>
</template>
<script>
    import credentialForm from '@/components/facility/marketplace/cart/credential-form.vue';
    import instructionForm from '@/components/facility/marketplace/cart/instruction-form.vue';
    import locationForm from '@/components/facility/marketplace/cart/location-form.vue';
    import webhookForm from '@/components/facility/marketplace/cart/webhook-form.vue';
    import basicInfo from '@/components/facility/marketplace/cart/basicinfo-form.vue';
    import defaultForm from '@/components/facility/marketplace/cart/defaults-form.vue';
    import notificationForm from '@/components/facility/marketplace/cart/notification-form.vue';
    import orderAutomationForm from '@/components/facility/marketplace/cart/orderautomation-form.vue';
    import printerForm from '@/components/facility/marketplace/cart/printer-form.vue';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    export default {
        name: "CartEditForm",
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        components: {
            'credential-form': credentialForm,
            'instruction-form': instructionForm,
            'location-form': locationForm,
            'webhook-form': webhookForm,
            'basicinfo-form': basicInfo,
            'defaults-form': defaultForm,
            'orderautomation-form': orderAutomationForm,
            'notification-form': notificationForm,
            'printer-form': printerForm
        },
        data() {
            return {
                payload: null,
                platform: null,
                printers: [],
                settings: null,
                customer: null,
                validation: {
                    credential: false,
                    basicInfo: false,
                    notification: false,
                    defaults: false,
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            common() { return this.$config.common; }
        }, 
        watch: {
            'model.id': {
                handler() {
                    this.getSources();
                }
            },
            'customer': {
                handler() {
                    this.getPrinters(this.customer?.record?.mask);
                    this.getSetting(this.customer?.record?.mask);
                }, deep: true, immediate: true
            }
        },
        methods: {
            getSetting(mask) {
                if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null) {
                    this.$nextTick(() => {
                        this.$axios
                            .get(this.$config.config.endpoint.api + `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this?.customer?.mask)}`.replaceAll("//", "/"))
                            .then((response) => {
                                this.settings = response?.data?.result ?? response?.result ?? null;
                            })
                            .catch((ex) => {
                                console.log(ex);
                            });
                    });
                }
            },
            getPrinters(mask) {
                if (mask)
                    this.$store.dispatch("cart/getURL", { url: `/services/settings/companyprinter/company/${mask}/active` })
                        .then((response) => {
                            this.printers = response?.result ?? [];
                        })
            },
            getSources() {
                this.$store.dispatch("store/getURL", { url: `/services/facility/cart/${this.model.mask}/locations`.replaceAll("//", "/") })
                    .then((response) => {
                        this.model.locations= response?.result ?? [];
                    });
                this.$store.dispatch("store/getURL", { url: `/services/facility/cart/${this.model.mask}/webhooks`.replaceAll("//", "/") })
                    .then((response) => {
                        this.model.webhooks = response?.result ?? [];
                    });
            }
        },
        mounted() {
            //if (this.model?.mask)
            //    this.getSources();
        },
        created() {
            this.accessScope = this.common.$accessScope(this.user.mask);
        }
    }
</script>
<style>
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child),
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:not(:first-child) {
        width: 100% !important;
    }

    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > td:first-child,
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr > th:first-child {
        max-width: 2rem !important;
    }
    .is-customized.p-datatable.p-component.p-datatable-scrollable tr.p-datatable-emptymessage > td {
        max-width: 100% !important;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr td:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
    .is-customized .p-datatable.p-component.p-datatable-scrollable tr th:not(:first-child) {
        width: 100% !important;
        flex: none;
    }
</style>