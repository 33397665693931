<template>
    <div>
        <h4 style="margin-bottom: 2rem;">SHOP LOCATIONS</h4>
        <pv-listbox :disabled="true" :options="model" listStyle="max-height:450px" optionLabel="displayName">
            <template #option="slotProps">
                <div class="p-d-flex p-jc-between" style="position: relative;width: 100%;">
                    <div class="flex-1 flex gap-2">
                        <div v-if="!slotProps.option.isActive && !slotProps.option.isDefault" style="background: var(--text-danger); padding: 0.25rem 1rem; color: white; font-weight: bold; border-radius: 4px !important;">DISABLED</div>
                        <div v-else-if="slotProps.option.isDefault" style="background: var(--text-success); padding: 0.25rem 1rem; color: white; font-weight: bold; border-radius: 4px !important;">DEFAULT</div>
                    </div>
                    <div class="flex-0 flex gap-2 relative">
                        <pv-button v-if="!disabled" label="Configure" icon="fa fa-cogs fa-fw" style="z-index: 999" @click="onLinkWarehouse(slotProps.option)"></pv-button>
                    </div>
                </div>
                <div style="position: relative;width: 100%; padding: 0.5rem 0.25rem">
                    <div style="text-transform: uppercase; font-weight: bold;">{{ platform?.name?.toUpperCase() }} LOCATION:</div>
                    <div class="p-d-flex p-jc-between" style="position: relative; width: calc(100% - 2rem); font-size: 1.1rem; font-weight: bold; margin: 0.5rem 1rem;">
                        <div class="flex-0 flex gap-2">
                            {{ slotProps.option.name }}
                        </div>
                        <div class="flex-0 flex gap-2 relative p-code-text">
                            {{ slotProps.option.code }}
                        </div>
                    </div>
                    <div class="p-d-flex p-jc-start" style="position: relative; width: calc(100% - 2rem); margin: 0.5rem 1rem; ">
                        <div class="flex-1 flex gap-2">
                            <i class="fa fa-warehouse fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i>
                        </div>
                        <div class="flex-0 flex gap-2 relative">
                            {{ slotProps.option.address1 ? slotProps.option.address1 + ', ' : '' }}{{ slotProps.option.address2 ? slotProps.option.address2 + ', ' : '' }}{{ slotProps.option.address3 ? slotProps.option.address3 + ', ' : '' }}{{ slotProps.option.city ? slotProps.option.city + ', ' : '' }}{{ slotProps.option.state ? slotProps.option.state + ', ' : '' }}{{ slotProps.option.postalCode ? slotProps.option.postalCode + ', ' : '' }}{{ slotProps.option.country ? slotProps.option.country : '' }}
                        </div>
                    </div>
                </div>
                <div style="position: relative;width: 100%; padding: 0.5rem 0.25rem">
                    <b>COSMOS WAREHOUSE: <span v-if="!slotProps.option.warehouseID" style='color: var(--text-danger)'>NOT MAPPED YET</span></b>
                    <div v-if="slotProps.option.warehouseID" class="p-d-flex p-jc-between" style="position: relative; width: calc(100% - 2rem); font-size: 1.1rem; font-weight: bold; margin: 0.5rem 1rem; ">
                        <div class="flex-1 flex gap-2">
                            {{ warehouses.filter(x => x.id === slotProps.option.warehouseID)[0]?.altName  ?? warehouses.filter(x => x.id === slotProps.option.warehouseID)[0]?.name }}
                        </div>
                        <div class="flex-0 flex gap-2 relative p-code-text">
                            {{ warehouses.filter(x => x.id === slotProps.option.warehouseID)[0]?.code }}
                        </div>
                    </div>
                    <div v-if="(slotProps.option.warehouseID ?? 0) > 0" class="p-d-flex p-jc-start" style="position: relative; width: calc(100% - 2rem); margin: 0.5rem 1rem; ">
                        <div class="flex-1 flex gap-2">
                            <i class="fa fa-warehouse fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i>
                        </div>
                        <div class="flex-0 flex gap-2 relative">
                            {{  onGetWarehouseAddress(slotProps.option.warehouseID) }}
                        </div>
                    </div>
                </div>
            </template>
        </pv-listbox>
        <is-dialog id="location-form" header="Location Mapping" :breakpoints="{'2500px': '50vw', '640px': '75vw'}" :visible="dialog.link" @is-confirm="onConfirm(location)" @is-cancel="dialog.link = false" :confirmDisabled="confirmDisabled">
            <p>This below configuration will route specific shop location transaction to the nominated COSMOS warehouse.</p>
            <div class="p-grid p-nogutter">
                <div class="p-col-12">
                    <div style="background: var(--surface-c); padding: 1rem; border-radius: 4px; border: 1px solid var(--surface-d);">
                        <h6 style="margin: 0 -1rem 1rem;">{{platform?.name?.toUpperCase()}} LOCATION DETAILS:</h6>
                        <div class="p-d-flex p-jc-between" style="position: relative;width: 100%; font-size: 1.2rem; font-weight: bold">
                            <div class="flex-1 flex gap-2">
                                {{ location.name }}
                            </div>
                            <div class="flex-0 flex gap-2 relative p-code-text">
                                {{ location.code }}
                            </div>
                        </div>
                        <div class="p-d-flex p-jc-start" style="position: relative;width: 100%;">
                            <div class="flex-1 flex gap-2">
                                <i class="fa fa-warehouse fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i>
                            </div>
                            <div class="flex-0 flex gap-2 relative">
                                {{ location.address1 ? location.address1 + ', ' : '' }}{{ location.address2 ? location.address2 + ', ' : '' }}{{ location.address3 ? location.address3 + ', ' : '' }}{{ location.city ? location.city + ', ' : '' }}{{ location.state ? location.state + ', ' : '' }}{{ location.postalCode ? location.postalCode + ', ' : '' }}{{ location.country ? location.country : '' }}
                            </div>
                        </div>
                        <div style="padding-top: 1rem;">
                            <h6 style="margin: 0 -1rem 2rem;">COSMOS WAREHOUSE:</h6>
                            <div style="margin: 0.5rem -1rem 0;"><form-lookup id="warehouse" label="Mapped To" :source="warehouses" v-model="location.warehouseID" :default-value="-10000" :required="location.isActive" @is-sync="onWarehouseSelect" /></div>
                            <div v-if="(location.warehouseID ?? 0) > 0" class="p-d-flex p-jc-start" style="position: relative;width: 100%;">
                                <div class="flex-1 flex gap-2">
                                    <i class="fa fa-warehouse fa-fw" style="margin-right: 1rem !important; font-size: 0.9rem !important;"></i>
                                </div>
                                <div class="flex-0 flex gap-2 relative">
                                    {{ onGetWarehouseAddress(location.warehouseID) }}
                                </div>
                            </div>
                            <div v-if="(location.warehouseID ?? 0) > 0" style="padding-top: 1rem; margin: 0.5rem -1rem 0;">
                                <form-lookup id="autoPrinting" type="enum" source="printersettingusage" v-model="location.autoPrinterSetting" :default-value="100" :required="true" @is-sync="onGetPrinters" :sorted="false" />
                                <form-lookup v-if="location.autoPrinterSetting !== 100" id="labelPrinter" label="Label Printer" :source="printerSource" v-model="location.labelPrinterID" :default-value="location.labelPrinterID" :required="location.autoPrinterSetting" />
                                <form-lookup v-if="location.autoPrinterSetting !== 100" id="documentPrinter" label="Document Printer" :source="(printerSource ?? []).filter(x => x.type === 2)" v-model="location.documentPrinterID" :default-value="location.labelPrinterID" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </is-dialog>
    </div>
</template>
<script>
    export default {
        name: "CartLocationForm",
        props: {
            customerID: { type: Number, default: 0 },
            modelValue: { type: Array, default: () => [] },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
            disabled: { type: Boolean, default: false },
            printers: { type: Array, default: null }
        },
        data() {
            return {
                model: [],
                location: null,
                companies: [],
                platform: null,
                accessScope: null,
                dialog: {
                    link: false
                }
            }
        },
        computed: {
            countries() { return this.$vm.$countries ?? []; },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            warehouses() { return [{ "id": -10000, "id2": null, "mask": null, "code": null, "name": "Do not capture from this location", "text": "Do not capture from this location", "customers": [] }, ...this.accessScope.warehouses.filter(x => x.customers.filter(y => y === this.customerID).length > 0)]; },
            printerSource() { return [...new Set(this.printers ?? [])].filter(x => x.companyID === ((this.location?.autoPrinterSetting ?? 100) === 100 ? -10000 : (this.location?.autoPrinterSetting === 300 ? this.location?.warehouseID : this.customerID))) ?? []; }
        },
        watch: {
            modelValue() { this.model = this.modelValue ?? []; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); }
        },
        methods: {
            onWarehouseSelect() {
                this.location.autoPrinterSetting = 100;
                this.location.labelPrinterID = null;
                this.location.documentPrinterID = null;
            },
            onGetPrinters() {
                this.location.labelPrinterID = null;
                this.location.documentPrinterID = null;
            },
            onGetWarehouseAddress(id) {
                if (id) {
                    let company = this.companies.filter(x => x.id === id)[0];
                    let country = this.countries.filter(x => x.id === company.countryID)[0];
                    return `${(company.address1 ? company.address1 + ', ' : '')}${(company.address2 ? company.address2 + ', ' : '')}${(company.address3 ? company.address3 + ', ' : '')}${(company.city ? company.city + ', ' : '')}${(company.state ? company.state + ', ' : '')}${(company.postalCode ? company.postalCode + ', ' : '')}${(country ? country.code: '')}`;
                }
                return null;
            },
            onLinkWarehouse(data) {
                this.location = this.$filters.init(data);
                this.dialog.link = true;
            },
            onConfirm(data) {
                var index = this.model.findIndex(x => x.code === data.code);
                this.model[index] = data;
                this.dialog.link = false;
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            this.companies = this.$config.common.$warehouse(this.sessionKeys.environment);
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>
<style>
    #location-form .p-dialog-content {
        overflow: hidden !important;
    }
</style>