import { createStore } from 'vuex';
import authapi from './accounts/auth-service.js';
const newStore = require("@/assets/lib/cosmos/_js/cosmos-store.js").default;
import vm from "@/assets/_js/cosmos.js";
vm.init(vm);
export default createStore({
    modules: {
        // Authentication Module
        auth: authapi,
        // Referentials
        country: newStore("country", "referential", "country", vm.$model.countrymodel, vm.$model.countrymodel),
        currency: newStore("currency", "referential", "currency", vm.$model.currencymodel, vm.$model.currencymodel),
        language: newStore("language", "referential", "language", vm.$model.languagemodel, vm.$model.languagemodel),

        // Settings
        // Tenant
        tenant: newStore("tenant", "settings", "tenant", vm.$model.tenantmodel, vm.$model.tenantmodel),
        // User
        user: newStore("user", "settings", "user", vm.$model.usermodel, vm.$model.usermodel),
        userscope: newStore("userscope", "settings", "userscope", vm.$model.userscopemodel, vm.$model.userscopemodel),
        // Company
        company: newStore("company", "settings", "company", vm.$model.companymodel, vm.$model.companymodel),
        companygeneralsetting: newStore("companygeneralsetting", "settings", "companygeneralsetting", vm.$model.companygeneralsettingmodel, vm.$schema.companygeneralsettingmodel),
        companyscope: newStore("companyscope", "settings", "companyscope", vm.$model.companyscopemodel, vm.$model.companyscopemodel),
        companyprofile: newStore("companyprofile", "settings", "companyprofile", vm.$model.companyprofilemodel, vm.$model.companyprofilemodel),
        companydefaultpackage: newStore("companydefaultpackage", "settings", "companydefaultpackage", vm.$model.copmanydefaultpackage, vm.$model.copmanydefaultpackage),
        companyalert: newStore("companyalert", "settings", "companyalert", vm.$model.companyalertmodel, vm.$model.companyalertmodel),
        companyalertscope: newStore("companyalertscope", "settings", "companyalertscope", vm.$model.companyalertscopemodel, vm.$model.companyalertscopemodel),
        companyprinter: newStore("companyprinter", "settings", "companyprinter", vm.$model.companyprintermodel, vm.$model.companyprintermodel),
        //Customisation
        custommessage: newStore("custommessage", "settings", "custommessage", vm.$model.custommessagemodel, vm.$model.custommessagemodel),
        // Admin
        dangerousgood: newStore("dangerousgood", "admin", "dangerousgood", vm.$model.dangerousgoodmodel, vm.$model.dangerousgoodmodel),
        // Address Book
        addressbook: newStore("addressbook", "admin", "addressbook", vm.$model.addressbookmodel, vm.$model.addressbookmodel),
        // Conversion Factor Matrix
        conversionfactormatrix: newStore("conversionfactormatrix", "admin", "conversionfactormatrix", vm.$model.conversionfactormatrixmodel, vm.$model.conversionfactormatrixmodel),
        // Accounts
        carrieraccount: newStore("carrieraccount", "admin", "carrieraccount", vm.$model.carrieraccountmodel, vm.$model.carrieraccountmodel),
        provider: newStore("serviceprovider", "settings", "serviceprovider", vm.$model.serviceprovidermodel, vm.$model.serviceprovidermodel),
        providercarrier: newStore("serviceprovidercarrier", "settings", "serviceprovidercarrier", vm.$model.serviceprovidercarriermodel, vm.$model.serviceprovidercarriermodel),
        providerproduct: newStore("serviceproviderproduct", "settings", "serviceproviderproduct", vm.$model.serviceproviderproductmodel, vm.$model.serviceproviderproductmodel),
        providerintegration: newStore("serviceproviderintegration", "settings", "serviceproviderintegration", vm.$model.serviceproviderintegrationmodel, vm.$model.serviceproviderintegrationmodel),
        providercountry: newStore("serviceprovidercountry", "settings", "serviceprovidercountry", vm.$model.serviceprovidercountrymodel, vm.$model.serviceprovidercountrymodel),
        providersurcharge: newStore("serviceprovidersurcharge", "settings", "serviceprovidersurcharge", vm.$model.serviceprovidersurchargemodel, vm.$model.serviceprovidersurchargemodel),
        providertimeintransit: newStore("serviceprovidertimeintransit", "settings", "serviceprovidertimeintransit", vm.$model.serviceprovidertimeintransitmodel, vm.$model.serviceprovidertimeintransitmodel),
        provideraccount: newStore("serviceprovideraccount", "admin", "serviceprovideraccount", vm.$model.serviceprovideraccountmodel, vm.$model.serviceprovideraccountmodel),
        provideraccountproduct: newStore("serviceprovideraccountproduct", "admin", "serviceprovideraccountproduct", vm.$model.serviceprovideraccountproductmodel, vm.$model.serviceprovideraccountproductmodel),
        provideraccountlocation: newStore("serviceprovideraccountpostinglocation", "admin", "serviceprovideraccountpostinglocation", vm.$model.serviceprovideraccountpostinglocationmodel, vm.$model.serviceprovideraccountpostinglocationrmodel),
        provideraccountcustomer: newStore("serviceprovideraccountcustomer", "admin", "serviceprovideraccountcustomer", vm.$model.serviceprovideraccountcustomermodel, vm.$model.serviceprovideraccountcustomermodel),
        provideraccountcustomerproduct: newStore("serviceprovidercustomerproduct", "admin", "serviceprovidercustomerproduct", vm.$model.serviceprovidercustomerproductmodel, vm.$model.serviceprovidercustomerproductmodel),

        // Polaris
        // Consignment
        consignment: newStore("consignment", "tms", "consignment", vm.$model.consignmentmodel, vm.$model.consignmentmodel),
        package: newStore("package", "tms", "package", vm.$model.packagemodel, vm.$model.packagemodel),
        packageitem: newStore("packageitem", "tms", "packageitem", vm.$model.packageitemmodel, vm.$model.packageitemmodel),
        // Collection
        collection: newStore("collection", "tms", "collection", vm.$model.collectionmodel, vm.$model.collectionmodel),
        // Returns
        returns: newStore("return", "tms", "return", vm.$model.returnmodel, vm.$model.returnmodel),
        // Facilities
        // Tariff Facility
        // Exchange Rates
        exchangerate: newStore("exchangerate", "facility", "exchangerate", vm.$model.exchangerate, vm.$model.exchangerate),
        // Provider Rates
        providercontract: newStore("providercontract", "facility", "providercontract", vm.$model.providercontractmodel, vm.$model.providercontractmodel),
        providercontractproduct: newStore("providercontractproduct", "facility", "providercontractproduct", vm.$model.providercontractproductmodel, vm.$model.providercontractproductmodel),
        providercontractproductrate: newStore("providercontractproductrate", "facility", "providercontractproductrate", vm.$model.providercontractproductratemodel, vm.$model.providercontractproductratemodel),
        providereffectivedate: newStore("providereffectivedate", "facility", "providercontracteffectivedate", vm.$model.providercontracteffectivedatemodel, vm.$model.providercontracteffectivedatemodel),
        providersurchargerate: newStore("providersurcharge", "facility", "providersurcharge", vm.$model.providersurchargemodel, vm.$model.providersurchargemodel),
        providersurchargerule: newStore("providersurchargerule", "facility", "providersurchargerule", vm.$model.providersurchargerulemodel, vm.$model.providersurchargerulemodel),
        providersurchargerulecondition: newStore("providersurchargerulecondition", "facility", "providersurchargerulecondition", vm.$model.providersurchargeruleconditionmodel, vm.$model.providersurchargeruleconditionmodel),
        providerzone: newStore("providerzone", "settings", "serviceproviderzone", vm.$model.serviceproviderzonemodel, vm.$model.serviceproviderzonemodel),
        providerzonemapping: newStore("providerzonemapping", "settings", "serviceproviderzonemapping", vm.$model.serviceprovidermappingmodel, vm.$model.serviceprovidermappingmodel),
        StorePlatforms: newStore("StorePlatforms", "facility", "StorePlatforms", vm.$model.StorePlatformsrmodel, vm.$model.StorePlatformsmodel),
        rateconditiontype: newStore("rateconditiontype", "facility", "rateconditiontype", vm.$model.rateconditiontypemodel, vm.$model.rateconditiontypemodel),
        // Markup Rates
        markupcontract: newStore("markupcontract", "facility", "markupcontract", vm.$model.markupcontractmodel, vm.$model.markupcontractmodel),
        markupcontractaccount: newStore("markupcontractaccount", "facility", "markupcontractaccount", vm.$model.markupcontractaccountmodel, vm.$model.markupcontractaccountmodel),
        markupcontractproduct: newStore("markupcontractproduct", "facility", "markupcontractaccountproduct", vm.$model.markupcontractaccountproductmodel, vm.$model.markupcontractaccountproductmodel),
        markupcontractrule: newStore("markupcontractrule", "facility", "markupcontractrule", vm.$model.markupcontractruletypermodel, vm.$model.markupcontractruletypemodel),
        markupcontractrulecondition: newStore("markupcontractrulecondition", "facility", "markupcontractrulecondition", vm.$model.markupcontractruleconditiontypemodel, vm.$model.markupcontractruleconditiontypemodel),
        markupStorePlatforms: newStore("markupStorePlatforms", "facility", "markupStorePlatforms", vm.$model.markupStorePlatformsrmodel, vm.$model.markupStorePlatformsmodel),
        markuprateconditiontype: newStore("markuprateconditiontype", "facility", "markuprateconditiontype", vm.$model.markuprateconditiontypemodel, vm.$model.markuprateconditiontypemodel),
        // Rule Engine Facility
        servicegroup: newStore("servicegroup", "facility", "servicegroup", vm.$model.servicegroupmodel, vm.$model.servicegroupmodel),
        servicegroupproduct: newStore("servicegroupproduct", "facility", "servicegroupproduct", vm.$model.servicegroupproductmodel, vm.$model.servicegroupproductmodel),
        servicegrouprule: newStore("servicegrouprule", "facility", "servicegrouprule", vm.$model.servicegrouprulemodel, vm.$model.servicegrouprulemodel),
        servicegrouprulecondition: newStore("servicegrouprulecondition", "facility", "servicegrouprulecondition", vm.$model.servicegroupruleconditionmodel, vm.$model.servicegroupruleconditionmodel),
        ruletradebloc: newStore("ruletradebloc", "facility", "ruletradebloc", vm.$model.ruletradeblocmodel, vm.$model.ruletradeblocmodel),
        ruletradebloccountry: newStore("ruletradebloccountry", "facility", "ruletradebloccountry", vm.$model.ruletradebloccountrymodel, vm.$model.ruletradebloccountrymodel),
        ruleholiday: newStore("ruleholiday", "facility", "ruleholiday", vm.$model.ruleholidaymodel, vm.$model.ruleholidaymodel),
        rulepostalbloc: newStore("rulepostalbloc", "facility", "rulepostalbloc", vm.$model.rulepostalblocmodel, vm.$model.rulepostalblocmodel),
        rulepostalblocpostalcode: newStore("rulepostalblocpostalcode", "facility", "rulepostalblocpostalcode", vm.$model.rulepostalblocpostalcodemodel, vm.$model.rulepostalblocpostalcodemodel),

        //Invoice Engine
        invoice: newStore("invoice", "facility", "invoice", vm.$model.invoicemodel, vm.$model.invoicemodel),
        invoiceadditionalfee: newStore("invoiceadditionalfee", "facility", "invoiceadditionalfee", vm.$model.invoiceadditionalfeemodel, vm.$model.invoiceadditionalfeemodel),
        invoiceattachment: newStore("invoiceattachment", "facility", "invoiceattachment", vm.$model.invoiceattachmentmodel, vm.$model.invoiceattachmentmodel),
        invoicereconcile: newStore("invoicereconcile", "facility", "invoicereconcile", vm.$model.invoicereconcilemodel, vm.$model.invoicereconcilemodel),

        // Import Facility
        importprofile: newStore("importprofile", "facility", "importprofile", vm.$model.importprofilemodel, vm.$model.importprofilemodel),
        importrequest: newStore("importrequest", "facility", "importrequest", vm.$model.importrequestmodel, vm.$model.importrequestmodel),

        // Dynamic Report Facility
        dynamicreport: newStore("dynamicreport", "facility", "dynamicreport", vm.$model.DynamicReportModel, vm.$model.DynamicReportModel),
        dynamicreportfield: newStore("dynamicreportfield", "facility", "dynamicreportfield", vm.$model.dynamicreportfieldmodel, vm.$model.dynamicreportfieldmodel),
        dynamicreportbasefield: newStore("dynamicreportbasefield", "facility", "dynamicreportbasefield", vm.$model.dynamicreportbasefieldmodel, vm.$model.dynamicreportbasefieldmodel),

        // Dynamic Report Facility
        store: newStore("store", "facility", "store", vm.$model.storemodel, vm.$model.storemodel),
        storelocation: newStore("storelocation", "facility", "storelocation", vm.$model.storelocationmodel, vm.$model.storelocationmodel),
        storewebhook: newStore("storewebhook", "facility", "storewebhook", vm.$model.storewebhookmodel, vm.$model.storewebhookmodel),
        storeplatform: newStore("storeplatform", "facility", "storeplatform", vm.$model.storeplatformmodel, vm.$model.storeplatformmodel),

        cart: newStore("cart", "facility", "cart", vm.$model.cartmodel, vm.$model.cartmodel),
        //cartlocation: newStore("storelocation", "facility", "storelocation", vm.$model.storelocationmodel, vm.$model.storelocationmodel),
        //cartwebhook: newStore("storewebhook", "facility", "storewebhook", vm.$model.storewebhookmodel, vm.$model.storewebhookmodel),
        salesorder: newStore("salesorder", "facility", "salesorder", vm.$model.salesordermodel, vm.$model.salesordermodel),
        salesorderitem: newStore("salesorderitem", "facility", "salesorderitem", vm.$model.salesorderitemmodel, vm.$model.salesorderitemmodel),


        // Import Facility
        auditlog: newStore("auditlog", "facility", "auditlog", vm.$model.auditlogmodel, vm.$model.auditlogmodel),
        printjob: newStore("printjob", "facility", "printjob", vm.$model.printjobmodel, vm.$model.printjobmodel),
    }
});