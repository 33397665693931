<template>
    <form-object :id="id" :autoFit="autoFit" :label="label" :floatLabel="useFloat" :disabled="true" :hideLabel="hideLabel" :validator="v$" :v$="v$" :vID="vID" :vBase="vBase" :vError="errors ?? vError" :required="required" :conditional="conditional">
        <template #default="slotProps">
            <pv-input v-if="!disabled && (objType === 'text' || objType === 'email' || objType === 'url')"
                      :ref="id"
                      :id="id"
                      :name="id"
                      :placeholder="phText"
                      :disabled="disabled"
                      :maxlength="maxlength"
                      :class="[{'p-inputtext': true}, {'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                      :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}]"
                      v-model="model.value"
                      :type="objType"
                      :pattern="regexPattern"
                      autocomplete="off"
                      autocorrect="off"
                      autosuggest="off"
                      spellcheck="false"
                      :tabindex="tabIndex"
                      v-tooltip.focus.right="tooltip"
                      onfocus="this.select()"></pv-input>
            <pv-password v-else-if="!disabled && objType === 'password'"
                         :ref="id"
                         :id="id"
                         :name="id"
                         :placeholder="phText"
                         :disabled="disabled"
                         :maxlength="maxlength"
                         :class="[{'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                         :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}]"
                         :feedback="showMeter"
                         :toggleMask="showButton"
                         :promptLabel="phText"
                         autocomplete="new-password"
                         autocorrect="off"
                         autosuggest="off"
                         spellcheck="false"
                         v-model="model.value"
                         v-tooltip.focus.right="tooltip"
                         onfocus="this.select()">
                <template v-if="showMeter" #footer>
                    <pv-divider />
                    <p class="p-mt-2">Requirements</p>
                    <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 10 characters</li>
                    </ul>
                </template>
            </pv-password>
            <pv-textarea v-else-if="!disabled && objType === 'textarea'"
                         :ref="id"
                         :id="id"
                         :name="id"
                         :class="[{'p-inputtextarea': true}, {'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                         :placeholder="phText"
                         :maxlength="maxlength"
                         :disabled="disabled"
                         :autoResize="autoHeight"
                         :rows="rows"
                         :cols="cols"
                         autocomplete="off"
                         autocorrect="off"
                         autosuggest="off"
                         spellcheck="false"
                         v-model="model.value"
                         v-tooltip.focus.right="tooltip"
                         style="resize: none;"
                         onfocus="this.select()"></pv-textarea>
            <k-editor v-else-if="!disabled && objType === 'editor'"
                      :ref="id"
                      :id="id"
                      :name="id"
                      :value="model.value"
                      :placeholder="phText"
                      :disabled="disabled"
                      :maxlength="maxlength"
                      :resizable-content="editorContentResize"
                      :resizable-toolbar="false"
                      :class="[{'p-inputchip': true}, {'p-fluid': autoFit}, {'p-invalid': slotProps.data}, {'k-rtl' : rtl }]"
                      :rows="rows"
                      :style="[{'height': `${rows * 30}px`}]"
                      :tools='["fontName", "fontSize", "formatting", "copyFormat", "applyFormat", "cleanFormatting", "foreColor", "backColor", "bold", "italic", "underline", "strikethrough", "subscript", "superscript", "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "insertUnorderedList", "insertOrderedList", "indent", "outdent", "createLink", "unlink", "tableWizard", "createTable", "addColumnLeft", "addColumnRight", "addRowAbove", "addRowBelow", "deleteRow", "deleteColumn", "viewHtml"]'
                      v-model="model.value"
                      @change="onSync"
                      >
                <!--                      :tools='["fontName", "fontSize", "formatting", "copyFormat", "applyFormat", "cleanFormatting", "foreColor", "backColor", "bold", "italic", "underline", "strikethrough", "subscript", "superscript", "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "insertUnorderedList", "insertOrderedList", "indent", "outdent", "createLink", "unlink", "insertImage", "insertFile", "tableWizard", "createTable", "addColumnLeft", "addColumnRight", "addRowAbove", "addRowBelow", "deleteRow", "deleteColumn", "print", "pdf", "viewHtml"]' 
                    :pdf="pdf"-->
            </k-editor>
            <pv-chips v-else-if="!disabled && (objType === 'chips')"
                      :ref="id"
                      :id="id"
                      :name="id"
                      :disabled="disabled"
                      :allowDuplicate="false"
                      :separator="chipSeparator"
                      :class="[{'p-fluid': autoFit}, {'p-invalid': slotProps.data}]"
                      v-tooltip.focus.right="tooltip"
                      v-model="model.value"></pv-chips>
            <pv-color v-else-if="!disabled && (objType === 'color' || objType === 'colorpicker')"
                      :ref="id"
                      :id="id"
                      :name="id"
                      :disabled="disabled"
                      :format="'hex'"
                      :defaultColor="'FFFFFF'"
                      :class="[{'p-colorpicker': true}, {'p-fluid': autoFit}, {'p-filled': true}, {'p-invalid': slotProps.data}]"
                      :style="[{'width': '100%'}, { 'border-radius': 0 }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': '0' }, { 'border-bottom-right-radius': '0' }]"
                      v-tooltip.focus.right="tooltip"
                      v-model="model.value"></pv-color>

            <!-- DISABLED OBJECT-->
            <div v-if="disabled && (objType === 'color' || objType === 'colorpicker')" :title="`${(model?.value ? model.value : '')}`" class="is-disable p-component p-inputtext p-input-icon-right" :style="[{'width': '100%'}, { 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': '0'}, { 'border-bottom-right-radius': '0'}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, {'background-color': `#${model.value ?? 'FFFFFF'}`}, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }] "></div>
            <div v-else-if="disabled && objType === 'password'" class="is-disabled p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow-x': 'hidden' }, { 'overflow-y': 'auto' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed'}] ">
                <span v-if="!(postLabel || postIcon || postLabel || postIcon || model.value)">&nbsp;</span><span class="p-code-text" style="font-size: 0.9rem; font-weight: 600; text-wrap: normal;" v-html="`${model?.text ? model?.text : ''}`"></span>
            </div>
            <div v-else-if="disabled && objType === 'textarea'" :title="`${(model?.value ? model.value : '')}`" class="is-disabled p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow-x': 'hidden' }, { 'overflow-y': 'auto' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'max-height': '74px' }, { 'min-height': '74px' }, { 'padding-left': '10px' }] ">
                <span v-if="!(postLabel || postIcon || postLabel || postIcon || model.value)">&nbsp;</span><span class="p-code-text" style="font-size: 0.8rem; font-weight: 600; text-wrap: normal;" v-html="`${model.text}`"></span>
                <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer; top: 20px;" @click="onCopy"></i>
            </div>
            <div v-else-if="disabled && objType=== 'editor'" :title="`${(model?.value ? model.value : '')}`" class="is-disabled p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }, {'min-height': '2.858em'}, {'padding': '0.6rem 25px 10px 5px'}, {'padding-left': '10px'}]">
                <span v-if="!(postLabel || postIcon || postLabel || postIcon || model.value)">&nbsp;</span><span class="p-code-text" style="font-size: 0.8rem;" v-html="`${(model?.value ? model.value : '&nbsp;')}`"></span>
                <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer; line-height: 1rem;" @click="onCopy"></i>
            </div>
            <div v-else-if="disabled" :title="`${(model?.value ? model.value : '')}`" class="is-disabled p-component p-inputtext p-input-icon-right" :style="[{ 'border-radius': 0 }, { 'border-right': (postLabel || postIcon) ? ((postLabel || postIcon) ? '0' : '1px solid inherit') : false }, { 'border-top-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon) ? '0' : '4px') }, { 'border-top-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'border-bottom-right-radius': ((postLabel || postIcon) ? '0' : '4px')}, { 'overflow': 'hidden' }, { 'text-overflow': 'ellipsis' }, { 'background': 'var(--surface-c)' }, { 'cursor': 'not-allowed' }, { 'white-space': 'nowrap' }, {'height': '2.858em'}, {'padding': '0.75rem 2rem 1.0rem 0.75rem'}]">
                <span v-if="!(postLabel || postIcon || postLabel || postIcon || model.value)">&nbsp;</span><span class="p-code-text" style="font-size: 0.9rem; font-weight: 600; text-wrap: normal;" v-html="`${(model?.value ? model.value : '&nbsp;')}`"></span>
                <i v-if="showCopy" class="pi pi-copy" style="cursor: pointer; line-height: 1rem;" @click="onCopy"></i>
            </div>
            <span class="p-inputtext p-text-uppercase" v-if="objType === 'color' || objType === 'colorpicker'" :style="[{'width': '100px'}, {'border-left': 0}, { 'border-radius': 0 }, { 'border-top-left-radius': ((preLabel || preIcon || disabled) ? '0' : '4px') }, { 'border-bottom-left-radius': ((preLabel || preIcon || disabled) ? '0' : '4px') }, { 'border-top-right-radius': disabled ? '4px' : '0' }, { 'border-bottom-right-radius': disabled ? '4px' : '0' }] ">#{{ model.value ?? 'FFFFFF' }}</span>
        </template>
        <template #leftSide="slotProps">
            <slot name="prepend" data="slotProps">
                <span v-if="preIcon || preLabel" :class="[{'is-prepend': true}, {'p-inputgroup-addon': true}, {'p-invalid': slotProps.data}]" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }] ">
                    <i v-if="preIcon" :class="preIcon"></i>
                    <span v-if="preLabel" :class="[{'is-label': true}, {'optional': model.state.optional}, {'required': model.state.required || required}]" :style="preCSS" :title="preLabel ?? label ?? $filters.titleize(id)" v-html="preLabel"></span>
                </span>
            </slot>
        </template>
        <template #rightSide>
            <slot name="append">
                <span v-if="postIcon || postLabel" class="is-append p-inputgroup-addon" :style="[{'background-color': 'var(--surface-f)'}, {'font-weight': 500 }]">
                    <i v-if="postIcon" :class="postIcon"></i>
                    <span v-if="postLabel" class="is-label" :style="postCSS" :title="postLabel" v-html="postLabel"></span>
                </span>
                <pv-button v-if="showDirectory" icon="pi pi-fw pi-book" :iconOnly="true" style="min-width: 40px;" title="Address Book Lookup..." @click="this.$emit('is-click',true)" tabindex="-1" />
                <pv-button v-if="showLookup" icon="pi pi-fw pi-map" :iconOnly="true" style="min-width: 40px;" title="Address Lookup..." @click="this.$emit('is-fetchify',true)" tabindex="-1" />
            </slot>
        </template>
    </form-object>
</template>
<script>
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    import { maxLength } from '@vuelidate/validators';
    export default {
        name: "FormInputField",
        mixins: [mixins.COMPONENT],
        data() {
            return {
                tools: {
                    fetchify: false

                },
                errors: null
            }
        },
        props: {
            type: { type: String, default: "text", validator: (value) => ["text", "email", "url", "password", 'search', 'colorpicker', 'color', 'textarea', 'editor', 'chips'].indexOf(value.toLowerCase()) !== -1 },
            modelValue: { type: String },
            value: { type: String },
            showCopy: { type: Boolean, default: true },
            /* Password-Specific Properties*/
            showButton: { type: Boolean, default: true },
            showMeter: { type: Boolean, default: true },
            /* TextArea-Specific Properties */
            cols: { type: Number, default: 30 },
            rows: { type: Number, default: 3 },
            autoHeight: { type: Boolean, default: false },
            /* Masked Input */
            maxLength: { type: Number, default: 35 },
            pattern: { type: String, default: null },
            patternMessage: { type: String },
            unmasked: { type: Boolean, default: true },
            showDirectory: { type: Boolean, default: false },
            showLookup: { type: Boolean, default: false },
            passwordLastDigit: { type: Number, default: 4 },
            tabIndex: { type: Number, default: 0 },
            /* Chips */
            chipSeparator: { type: String, default: '�' },
            /* Editow-Specific Properties */
            editorContentResize: { type: Boolean, default: false },
            rtl: { type: Boolean, default: false }
        },
        computed: {
            maxlength() {
                if (maxLength === -1)
                    return this.model.state.maxLength;
                return this.maxLength;
            },
            useFloat() {
                if (this.model.value)
                    return (this.floatLabel && !this.disabled);
                else if (this.floatLabel && (!this.disabled || this.objBlank))
                    return (this.floatLabel && (!this.disabled || this.objBlank))
                else
                    return false;
            },
            regexPattern() {
                if (this.pattern && this.pattern === "ValidateURL")
                    return "[Hh][Tt][Tt][Pp][Ss]://(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+).(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::[0-9]{2,5})?(?:/[^[a-zA-Z]]*)?";
                return this.pattern;
            }
        },
        methods: {
            onSync(e) {
                let data = { value: this.model?.value, text: null, record: null };
                if (this.objType === 'editor' && e && e.sender) {
                    data.value = e.sender.document["body"].innerHTML.replaceAll("<", "&lt;");
                    var iframe = e.sender.element[0].previousSibling;
                    data.value = iframe.contentWindow.document.querySelector('body').innerHTML;
                    data.text = e.sender.document.children[0].innerText;
                    data.record = { html: data.value, text: data.text };
                }
                else if (this.objType === "color" || this.objType === "colorpicker") {
                    data.value = data.value.replaceAll('#', '').toUpperCase();
                    data.text = '#' + data.value.replaceAll('#', '').toUpperCase();
                    data.record = { value: data.value, text: data.text };
                }
                else if (this.objType === 'textarea') {
                    data = {
                        value: (this.model?.value ?? "").length > 0 ? (this.model?.value ?? "") : null,
                        text: (data?.value ? this.model.value : '&nbsp;').replaceAll('\r\n', '<br />').replaceAll('\n', '<br />'),
                        record: {
                            text: (this.model?.value ?? "").length > 0 ? (this.model?.value ?? "") : null,
                            html: (data?.value ? this.model.value : '&nbsp;').replaceAll('\r\n', '<br />').replaceAll('\n', '<br />')
                        }
                    };
                    data.value = ((data.value === undefined || data.value === 'undefined') ? null : data.value);
                }
                else if (this.objType === 'password') {
                    var value = (this.model?.value ? this.model.value : '&nbsp;');
                    var length = value.replace('&nbsp;', '').length;
                    value = (this.model?.value ?? "").length > 0 ? (this.model?.value ?? "") : null;
                    var hidden = this.$filters.padLeft(length > this.passwordLastDigit ? value.substring((length - this.passwordLastDigit)) : '', length, '*')?.replaceAll('*', '<i class="pi pi-circle-on" style="font-size: 0.5rem;margin-right: 2px;"></i>');
                    data = {
                        value: value,
                        text: hidden,
                        record: { value: value, text: hidden }
                    };
                    data.value = ((data.value === undefined || data.value === 'undefined') ? null : data.value);
                }
                else if (this.objType === 'chips') {
                    data.value = (this.model?.value ?? []).join(this.chipSeparator);
                    data.text = (this.model?.value ?? []).map(m => `"${m}"`).join(',');
                    data.record = (this.model?.value ?? []);
                }
                else {
                    var form = document.querySelectorAll("form")[0];
                    if (form) {
                        try {
                            form?.addEventListener("invalid", function (event) {
                                event.preventDefault();
                            }, true);
                            form.checkValidity();
                            var id = this.id ?? -1000;
                            // Suppress the default bubbles
                            var object = form.querySelectorAll(":invalid");
                            this.errors = null;
                            for (var i = 0; i < object.length; i++) {
                                var a = object[i];
                                if (id === a.id)
                                    this.errors = (object[i].validationMessage === "Please match the format requested." ? this.patternMessage ?? object[i].validationMessage + " Pattern: " + this.regexPattern : object[i].validationMessage);
                            }
                        }
                        catch { /* No Catch */ }
                    }

                    data = {
                        value: (this.model?.value ?? "").trim().length > 0 ? (this.model?.value ?? "").trim() : null,
                        text: (this.model?.text ?? "").trim().length > 0 ? (this.model?.text ?? "").trim() : null,
                        record: {
                            value: (this.model?.value ?? "").trim().length > 0 ? (this.model?.value ?? "").trim() : null,
                            text: (this.model?.text ?? "").trim().length > 0 ? (this.model?.text ?? "").trim() : null
                        }
                    };
                    data.value = ((data.value === undefined || data.value === 'undefined') ? null : data.value);
                }
                this.model.value = (data?.value ?? "").length > 0 ? (this.model?.value ?? "") : null
                this.model.text = data.text;
                this.model.record = data.record;
                this.$emit("update:modelValue", this.model.value);
                this.$emit("is-sync", data);
            }
           
        },
        async created() {
            /*
            February 19,2024
            FIX .replace(/\u00ACn/g, "\n") THIS IS TO APPLY FIX FOR NEW LINES FOR VALUES CONVERTING NEWLINE TO THIS CHARACTER "�n"
            */
            if (this.modelValue)
                this.model.value = this.modelValue?.replace(/\u00ACn/g, "\n");
            else
                this.model.value = this.value?.replace(/\u00ACn/g, "\n");
            this.onSync();
        },
        mounted() {
            var form = document.querySelectorAll("form")[0];
            if (form) {
                form.addEventListener("invalid", function (event) {
                    event.preventDefault();
                }, true);
                form.addEventListener("submit", function (event) {
                    event.preventDefault();
                    if (!this.checkValidity()) {
                        event.preventDefault();
                    }
                });
            }
        },
        unmounted() {
            var form = document.querySelectorAll("form")[0];
            if (form) {
                form.removeEventListener("invalid", function (event) {
                    event.preventDefault();
                });
                form.removeEventListener("submit", function (event) {
                    event.preventDefault();
                    if (!this.checkValidity()) {
                        event.preventDefault();
                    }
                });
            }
        }
    }
</script>
<style>
    .is-prepend .required { color: var(--text-danger) !important; }
 /*   .p-component.p-inputtext {
        padding: 0.5rem 0.75rem !important;
        font-family: 'Roboto Mono', monospace !important;
        font-weight: 600 !important;
        font-size: 0.9rem !important;
        min-height: 2.3rem !important;
        max-height: 2.3rem !important;
    }
    .p-dropdown.p-component.p-inputwrapper, .p-calendar.p-component.p-inputwrapper {
        padding: 0 !important;
        font-family: 'Roboto Mono', monospace !important;
        font-weight: 600 !important;
        font-size: 0.9rem !important;
        min-height: 2.3rem !important;
        max-height: 2.3rem !important;
    }
    .p-multiselect.p-component.p-inputwrapper {
        padding: 0 !important;
        font-family: 'Roboto Mono', monospace !important;
        font-weight: 600 !important;
        font-size: 0.9rem !important;
        min-height: 2.3rem !important;
    }
        .p-dropdown.p-component.p-inputwrapper .p-component.p-inputtext, .p-calendar.p-component.p-inputwrapper .p-component.p-inputtext {
            padding: 0.5rem 0.75rem !important;
            font-family: 'Roboto Mono', monospace !important;
            font-weight: 600 !important;
            font-size: 0.9rem !important;
            min-height: 2.2rem !important;
            max-height: 2.2rem !important;
        }

    .p-multiselect.p-component.p-inputwrapper .p-component.p-inputtext {
        padding: 0.5rem 0.75rem !important;
        font-family: 'Roboto Mono', monospace !important;
        font-weight: 600 !important;
        font-size: 0.9rem !important;
        min-height: 2.3rem !important;
    }

    .p-inputtextarea.p-inputtext.p-component {
        font-family: 'Roboto Mono', monospace !important;
        min-height: unset !important;
        max-height: unset !important;
    }

    .p-dropdown-label.p-inputtext {
        padding: 0.25rem 0.75rem !important;
        font-family: 'Roboto Mono', monospace !important;
        min-height: 2.2rem !important;
        max-height: 2.2rem !important;
    }

    .p-multiselect-label-container.p-inputtext {
        padding: 0.25rem 0.75rem !important;
        font-family: 'Roboto Mono', monospace !important;
        min-height: 2.2rem !important;
    }
    .p-dropdown-label.p-inputtext * {
        font-family: 'Roboto Mono', monospace !important;
    }*/
</style>