<template>
    <form-record :model="model" :modelState="v$" @is-reset="resetRecord" :viewOnly="true">
        <div class="p-d-flex">
            <div class="p-col-12">
                <pv-steps :model="steps" style="margin: -2.4rem -1.5rem 0.5rem -1.5rem; border-bottom: 1px solid var(--surface-d); padding: 10px; background: var(--surface-c) " />
                <router-view name="stepper" v-slot="{Component}" v-model="model" v-model:printerSource="printers" v-model:modelPlatform="platform" v-model:modelAccessScope="accessScope" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete($event)">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>
        </div>
    </form-record>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    export default {
        name: "StoreCreateForm",
        setup: () => ({ v$: useVuelidate() }),
        mixins: [mixins.RECORD],
        data() {
            return {
                header: null,
                payload: null,
                platform: null,
                printers: [],
                model: {
                    id: 0,
                    customerID: 0,
                    basicInfo: {
                        code: null,
                        name: null,
                        endSolution: 200,
                        thirdPartySolution: 100,
                        status: 100,
                        verificationDate: null,
                    },
                    defaults: {
                        warehouseID: null,
                        currencyID: null,
                        content: null,
                        email: null,
                        telephone: null,
                        packagingType: 4,
                        incoterm: 16,
                        weightUnit: 100,
                        dimensionUnit: 100
                    },
                    printers: {
                        autoPrinterSetting: 100,
                        labelPrinterID: null,
                        documentPrinterID: null
                    },
                    credentials: {
                        platformID: 1,
                        credential_01: "https://shops-demo.integro360.com",
                        credential_02: "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",
                        credential_03: "008629bc859b6912d51777101ad9d3cc",
                        credential_04: "ccb1fbc6fe04b7a86fa86330dd1ea3d9",
                        credential_05: "ccb1fbc6fe04b7a86fa86330dd1ea3d9",
                        credential_06: "ccb1fbc6fe04b7a86fa86330dd1ea3d9",
                        credential_07: null,
                        credential_08: null,
                        credential_09: null,
                        credential_10: null,
                        credential_11: null,
                        credential_12: null,
                        credential_13: null,
                        credential_14: null,
                        credential_15: null,
                        tokenIssued: null,
                        tokenExpiry: null
                    },
                    orderAutomation: {
                        type: 100,
                        captureType: 200,
                        fulfillOrder: false,
                        partialFulfillment: false,
                        notifyUser: false,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    productAutomation: {
                        type: 100,
                        captureType: 200,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    notification: {
                        failed: null,
                        success: null
                    },
                    shopInfo: {
                        code: null,
                        name: "Acme Technologies",
                        address1: "Springfield House",
                        address2: "28 Oatlands Drive",
                        address3: null,
                        city: "Weybridge",
                        state: "Surrey",
                        stateCode: null,
                        postalCode: "KT13 9LZ",
                        country: "GB",
                        countryID: null,
                        contact: "Development Team",
                        telephone: "+448452993289",
                        email: "enquiries@integro360.com",
                        shopDomain: "stream-local.myshopify.com",
                        storeDomain: "shops-demo.integro360.com",
                        timezone: null,
                        ianaTimezone: null,
                        supportedCurrencies: null,
                        defaults: {
                            primaryLocationID: null,
                            currency: "GBP",
                            weightUnit: "G"
                        },
                        taxConfiguration: {
                            taxInclusive: false,
                            taxShipping: false
                        }
                    },
                    webhooks: [],
                    locations: [],
                    storeID: null,
                    storeKey: null,
                    bridgeKey: null,
                    environmentID: null,
                    recordStatus: 0
                },
                accessScope: null,
                steps: [
                    { label: 'Credentials', to: '/facility/cart/create' },
                    { label: 'Shop Info', to: '/facility/cart/step/shopinfo' },
                    { label: 'Basic Info', to: '/facility/cart/step/basicinfo' },
                    { label: 'Summary', to: '/facility/cart/step/summary' },
                ]
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }), 
            sessionKeys() { return this.$store.getters["auth/session"]; },
            common() { return this.$config.common; }
        },  
        methods: {
            nextPage(event) {
                if (event.header)
                    this.header = event.header;
                if (event.payload)
                    this.payload = event.payload;
                if (event.printers)
                    this.printers = event.printers;
                this.platform = event.platform;
                if (event.formData)
                    this.model = event.formData;
                this.$router.push(this.steps[event.pageIndex + 1].to);
            },
            prevPage(event) {
                for (let field in event.formData) {
                    this.model[field] = event.formData[field];
                }
                this.$router.push(this.steps[event.pageIndex - 1].to);
            },
            async complete() {
                var data = null;
                await this.$axios
                    .post(this.$config.config.endpoint.api + '/api/marketplace/cart'.replaceAll("//", "/"), this.payload, this.header)
                    .then((response) => {
                        data = response.data;
                        if ((response.data.Success && response.data.Data) || response.success) {
                            this.model.storeID = response.data.Data.ShopID;
                            this.model.storeKey = response.data.Data.StoreKey;
                            let entity = this.$filters.init(this.model);
                            this.insert(entity).then((response) => {
                                if (response && (response.errors ?? []).length > 0)
                                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[0].message, life: 3000 });
                                else {
                                    this.$router.push({ name: this.router.module + '-edit', params: { mask: response.result.mask } });
                                    this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                                    this.resetModelState();
                                    setTimeout(() => this.$emit("is-reset"), 500);
                                }
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                            });
                            this.$nextTick(() => { this.activity.loading = false; });
                        }
                        else if ((response.data.Errors ?? []).length > 0) {
                            let message = response.data.Errors[0].message.indexOf("AK_Cart_ThirdPartyStoreKey") !== -1 ? "There store is already exist." : response.data.Errors[0].message; 
                            this.$toast.add({ severity: 'error', summary: 'Failed To Validate Credentails', detail: message, life: 3000 });
                            this.$nextTick(() => { this.activity.loading = false; });
                        }
                    })
                    .catch((ex) => {
                        data = "EXCEPTION:" + ex?.data ?? ex;
                        //if (data)
                        //    commit("SET_ERROR_DATA", ex.data);
                    });
                return data;
            },
        },
        created() {
            this.accessScope = this.common.$accessScope(this.user.mask);
            this.$router.push({ name: this.router.module + '-platform' });
        }
    }
</script>
