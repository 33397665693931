<template>
    <pv-grid :ref="id"
             :id="id"
             :name="id"
             :value="result?.data ?? result ?? []"
             :totalRecords="result.total ?? (result ?? []).length ?? 0"
             :paginator="paginator"
             responsiveLayout="scroll"
             :paginatorPosition="paginatorPosition"
             :paginatorTemplate="paginatorTemplate"
             :pageLinkSize="pageLink"
             :rowsPerPageOptions="rowSizes"
             :alwaysShowPaginator="true"
             :currentPageReportTemplate="pageReportTemplate"
             :groupRowsBy="groupBy"
             :rowGroupMode="(groupBy ? 'subheader' : '')"
             :first="page.first"
             :rows="page.size"
             :lazy="true"
             :loading="activity.loading"
             :loadingIcon="'pi pi-spinner'"
             v-model:selection="selected"
             v-model:filters="filter"
             v-model:expandedRows="expanded.level1"
             :scrollable="scrollable"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :columnResizeMode="'expand'"
             autoLayout
             showGridlines
             stripedRows
             :csvSeparator="csvSeparator"
             :exportFilename="generateFilename()"
             :rowHover="true"
             :filters="activeFilters"
             :filterDisplay="'menu'"
             :globalFilterFields="['code', 'name']"
             :stateStorage="stateStorage"
             :stateKey="stateKey"
             @page="onChangePage"
             @row-dblclick="onRowDoubleClick">

        <!-- Table Header Template -->
        <template v-if='showHeader' #header>
            <slot name="toolbar">
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="allowMultiLevel" icon="pi pi-plus" label="Expand All" @click="expandAll" class="mr-2" style="margin-right: 5px;" />
                        <pv-button v-if="allowMultiLevel" icon="pi pi-minus" label="Collapse All" @click="collapseAll" style="margin-right: 5px;" />
                        <pv-button v-if="showAdd" icon="pi pi-fw pi-plus" label="Add New Record" style="margin-right: 5px;" @click="onCreate"></pv-button>
                        <pv-button v-if="showExport" icon="pi pi-fw pi-file-excel" label="Export To CSV" :disabled="source.total === 0" @click="onExport" style="margin-right: 5px;"> </pv-button>
                    </span>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>
            </slot>
            <slot name="columnGroup">
            </slot>
        </template>

        <!-- Group Header Template -->
        <template v-if="groupBy" #groupheader="slotProps">
            <slot name="groupheader" :data="slotProps.data">
                <span class="image-text" style="text-transform: uppercase; font-weight: 700 !important" v-html="`${groupBy.split('.')[1] ? slotProps.data[groupBy.split('.')[0]][groupBy.split('.')[1]] : slotProps.data[groupBy.split('.')[0]]}`"></span>
            </slot>
        </template>
        <!-- Row Reorder Icon -->
        <pv-grid-column v-if="rowReorder" :rowReorder="true" headerStyle="width: 2.5rem" :reorderableColumn="false" :frozen="true" />
        <!-- Multi Level Expander -->
        <pv-grid-column v-if="multiLevel" :expander="true" headerStyle="width: 3rem; max-width: 3rem; padding: 10px;" style="max-width: 3rem; padding: 5px 10px; border-right: 0;" :frozen="true" />
        <!-- Multi Level Expander -->
        <pv-grid-column v-if="showCheckbox" field="id" :selectionMode="multiSelect ? 'multiple' : 'single'" headerStyle="width: 3em; min-width: 3em; max-width: 3em;" style="width: 3em; width: 3em; min-width: 3em; max-width: 3em;" :frozen="true" />
        <!-- Columns -->
        <slot v-if="!autoGenerate" name="default"></slot>
        <pv-grid-column v-else v-for="col in columns" :field="col.field" :header="col.header" :key="col.field" :headerStyle="`width: ${col.width}em; min-width: ${col.width}em; max-width: ${col.width}em;`">
            <template #body="slotProps">
                {{ col.field?.split('.')[1] !== undefined ? (slotProps.data[col.field?.split('.')[0]] === null  || slotProps.data[col.field?.split('.')[0]] === undefined) ? '' : slotProps.data[col.field?.split('.')[0]][col.field?.split('.')[1]] : slotProps.data[col.field?.split('.')[0]] }}
            </template>
        </pv-grid-column>
        <!-- Operation Column -->
        <pv-grid-column v-if="showOperation" field="mask" :headerStyle="`min-width: ${operationWidth}rem; max-width: ${operationWidth}rem; width: ${operationWidth}rem`" :style="`min-width: ${operationWidth}rem; max-width: ${operationWidth}rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;`" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex" style="width: 2rem;">
                    <div v-if="showEdit" class="p-col">
                        <pv-button icon="pi pi-pencil" iconPos="right" class="p-button-info" @click="onEdit(`${slotProps.data.mask}`)" />
                    </div>
                    <div v-if="showDelete" class="p-col">
                        <pv-button :disabled="(slotProps.data.recordStatus ?? 0) !== 0 || slotProps.data.id < baseRecordID || (slotProps.data?.viewOnly ?? false)  || (slotProps.data?.disabled ?? false) || (slotProps.data?.readOnly ?? false)" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="onDelete(`${slotProps.data.id}`)" />
                    </div>
                    <slot name="operation" :data="slotProps.data">
                    </slot>
                </div>
            </template>
        </pv-grid-column>
        <!-- Row Messages -->
        <template #empty>
            <slot name="empty">
                No records found.
            </slot>
        </template>
        <template #loading>
            <slot name="loading">
                Retrieving records from database. Please wait...
            </slot>
        </template>
        <!-- Pagination -->
        <template #paginatorLeft>
            <slot name="paginatorLeft">
                <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
            </slot>
        </template>
        <template #paginatorRight>
            <slot name="paginatorRight">
                <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="onRefresh" />
            </slot>
        </template>
        <template v-if="multiLevel" #expansion="slotProps">
            <div class="orders-subtable" style="margin: -10px -10px -10px calc(3rem - 10px);">
                <slot name="expansion" :data="slotProps.data">

                </slot>
            </div>
        </template>

        <!--
                         :paginator="true"
                 :alwaysShowPaginator="pagination"
                 :paginatorPosition="'bottom'"
                 :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
                 :pageLinkSize="3"
                 :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} records'"









        value	array	null	An array of objects to display.
        dataKey	string	null	Name of the field that uniquely identifies the a record in the data.
        rows	number	null	Number of rows to display per page.
        first	number	0	Index of the first row to be displayed.
        totalRecords	number	null	Number of total records, defaults to length of value when not defined.
        paginator	boolean	false	When specified as true, enables the pagination.
        paginatorPosition	string	bottom	Position of the paginator, options are "top","bottom" or "both".
        alwaysShowPaginator	boolean	true	Whether to show it even there is only one page.
        paginatorTemplate	string	FirstPageLink PrevPageLink PageLinks
        NextPageLink LastPageLink RowsPerPageDropdown	Template of the paginator. See the Paginator for all available options.
        pageLinkSize	number	5	Number of page links to display.
        rowsPerPageOptions	array	null	Array of integer values to display inside rows per page dropdown.
        currentPageReportTemplate	string	({currentPage} of {totalPages})	Template of the current page report element.
        lazy	boolean	false	Defines if data is loaded and interacted with in lazy manner.
        loading	boolean	false	Displays a loader to indicate data load is in progress.
        loadingIcon	string	pi pi-spinner	The icon to show while indicating data load is in progress.
        sortField	string	null	Property name or a getter function of a row data used for sorting by default
        sortOrder	number	null	Order to sort the data by default.
        defaultSortOrder	number	1	Default sort order of an unsorted column.
        multiSortMeta	array	null	An array of SortMeta objects to sort the data by default in multiple sort mode.
        sortMode	string	single	Defines whether sorting works on single column or on multiple columns.
        removableSort	boolean	false	When enabled, columns can have an un-sorted state.
        filters	object	null	Filters object with key-value pairs to define the filters.
        filterDisplay	string	null	Layout of the filter elements, valid values are "row" and "menu".
        filterLocale	string	undefined	Locale to use in filtering. The default locale is the host environment's current locale.
        selection	any	null	Selected row in single mode or an array of values in multiple mode.
        selectionMode	string	null	Specifies the selection mode, valid values are "single" and "multiple".
        compareSelectionBy	string	deepEquals	Algorithm to define if a row is selected, valid values are "equals" that compares by reference and
        "deepEquals" that compares all fields.
        metaKeySelection	boolean	true	Defines whether metaKey is requred or not for the selection.
        When true metaKey needs to be pressed to select or unselect an item and
        when set to false selection of each item can be toggled individually. On touch enabled devices, metaKeySelection is turned off automatically.
        contextMenu	boolean	false	Enables context menu integration.
        contextMenuSelection	object	null	Selected row instance with the ContextMenu.
        rowHover	boolean	false	When enabled, background of the rows change on hover.
        csvSeparator	string	,	Character to use as the csv separator.
        exportFilename	string	download	Name of the exported file.
        exportFunction	function	download	Custom function to export data.
        autoLayout	boolean	false	Whether the cell widths scale according to their content or not. Does not apply to scrollable tables.
        resizableColumns	boolean	false	When enabled, columns can be resized using drag and drop.
        columnResizeMode	string	fit	Defines whether the overall table width should change on column resize,
        valid values are "fit" and "expand".
        reorderableColumns	boolean	false	When enabled, columns can be reordered using drag and drop.
        expandedRows	array	null	A collection of row data display as expanded.
        expandedRowIcon	string	pi-chevron-down	Icon of the row toggler to display the row as expanded.
        collapsedRowIcon	string	pi-chevron-right	Icon of the row toggler to display the row as collapsed.
        rowGroupMode	string	null	Defines the row group mode, valid options are "subheader" and "rowspan".
        groupRowsBy	string|array	null	One or more field names to use in row grouping.
        expandableRowGroups	boolean	false	Whether the row groups can be expandable.
        expandedRowGroups	array	null	An array of group field values whose groups would be rendered as expanded.
        stateStorage	string	session	Defines where a stateful table keeps its state, valid values are "session" for sessionStorage and "local" for localStorage.
        stateKey	string	null	Unique identifier of a stateful table to use in state storage.
        editMode	string	null	Defines the incell editing mode, valid options are "cell" and "row".
        editingRows	array	null	A collection of rows to represent the current editing data in row edit mode.
        rowClass	function	null	A function that takes the row data as a parameter and returns a string to apply a particular class for the row.
        rowStyle	object	null	A function that takes the row data as a parameter and returns the inline style for the corresponding row.
        scrollable	boolean	false	When specified, enables horizontal and/or vertical scrolling.
        scrollDirection	string	vertical	Orientation of the scrolling, options are "vertical", "horizontal" and "both".
        scrollHeight	string	null	Height of the scroll viewport in fixed pixels or the "flex" keyword for a dynamic size.
        virtualScrollerOptions	object	null	Whether to use the virtualScroller feature. The properties of VirtualScroller component can be used like an object in it.
        Note: Currently only vertical orientation mode is supported.
        frozenValue	array	null	Items of the frozen part in scrollable DataTable.
        responsiveLayout	string	stack	Defines the responsive mode, valid options are "stack" and "scroll".
        breakpoint	string	960px	The breakpoint to define the maximum width boundary when using stack responsive layout.
        showGridlines	boolean	false	Whether to show grid lines between cells.
        stripedRows	boolean	false	Whether to displays rows with alternating colors.
        tableStyle	object	null	Inline style of the table element.
        tableClass	string	null	Style class of the table element.-->
    </pv-grid>
    <is-dialog header="Delete Record?" :visible="dialog.delete" @is-confirm="onConfirm(entity.mask)" @is-cancel="onCancel()">
        <div class="confirmation-content p-d-flex">
            <div><i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" /></div>
            <div>
                You are about to delete this record:
                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteColumns" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data.indexOf('.') !== -1 ? data.split('.')[1] : data) }}</th>
                        <td>:</td>
                        <td> {{ data.indexOf('.') !== -1 ? entity[data.split('.')[0]][data.split('.')[1]] : entity[data] }}</td>
                    </tr>
                </table>
                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
</template>
<script>
    const config = require("@/assets/lib/cosmos/_js/config.js").default.config;
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: "GridTemplate",
        props: {
            // Standard Properties
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            // Data Source Properties
            source: { type: [String, Array, Object], default: null },
            sourceType: { type: String, default: 'store', validator: (value) => ['array', 'store', 'url', 'storename'].indexOf(value.toLowerCase()) !== -1 },
            sourcePaged: { type: Boolean, default: true },
            lazy: { type: Boolean, default: true },
            // Data Filters
            filters: { type: Object },
            searchFilters: { type: Array },
            searchKeyword: { type: String, default: null },
            // Pagination
            paginator: { type: Boolean, default: true },
            paginatorPosition: { type: String, default: 'bottom', validator: (value) => ['bottom', 'top'].indexOf(value.toLowerCase()) !== -1 },
            paginatorTemplate: { type: String, default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport' },
            pageReportTemplate: { type: String, default: 'Showing {first} - {last} of {totalRecords} records' },
            pageSize: { type: Number, default: 10 },
            pageLink: { type: Number, default: 5 },
            rowSizes: { type: Array, default: () => [5, 10, 15, 25, 50, 100] },
            // Selection
            showSelection: { type: Boolean, default: true },
            multiSelect: { type: Boolean, default: true },
            // Columns
            columns: { type: Array },
            autoGenerate: { type: Boolean, default: false },
            useColumnGroup: { type: Boolean, default: false },
            groupBy: { type: String },
            key: { type: String, default: 'id' },
            // Additional Features
            rowReorder: { type: Boolean, default: false },
            multiLevel: { type: Boolean, default: false },
            csvSeparator: { type: String, default: ',' },
            filename: { type: String, default: null },
            // CRUD Operations
            showCheckbox: { type: Boolean, default: true },
            showHeader: { type: Boolean, default: true },
            showOperation: { type: Boolean, default: true },
            showAdd: { type: Boolean, default: true },
            showExport: { type: Boolean, default: true },
            showEdit: { type: Boolean, default: true },
            showDelete: { type: Boolean, default: true },
            scrollable: { type: Boolean, default: false },
            operationWidth: { type: Number, default: 7 },
            deleteColumns: { type: Array },
            forceRefresh: { type: Boolean },
            baseRecordID: { type: Number, default: 1000 },
            selectedReset: { type: Boolean },
            persistent: { type: Boolean, default: true },
            bypassArrayFilter: { type: Boolean, default: false }
        },
        data() {
            return {
                exportFilename: null,
                activity: { loading: true },
                dialog: { delete: false },
                entity: null,
                selected: [],
                filter: [],
                ako: {
                    global: {
                        value: null,
                        matchMode: 'contain'
                    }
                },
                result: {
                    total: 0,
                    data: []
                },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                expanded: {
                    level1: null,
                    level2: null,
                    level3: null,
                    level4: null,
                    level5: null
                },
                router: {
                    name: null,
                    module: null,
                    title: null,
                    subtitle: null,
                    model: null,
                    schema: null
                },
                keys: null
            }
        },
        watch: {
            source: {
                handler() {
                    this.getSource().then((response) => { this.result = response });
                },
                deep: true
            },
            forceRefresh() {
                if (this.forceRefresh) {
                    this.$nextTick(() => { this.onRefresh(); });
                    if (this.selectedReset) { this.selected = []; }
                        
                }
            },
            keys() {
                this.$nextTick(() => { this.onRefresh(); });
            },
            selected: {
                handler() {
                    this.$emit("is-selected", this.selected);
                },
                deep: true
            }
        },
        computed: {
            activeFilters() {
                var filter = {};
                if (this.filters)
                    filter = this.filters;
                filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
                return filter;
            },
            stateStorage() {
                return this.persistent ? 'session' : null;
            },
            stateKey() {
                return this.persistent ? `dt-state-${this.id}` : null;
            }
           

        },
        methods: {
            generateFilename() {
                var filename = this.id;
                if (this.filename)
                    filename = this.filename;
                filename = filename.replace(/.csv/i, "");
                return filename + "_" + this.$filters.toDateFileName(new Date());
            },
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    this.onRefresh();
                }
            },
            onCreate() { this.$router.push({ name: `${(this.sourceType.toLowerCase() === 'store' && typeof (this.source) === 'string' ? this.source : this.router.name)}-add` }); },
            onEdit(mask) {
                this.getRouter();
                this.$router.push({ name: `${(this.sourceType.toLowerCase() === 'store' && typeof (this.source) === 'string' ? this.source : this.router.name)}-edit`, params: { mask: mask } });
            },
            onDelete(id) {
                this.entity = this.result.data.filter(x => x.id == id)[0];
                this.dialog.delete = true;
            },
           onConfirm(mask) {
                this.$store.dispatch(this.router.module + "/deleteRecordByMask", mask).then((response) => {
                    if (response && response.success) {
                        this.$toast.add({ severity: 'success', summary: 'Record Deleted Sucessfully', detail: 'The record was successfully deleted', life: 3000 });
                        this.onRefresh();
                    }
                    else {
                        let _error = this.errors != undefined ? this.errors[0]?.message ?? response?.errors[0]?.message : response?.errors[0]?.message ?? "";
                        if (_error.indexOf("The DELETE statement conflicted with the REFERENCE constraint") != -1) {
                            this.$toast.add({ severity: 'error', summary: 'Record Delete Failed', detail: "Deletion of the record failed due to the presence of associated transactional activity." });
                        }
                        else
                            this.$toast.add({ severity: 'error', summary: 'Record Delete Failed', detail: _error, life: 5000 });
                    }
                    this.dialog.delete = false;
                }).catch((err) => {
                    console.log(err);
                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed', detail: "Unable to delete the record.", life: 10000 });
                    this.dialog.delete = false;
                });
            },
            onCancel() {
                this.dialog.delete = false;
                this.entity = null;
            },
            onExport() {
                this.exportFilename = this.generateFilename();
                this.$refs[this.id].exportCSV();
            },
            onRefresh() {
                this.activity.loading = true;
                this.result = { data: [], total: 0 };
                this.getSource().then((response) => {
                    this.result = response;
                    this.$nextTick(() => { this.activity.loading = false; });
                    if (this.sourceType.toLowerCase() === 'array') {
                        this.$emit("is-refresh");
                    }
                });
            },
            onExpandAll() {
                this.expanded.level1 = null;
                this.expanded.level2 = null;
                this.expanded.level3 = null;
                this.expanded.level4 = null;
                this.expanded.level5 = null;
            },
            onCollapseAll() {
                this.expanded.level1 = null;
                this.expanded.level2 = null;
                this.expanded.level3 = null;
                this.expanded.level4 = null;
                this.expanded.level5 = null;
            },
            onChangePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
                //this.getSource().then((response) => { this.result = response; });


                //event.originalEvent: Browser event
                //event.page: New page number
                //event.pageCount: Total page count
                //event.first: Index of first record
                //event.rows: Number of rows to display in new page
                //event.sortField: Field to sort against
                //event.sortOrder: Sort order as integer
                //event.multiSortMeta: MultiSort metadata
                //event.filters: Collection of active filters
                //event.filterMatchModes: Match modes per field
            },
            onFilterPage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                //                this.getSource().then((response) => { this.result = response.data; });

                //event.originalEvent: Browser event
                //event.first: Index of first record
                //event.rows: Number of rows to display in new page
                //event.sortField: Field to sort against
                //event.sortOrder: Sort order as integer
                //event.multiSortMeta: MultiSort metadata
                //event.filters: Collection of active filters
                //event.filteredValue: Filtered collection(non - lazy only)
            },
            onSortPage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.getSource().then((response) => { this.result = response; });
                //event.originalEvent: Browser event
                //event.first: Index of first record
                //event.rows: Number of rows to display in new page
                //event.sortField: Field to sort against
                //event.sortOrder: Sort order as integer
                //event.multiSortMeta: MultiSort metadata
                //event.filters: Collection of active filters
                //event.filterMatchModes: Match modes per field
            },
            onRowDoubleClick(e) {
                if (this.showOperation && this.showEdit) {
                    this.selected = this.selected.filter((x) => x.mask !== e.data.mask);
                    this.onEdit(e.data.mask);
                }
            },
            getRouter() {
                const router = this.$router.currentRoute._value;
                const meta = router.meta;
                this.router.name = router.name.replace("-index", "").replace("-add", "").replace("-edit", "").replace("-create", "");
                this.router.module = meta.module;
                this.router.model = meta.model;
                this.router.schema = meta.schema;
                this.router.title = meta.title;
                this.router.subtitle = meta.subtitle;
            },
            async getSource() {
                this.activity.loading = true;
                let type = typeof (this.source);
                let dataSource = { total: 0, data: [] };
                if (this.sourceType.toLowerCase() === 'array') {                    
                    let source = (this.source ?? []).filter(x => ((x.name != undefined && x.name != null) ? x.name?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : this.bypassArrayFilter)
                        || ((x.code != undefined && x.code != null) ? x.code?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : this.bypassArrayFilter)
                        || ((x.text != undefined && x.text != null) ? x.text?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : this.bypassArrayFilter)
                        || ((x.description != undefined && x.description != null) ? x.description?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : this.bypassArrayFilter)
                        || ((x.searchKey != undefined && x.searchKey != null) ? x.searchKey?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : this.bypassArrayFilter)
                    );
                    if (source !== null)
                        dataSource = { total: (source ?? []).length, data: [...(source ?? [])]?.slice(this.page.first) };
                    setTimeout(() => { this.activity.loading = false; }, 100);
                    this.$emit("is-source", dataSource);
                }
                else if (this.sourceType.toLowerCase() === 'url') {
                    let pageDetails = this.sourcePaged ? (((this.source ?? "").indexOf('?') !== -1 ? '&' : '?') + `page=${(this.page.current + 1)}&pageSize=${(this.page.size)}`) : "";
                    let url = `${config.endpoint.api}/${('~' + this.source + '~').replace('~/', '').replace('/~', '')}${pageDetails}`.replace('~/', '').replace('/~', '').replace('~&', '&').replace('~?', '?');
                    if (this.source.indexOf('https://') !== -1)
                        url = `${this.source}${pageDetails}`;
                    if (url.lastIndexOf('~') === (url.length - 1))
                        url = url.substring(0, url.lastIndexOf('~'));
                    if (this.source !== null || type === 'string') {
                        if (url.toLowerCase().indexOf("paged") >= 0) {
                            await this.$axios.get(url, { headers: { filter: this.searchKeyword ?? this.activeFilters?.global?.value ?? "", fields: JSON.stringify(this.searchFilters ?? []) } })
                                .then((response) => {
                                    dataSource.total = response?.data?.data?.result?.total ?? response.data?.result?.total ?? (response.data?.result ?? []).length ?? (response?.result ?? []).length;
                                    dataSource.data = (response?.data?.data?.result?.data ?? response?.data?.result?.data ?? response.data?.result ?? response?.result ?? []);
                                    this.$emit("is-source", dataSource);
                                    this.activity.loading = false;
                                })
                                .catch(() => { this.activity.loading = false; });
                        }
                        else {
                            await this.$axios.get(url, { headers: { filter: this.searchKeyword ?? this.activeFilters?.global?.value ?? "", fields: JSON.stringify(this.searchFilters ?? []) } })
                                .then((response) => {
                                    dataSource.total = response?.result?.total ?? (response?.data?.result ?? []).length;
                                    this.$emit("is-source", { total: dataSource.total, data: [...(response?.result ?? response.data?.result ?? [])] });
                                    dataSource.data = (response?.result ?? response.result?.data ?? response.data?.result ?? []).splice(this.page.first, this.page.size);
                                    this.activity.loading = false;
                                })
                                .catch(() => { this.activity.loading = false; });
                        }
                    }
                }
                else if (this.sourceType.toLowerCase() === 'storename') {
                    if (this.source !== null || type === 'string')
                        if (this.sourcePaged)
                            await this.$store.dispatch(this.source + "/getPaged", { page: this.page.current + 1, pageSize: this.page.size, where: this.activeFilters, sort: null, fields: this.searchFilters })
                                .then((response) => {
                                    dataSource.total = response?.result?.total ?? (response?.result?.data ?? []).length;
                                    dataSource.data = response?.result?.data ?? [];
                                    this.$emit("is-source", dataSource);
                                    this.activity.loading = false;
                                })
                                .catch(() => { this.activity.loading = false; });
                        else
                            await this.$store.dispatch(this.source + "/getAll", { where: this.activeFilters, sort: null, fields: this.searchFilters })
                                .then((response) => {
                                    dataSource.total = response?.result?.total ?? (response?.result?.data ?? response?.result ?? []).length;
                                    dataSource.data = response?.result?.data ?? response?.result ?? [];
                                    let source = (dataSource.data ?? []).filter(x => (x.name ? x.name?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : false)
                                        || (x.code ? x.code?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : false)
                                        || (x.text ? x.text?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : false)
                                        || (x.description ? x.description?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : false)
                                        || (x.searchKey ? x.searchKey?.toLowerCase().indexOf((this.activeFilters?.global?.value ?? "").toLowerCase()) !== -1 : false)
                                    );
                                    if (source !== null)
                                        dataSource = { total: (source ?? []).length, data: [...(source ?? [])]?.slice(this.page.first) };
                                    this.$emit("is-source", dataSource);
                                    this.activity.loading = false;
                                })
                                .catch(() => { this.activity.loading = false; });
                }
                else {
                    if (this.source === null || type === 'string')
                        if (this.sourcePaged)
                            await this.$store.dispatch(this.router.module + "/getPaged", { page: this.page.current + 1, pageSize: this.page.size, where: this.activeFilters, sort: null, fields: this.searchFilters })
                                .then((response) => {
                                    dataSource.total = response?.result?.total ?? (response?.result?.data ?? []).length;
                                    dataSource.data = response?.result?.data ?? [];
                                    this.$emit("is-source", dataSource);
                                    this.activity.loading = false;
                                })
                                .catch(() => { this.activity.loading = false; });
                        else
                            await this.$store.dispatch(this.router.module + "/getAll", { where: this.activeFilters, sort: null, fields: this.searchFilters })
                                .then((response) => {
                                    dataSource.total = response?.result?.total ?? (response?.result?.data ?? response?.result ?? []).length;
                                    dataSource.data = response?.result?.data ?? response?.result ?? [];
                                    this.$emit("is-source", dataSource);
                                    this.activity.loading = false;
                                })
                                .catch(() => { this.activity.loading = false; });
                }
                return dataSource;
            }
        },
        created() {
            this.page.size = this.pageSize;
            this.exportFilename = this.generateFilename();
            this.getRouter();
        },
        mounted() {
            this.$nextTick(() => {
                this.getSource().then((response) => {
                    this.result = response;
                    this.activity.loading = false;
                    //this.$emit("is-source", this.result);
                });
            })
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.$emit('is-refs', this.$refs);
        },
        unmounted() {
            try {
                var session = JSON.parse(sessionStorage.getItem(`dt-state-${this.id}`));
                session.first = 0;
                sessionStorage.setItem(`dt-state-${this.id}`, JSON.stringify(session));
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-paginator .p-paginator-pages button.p-paginator-page.p-paginator-element { border: 1px solid var(--border-color-grid); margin: 0; border-right: 0; border-radius: 0 !important; }
    .p-paginator .p-paginator-next.p-paginator-element { border: 1px solid var(--border-color-grid); border-right: 0; border-radius: 0; margin: 0; }
    .p-paginator .p-paginator-prev.p-paginator-element { border: 1px solid var(--border-color-grid); border-radius: 0; border-right: 0; margin: 0; }
    .p-paginator .p-paginator-last.p-paginator-element { border: 1px solid var(--border-color-grid); border-radius: 0 5px 5px 0; margin: 0; }
    .p-paginator .p-paginator-first.p-paginator-element { border: 1px solid var(--border-color-grid); border-right: 0;border-radius: 5px 0 0 5px; margin-right: 0; }
    .p-paginator .p-highlight.p-paginator-element, .p-paginator .p-paginator-element:hover { border: 1px solid var(--border-color-grid) !important; }
    .p-paginator.p-component { padding: 2px; }
    .p-paginator-page.p-paginator-element { border-radius: 4px !important; }
    .p-paginator .p-paginator-current { margin-left: auto; }
    .p-paginator .p-paginator-right-content { margin-left: 0; height: 3rem !important; width: 3rem !important; line-height: 2.75rem; }
    .p-paginator .p-paginator-left-content { margin-right: 0; margin-left: 3px; height: 3rem !important; width: 3rem !important; line-height: 2.75rem; }
    .p-field-hidden { visibility: hidden;  display: none; }

    button.p-link.p-disabled {
        opacity: 0.50;
        color: var(--text-color) !important;
    }
</style>