<template>
    <div>
        <h4 style="margin-bottom: 2rem;">AVAILABLE WEBHOOKS</h4>
        <pv-listbox :disabled="true" :options="model" listStyle="max-height:450px" optionLabel="displayName">
            <template #option="slotProps">
                <div class="p-d-flex p-jc-between" style="position: relative;width: 100%;">
                    <div class="flex-1 flex gap-4">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            <div class="pr-3"><i :class="`fas fa-fw fa-${(slotProps.option.isActive ? 'check' : 'times')}`" :style="`font-size: 2rem; color: var(--text-${(slotProps.option.isActive ? 'success' : 'danger')}); padding-right: 1rem` "></i></div>
                            <div>
                                <div style="font-size: 1.25rem; font-weight: bold;">{{ slotProps.option.name }}</div>
                                <div style="font-size: 0.75rem; "> {{ slotProps.option.entity }}/{{ slotProps.option.action }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-0 flex gap-2 relative">
                        <pv-button v-if="!isAct" :label="(slotProps.option.isActive ? 'Disable' : 'Enable')" :class="`p-button-${(slotProps.option.isActive ? 'danger' : 'success')}`" icon="fa fa-cogs fa-fw" style="z-index: 999; min-width: 7rem !important;" @click="webhook = slotProps.option"></pv-button>
                    </div>
                </div>
            </template>
        </pv-listbox>
        <div style="font-size: 0.9rem">
            <strong>NOTE:</strong>
            If the button label is "Enable," clicking it will activate the webhook. If the label is anything else, clicking it will deactivate the webhook.
        </div>
        <is-dialog header="Webhook Change Status" :visible="webhook" @is-confirm="onConfirm(webhook)" @is-cancel="webhook = null">
            <div class="confirmation-content p-d-flex">
                <div>
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                </div>
                <div>
                    You are about to <b>{{ webhook.isActive ? 'disable' : 'enable'}}</b> this webhook, please be aware of the following:
                    <ul>
                        <li v-if="!webhook.isActive">When a webhook is enabled, COSMOS will only process orders from the e-commerce platform after they are marked as fulfilled because COSMOS doesn't handle the fulfillment process.</li>
                        <li v-else>Disabling a webhook will stop any ongoing background processes related to it. Make sure there are no active processes before disabling.</li>
                    </ul>
                    <br />Are you sure you still want to proceed?
                </div>
            </div>
        </is-dialog>
    </div>
</template>
<script>
    export default {
        name: "CartLocationForm",
        props: {
            parentID: { type: Number, default: 0 },
            parentMask: { type: String, default: null },
            customerID: { type: Number, default: 0 },
            modelValue: { type: Array, default: () => [] },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: [],
                webhook: null,
                companies: [],
                platform: null,
                accessScope: null,
                dialog: {
                    link: false
                }
            }
        },
        computed: {
            countries() { return this.$vm.$countries ?? []; },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            warehouses() { return this.accessScope.warehouses.filter(x => x.customers.filter(y => y === this.customerID).length > 0); }
        },
        watch: {
            modelValue() { this.model = this.modelValue ?? []; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); }
        },
        methods: {
            onGetWarehouseAddress(id) {
                if (id) {
                    let company = this.companies.filter(x => x.id === id)[0];
                    let country = this.countries.filter(x => x.id === company.countryID)[0];
                    return `${(company.address1 ? company.address1 + ', ' : '')}${(company.address2 ? company.address2 + ', ' : '')}${(company.address3 ? company.address3 + ', ' : '')}${(company.city ? company.city + ', ' : '')}${(company.state ? company.state + ', ' : '')}${(company.postalCode ? company.postalCode + ', ' : '')}${(country ? country.code: '')}`;
                }
                return null;
            },
            onLinkWarehouse(data) {
                this.dialog.link = true;
                this.location = this.$filters.init(data);
            },
            onConfirm(data) {
                this.$store.dispatch("store/putURL", { url: `/services/facility/cart/${this.parentMask}/webhook`, payload: data })
                    .then(() => {
                        this.webhook = null;
                        this.$emit("is-force-refresh");
                    })
                    .catch(() => {
                        this.activity.loading = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    });
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            this.companies = this.$config.common.$warehouse(this.sessionKeys.environment);
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>
<style>
    #location-form .p-dialog-content {
        overflow: hidden !important;
    }
</style>