<template>
    <div style="width: 100%">
        <h4 style="margin-bottom: 2rem;">PRINTERS</h4>
        <form-lookup id="Warehouse" :disabled="disabled" disabledType="object" :source="warehouses" :defaultValue="warehouses[0]?.id" v-model="model" :required="true" :v$="v$" @is-sync="onWarehouseSelect" />
        <form-lookup id="autoPrinting" :disabled="disabled" disabledType="object" type="enum" source="printersettingusage" v-model="printer.autoPrinterSetting" :default-value="100" :required="true" @is-sync="onGetPrinters" :sorted="false" />
        <form-lookup v-if="printer.autoPrinterSetting !== 100" :disabled="disabled" disabledType="object" id="labelPrinter" label="Label Printer" :source="printerSource" :value="printer.labelPrinterID" @is-sync="printer.labelPrinterID = $event?.value ?? (newLoad < 13 ? printer.labelPrinterID : null)"  :required="printer.autoPrinterSetting" />
        <form-lookup v-if="printer.autoPrinterSetting !== 100" :disabled="disabled" disabledType="object" id="documentPrinter" label="Document Printer" :source="(printers ?? []).filter(x => x.type === 2)" :value="printer.documentPrinterID" @is-sync="printer.documentPrinterID = $event?.value ?? (newLoad  < 13  ? printer.documentPrinterID : null)"  />
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    //import { required, maxLength, minLength, helpers, email } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    //const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: "StoreDefaultsForm",
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        props: {
            customerID: { type: Number, default: null },
            modelValue: { type: Number, default: null },
            modelPrinter: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
            disabled: { type: Boolean, default: false },
            printers: { type: Array, default: null }
        },
        data() {
            return {
                newLoad: 0,
                model: null,
                printer: {
                    autoPrinterSetting: 100,
                    labelPrinterID: null,
                    documentPrinterID: null
                },
                accessScope: null,
            };
        },
        watch: {
            modelValue() { this.model = this.modelValue ?? { failed: null, success: null } },
            "modelPrinter": {
                handler() {
                    this.printer = this.modelPrinter;
                    if (this.printer == undefined) this.printer = { autoPrinterSetting: 100 }
                },
                immediate: true,
                deep: true
            },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); },
            printers() { this.onGetPrinters() },
            printer() { this.onSync(); },
            v$() { this.$emit("update:modelInvalid", this.v$.$error || this.v$.$invalid); }
        },
        computed: {
            warehouses() { return (this.accessScope?.warehouses ?? []).filter(x => x.customers.filter(y => y === this.customerID).length > 0) ?? []; },
            printerSource() { return [...new Set(this.printers ?? [])].filter(x => x.companyID === ((this.printer?.autoPrinterSetting ?? 100) === 100 ? -10000 : (this.printer?.autoPrinterSetting === 300 ? this.model : this.customerID))) ?? []; }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: { $autoDirty: true },
                //model: {
                //    warehouseID: { $autoDirty: true, required },
                //    currencyID: { $autoDirty: true, required },
                //    content: { $autoDirty: true, required, minLength: minLength(5), maxLength: maxLength(255) },
                //    email: { $autoDirty: true, email, required },
                //    telephone: { $autoDirty: true, required, minLength: minLength(7), maxLength: maxLength(15), regex: helpers.withMessage("The field only accepts numeric values without whitespace. Please ensure that at least 1 number is non-zero.", regex("^([0-9]*[1-9][0-9]*){0,15}$"))  },
                //    packagingType: { $autoDirty: true, required },
                //    incoterm: { $autoDirty: true, required },
                //    weightUnit: { $autoDirty: true, required },
                //    dimensionUnit: { $autoDirty: true, required }
                //}
            };
            return state;
        },
        methods: {
            onWarehouseSelect() {
                //if (!this.disabled) {
                //    this.printer.autoPrinterSetting = 100;
                //    this.printer.labelPrinterID = null;
                //    this.printer.documentPrinterID = null;
                //}
            },
            onGetPrinters() {
                this.newLoad++;
                if (!this.disabled) {
                    let a = this.printer.labelPrinterID;
                    let b = this.printer.documentPrinterID;
                    this.printer.labelPrinterID = a;
                    this.printer.documentPrinterID = b;
                }
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("update:modelPrinter", this.printer);
                this.$emit("is-sync", this.model);
            }
        },
        mounted() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
            if (this.modelPrinter)
                this.printer = this.modelPrinter;
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
            if (this.modelPrinter)
                this.printer = this.modelPrinter;
        }
    }
</script>