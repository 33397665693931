<template>
    <div>
        <form-lookup id="marketplaceID" label="Marketplace" mode="dropdown" v-model:modelData="platform" v-model="model.marketplaceID" :defaultValue="defaultPlatform" :source="platforms" :required="true" optionGroup="text" :disabled="disabled" disabledType="object" />
        <div v-if="!disabled">
            <pv-accordion id="credentialform" :activeIndex="[0,1]" :multiple="true">
                <pv-accordion-tab style="padding: 0;" v-for="category in [...new Set((platform?.credentialFields ?? []).map(x => x.category).filter(x => x != 'General'))]" :key="category">
                    <template #header>
                        <span class="flex align-items-center w-full">
                            <i class="fa fa-bars fa-fw"></i> &nbsp;&nbsp;&nbsp;
                            <span class="is-text-bold upper" style="text-transform: uppercase">{{ category }}</span>
                        </span>
                    </template>
                    <pv-accordion :activeIndex="0" v-if="[...new Set((platform?.credentialFields ?? []).filter(x => x.category === category).map(x => x.subCategory))].length > 1">
                        <pv-accordion-tab v-for="subcategory in [...new Set((platform?.credentialFields ?? []).filter(x => x.category === category).map(x => x.subCategory))]" :key="subcategory">
                            <template #header>
                                <span class="flex align-items-center w-full">
                                    <i class="fa fa-bars fa-fw"></i> &nbsp;&nbsp;&nbsp;
                                    <span class="is-text-bold ml-4">{{ subcategory }}</span>
                                </span>
                            </template>
                            <div v-if="category === 'Credential'">
                                <div v-for="credential in [...new Set((platform?.credentialFields ?? []).filter(x => x.category == 'General'))]" :key="credential">
                                    <form-input v-if="credential.component === 100"
                                                v-model="model[credential.field.toLowerCase()]"
                                                :id="credential.field"
                                                :label="credential.label"
                                                :ph="credential.placeholder"
                                                :pattern="credential?.validation?.regEx.pattern?.replace('(?i)', '')"
                                                :pattern-message="credential?.validation?.regEx?.message"
                                                :max-length="credential?.validation?.length?.maximum"
                                                :required="credential?.validation?.required ?? false"
                                                :v$="v$"
                                                :vError="v$.model[credential.field.toLowerCase()].$errors[0]?.$message"
                                                :disabled="disabled"
                                                :showCopy="false"
                                                @is-sync="onSetProperty(credential.property, $event)" />
                                </div>
                            </div>
                            <div v-for="credential in (platform?.credentialFields ?? []).filter(x => x.category === category && x.subCategory === (subcategory ?? x.subCategory))" :key="credential.field">
                                <form-input v-if="credential.component === 100"
                                            v-model="model[credential.field.toLowerCase()]"
                                            :id="credential.field"
                                            :label="credential.label"
                                            :ph="credential.placeholder"
                                            :pattern="credential?.validation?.regEx.pattern?.replace('(?i)', '')"
                                            :pattern-message="credential?.validation?.regEx?.message"
                                            :max-length="credential?.validation?.length?.maximum"
                                            :required="credential?.validation?.required ?? false"
                                            :v$="v$"
                                            :vError="v$.model[credential.field.toLowerCase()].$errors[0]?.$message"
                                            :disabled="disabled"
                                            :showCopy="false"
                                            @is-sync="onSetProperty(credential.property, $event)" />

                            </div>
                        </pv-accordion-tab>
                    </pv-accordion>
                    <div v-else>
                        <div v-if="category === 'Credential'">
                            <div v-for="credential in [...new Set((platform?.credentialFields ?? []).filter(x => x.category == 'General'))]" :key="credential">
                                <form-input v-if="credential.component === 100"
                                            v-model="model[credential.field.toLowerCase()]"
                                            :id="credential.field"
                                            :label="credential.label"
                                            :ph="credential.placeholder"
                                            :pattern="credential?.validation?.regEx.pattern?.replace('(?i)', '')"
                                            :pattern-message="credential?.validation?.regEx?.message"
                                            :max-length="credential?.validation?.length?.maximum"
                                            :required="credential?.validation?.required ?? false"
                                            :v$="v$"
                                            :vError="v$.model[credential.field.toLowerCase()].$errors[0]?.$message"
                                            :disabled="disabled"
                                            :showCopy="false"
                                            @is-sync="onSetProperty(credential.property, $event)" />
                            </div>
                        </div>
                        <div v-for="credential in (platform?.credentialFields ?? []).filter(x => x.category === category && x.subCategory === (subcategory ?? x.subCategory))" :key="credential.field">
                            <form-input v-if="credential.component === 100"
                                        v-model="model[credential.field.toLowerCase()]"
                                        :id="credential.field"
                                        :label="credential.label"
                                        :ph="credential.placeholder"
                                        :pattern="credential?.validation?.regEx.pattern?.replace('(?i)', '')"
                                        :pattern-message="credential?.validation?.regEx?.message"
                                        :max-length="credential?.validation?.length?.maximum"
                                        :required="credential?.validation?.required ?? false"
                                        :v$="v$"
                                        :vError="v$.model[credential.field.toLowerCase()].$errors[0]?.$message"
                                        :disabled="disabled"
                                        :showCopy="false"
                                        @is-sync="onSetProperty(credential.property, $event)" />

                        </div>
                    </div>
                    <!--</div>-->
                </pv-accordion-tab>
            </pv-accordion>
        </div>
        <div v-else>
            <div v-for="credential in (platform?.credentialFields ?? [])" :key="credential.field">
                <form-input v-if="credential.component === 100 && model[credential.field.toLowerCase()]"
                            v-model="model[credential.field.toLowerCase()]"
                            :id="credential.field"
                            :label="credential.label"
                            :ph="credential.placeholder"
                            :pattern="credential?.validation?.regEx.pattern?.replace('(?i)', '')"
                            :pattern-message="credential?.validation?.regEx?.message"
                            :max-length="credential?.validation?.length?.maximum"
                            :required="credential?.validation?.required ?? false"
                            :v$="v$"
                            :vError="v$.model[credential.field.toLowerCase()].$errors[0]?.$message"
                            :disabled="disabled"
                            :showCopy="false"
                            @is-sync="onSetProperty(credential.property, $event)" />

            </div>
        </div>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength, helpers, url, email } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: "PlatformCredentialForm",
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        props: {
            modelValue: { type: Object, default: null },
            modelPlatform: { type: Object, default: null },
            modelInvalid: { type: Boolean, default: false },
            modelPayload: { type: Object, default: () => ({ PlatformID: null, Credential: () => { } }) },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: {
                    marketplaceID: 1,
                    credential_01: null,
                    credential_02: null,
                    credential_03: null,
                    credential_04: null,
                    credential_05: null,
                    credential_06: null,
                    credential_07: null,
                    credential_08: null,
                    credential_09: null,
                    credential_10: null,
                    credential_11: null,
                    credential_12: null,
                    credential_13: null,
                    credential_14: null,
                    credential_15: null,
                    tokenIssued: null,
                    tokenExpiry: null
                },
                credentials: {},
                platform: null,
                marketplaces: []
            };
        },
        computed: {
            payload() { return { PlatformID: this.platform?.id ?? this.defaultPlatform, Credentials: this.credentials } },
            direct() { return this.$vm.$marketplaces.filter(x => x.status === 200 && x.integrationType === 100) },
            thirdparty() { return this.$vm.$marketplaces.filter(x => x.status === 200 && x.integrationType === 200) },
            platforms() {
                return [{
                    text: "Direct Integration",
                    items: this.direct,
                },
                {
                    text: "Third-Party Integration",
                    items: this.thirdparty,
                }].filter(x => (x.items ?? []).length > 0);
            },
            defaultPlatform() {
                return [...new Set(this.direct), ...new Set(this.thirdparty)][0]?.id;
            }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelCredential() { this.credentials = this.modelCredential; },
            platform() { this.onPlatformSync(); },
            model() { this.onSync(); },
            v$() { this.$emit("update:modelInvalid", this.v$.$error || this.v$.$invalid); }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: {
                    marketplaceID: { required, $autoDirty: true },
                    credential_01: { $autoDirty: true },
                    credential_02: { $autoDirty: true },
                    credential_03: { $autoDirty: true },
                    credential_04: { $autoDirty: true },
                    credential_05: { $autoDirty: true },
                    credential_06: { $autoDirty: true },
                    credential_07: { $autoDirty: true },
                    credential_08: { $autoDirty: true },
                    credential_09: { $autoDirty: true },
                    credential_10: { $autoDirty: true },
                    credential_11: { $autoDirty: true },
                    credential_12: { $autoDirty: true },
                    credential_13: { $autoDirty: true },
                    credential_14: { $autoDirty: true },
                    credential_15: { $autoDirty: true },
                }
            };

            (this.platform?.credentialFields ?? []).forEach((data) => {
                let field = data.field.toLowerCase();
                let property = state.model[field];
                if (data.validation.required)
                    property.required = required;
                if ((data?.validation?.length?.minimum ?? 0) > 0)
                    property.minLength = minLength(data.validation.length.minimum);
                if ((data?.validation?.length?.maximum ?? 0) > 0)
                    property.maxLength = maxLength(data.validation.length.maximum);
                if (data?.validation?.regEx && data?.validation?.regEx?.pattern === "ValidateURL")
                    property.url = url;
                else if (data?.validation?.regEx && data?.validation?.regEx?.pattern === "ValidateEmail")
                    property.email = email;
                else if (data?.validation?.regEx && data?.validation?.regEx?.pattern === "ValidateURL")
                    property.regex = helpers.withMessage(data.validation.regEx.message, regex(data.validation.regEx.pattern?.replace('(?i)', '')));
            });

            return state;
        },
        methods: {
            onChange(e) {
                this.model.marketplaceID = e.id;
            },
            onSetProperty(property, e) {
                if (property && !this.disabled)
                    try {
                        this.credentials[property] = e.value;

                    }
                    catch { /* ignore */ }
            },
            onPlatformSync() {
                let model = this.$filters.init(this.model);
                let properties = (this.platform?.credentialFields ?? []).map(x => x.field.toLowerCase());
                for (let i = 1; i <= 15; i++) {
                    let property = "credential_" + i.toString().padStart(2, '0');
                    if (properties.filter(x => x === property).length === 0)
                        model[property] = null;
                }
                this.model = model;
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                if (!this.disabled)
                    this.$emit("update:modelPayload", this.payload);
                this.$emit("update:modelPlatform", this.platform);
                this.$emit("is-sync", this.model);
            },
            getMarketplaces() {
                this.marketplaces = [];
                let grouping = [...new Set(this.$vm.$marketplaces.map(x => x.integrationTypeName))];
                if (grouping.length > 1) {
                    grouping.forEach(y => this.marketplaces.push({
                        label: y,
                        items: this.$vm.$marketplaces.filter(x => x.integrationTypeName === y && x.status === 200)
                    }));
                }
                else
                    this.marketplaces = this.$vm.$marketplaces.filter(x => x.status === 200);
            }
        },
        mounted() {
            if (!this.modelPlatform)
                this.platform = this.defaultPlatform
        },
        created() {
            this.getMarketplaces();
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform) {
                this.platform = this.modelPlatform;
                this.onPlatformSync();
            }
            if (this.modelPayload)
                this.credentials = this.modelPayload.Credentials;
        }
    }
</script>
<style>
    #credentialform .p-accordion-header > a {
        padding: 0.75rem !important;
    }

    #credentialform .p-accordion-content {
        padding: 2rem 0 !important;
    }
        #credentialform .p-accordion-content > .p-accordion-content {
            padding: 0 !important;
        }

        #credentialform .p-accordion-content .p-accordion-content {
            padding: 2rem 0.25rem 0.25rem !important;
        }
</style>
