<template>
    <div>
        <pv-grid id="SalesOrderGrid"
                 :value="(source?.data ?? [])"
                 :totalRecords="(source?.total ?? (source?.data ?? []).length)"
                 v-model:filters="filters"
                 v-model:selection="selected"
                 :responsive="'scroll'"
                 :paginator="true"
                 responsiveLayout="scroll"
                 :paginatorPosition="'bottom'"
                 :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
                 :pageLinkSize="5"
                 :rows="10"
                 :rowsPerPageOptions="[5, 10, 15, 25, 50, 100]"
                 :alwaysShowPaginator="true"
                 :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
                 :loading="activity.loading"
                 :globalFilterFields="['transactionID']"
                 :showAdd="false"
                 :showExport="false"
                 :rowHover="true"
                 autoLayout
                 showGridlines
                 stripedRows
                 @page="onChangePage"
                 @row-dblclick="onRowDoubleClick">
            <template #header>
                <div class="p-d-flex p-jc-between">
                    <div>
                        <pv-button @click="getSource" style="margin-right: 1rem; padding: 0.75rem;" label="Load Sales Orders" icon="fa fa-table fa-fw" :disabled="parameters.cart === '00000000000000000000000000000000'" :loading="activity.loading"></pv-button>
                    </div>
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%;" />
                    </span>
                </div>
            </template>
            <pv-grid-column field="id" selectionMode="multiple" headerStyle="width: 3rem"></pv-grid-column>
            <pv-grid-column field="transactionID" header="Transaction ID" style="width: 10rem; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0; font-weight: bold;">
                        <router-link :to="`/facility/cart/salesorder/${slotProps.data.mask}`">{{ slotProps.data.code }}</router-link>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="reference_01" header="Reference 1" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="reference_02" header="Reference 2" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="reference_03" header="Reference 3" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="statusText" header="Status" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="serviceGroup" header="Delivery Name" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="shipToName" header="Delivery Name" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="shipToCityCountry" header="Delivery Postal Address" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0;">
                        <i :class="`flag flag-${$vm.$countries.filter(x => x.id == slotProps.data.shipTo_CountryID)[0]?.iso2?.toLowerCase()} ml-2`"></i> &nbsp; {{ slotProps.data?.shipTo_City }}, {{ $vm.$countries.filter(x => x.id == slotProps.data.shipTo_CountryID)[0]?.iso2}}
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="shipTo_PostalCode" header="Delivery Postal Code" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
            <pv-grid-column field="orderDate" header="Order Date" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0;">
                        {{  $filters.toDateFullTimeDisplay(slotProps.data?.orderDate)}}
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column field="createDate" header="Process Date" style="width: 100%; text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
                <template #body="slotProps">
                    <div class="p-d-flex p-col-12" style="padding: 0;">
                        {{  $filters.toDateFullTimeDisplay(slotProps.data?.createDate)}}
                    </div>
                </template>
            </pv-grid-column>
            <!-- Column Headers -->
            <pv-grid-column-group type="header">
                <pv-grid-row>
                    <pv-grid-column field="identity" :rowspan="2" selectionMode="multiple" headerStyle="width: 3rem" />
                    <pv-grid-column header="Transaction ID" :rowspan="2" headerStyle="min-width: 2rem" />
                    <pv-grid-column header="Reference 1" :rowspan="2" headerStyle="min-width: 2rem; width: 100%;" />
                    <pv-grid-column header="Reference 2" :rowspan="2" headerStyle="min-width: 2rem; width: 100%;" />
                    <pv-grid-column header="Reference 3" :rowspan="2" headerStyle="min-width: 2rem; width: 100%;" />
                    <pv-grid-column header="Status" :rowspan="2" headerStyle="min-width: 2rem" />
                    <pv-grid-column header="Service Group" :rowspan="2" headerStyle="min-width: 2rem" />
                    <pv-grid-column header="Delivery" :colspan="3" headerStyle="text-align: center !important;" style="text-align: center;" />
                    <pv-grid-column header="Order Date" :rowspan="2" headerStyle="min-width: 2rem" />
                    <pv-grid-column header="Download Date" :rowspan="2" headerStyle="min-width: 2rem" />
                </pv-grid-row>
                <pv-grid-row>
                    <pv-grid-column header="Name" headerStyle="width: 100%" />
                    <pv-grid-column header="Address" headerStyle="min-width: 8rem" />
                    <pv-grid-column header="Postal Code" headerStyle="min-width: 8rem" />
                </pv-grid-row>
            </pv-grid-column-group>
            <template #empty>
                <span v-html="`No records found.`"></span>
            </template>
            <template #loading>
                Retrieving records from database. Please wait...
            </template>
            <template #paginatorRight>
                <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSource" />
            </template>
        </pv-grid>
        <is-dialog id="download-form" :header="`DOWNLOAD ${dialog.downloadAll ? 'ALL' : 'SELECTED'} ORDERS`" :breakpoints="{'2500px': '50vw', '640px': '75vw'}" :visible="dialog.downloadAll || dialog.downloadSelected" @is-confirm="onConfirm(dialog.identities)" @is-cancel="dialog.downloadAll = dialog.downloadSelected = false" :confirmDisabled="confirmDisabled">
            <p>Do you want to download st<span style="font-weight: bold; color: var(--text-danger)">{{dialog.downloadAll ? 'ALL' : (dialog.identities ?? []).length }}</span> order{{ (dialog.identities ?? []).length > 1 ? 's' : '' }}?</p>
            <p style="font-weight: bold">Download Configuration</p>
            <ul style="list-style: none;padding: 0; margin: 0;">
                <li><form-boolean id="performPlatformFulfillment" v-model="dialog.parameters.performPlatformFulfillment" label="If order is unfulfilled, COSMOS will initialize the platform fulfillment. Otherwise, it will only download the order." width="135px" style="font-weight:bold; margin-top: -1.25rem !important;" @is-sync="{ dialog.parameters.closePartialFulfillment = false; dialog.parameters.allowPartialFulfillment = false; }" /></li>
                <li><form-boolean v-if="dialog.parameters.performPlatformFulfillment" id="allowPartialFulfillment" v-model="dialog.parameters.allowPartialFulfillment" label="When platform stock is low, COSMOS can initite partial fulfillment." width="135px" style="font-weight:bold; margin-top: -1.25rem !important;" @is-sync="dialog.parameters.closePartialFulfillment = false" /></li>
                <li><form-boolean v-if="dialog.parameters.allowPartialFulfillment" id="closePartialFulfillment" v-model="dialog.parameters.closePartialFulfillment" label="If partial fulfillment is allowed, COSMOS will mark the order as closed and won't accept further fulfillment." width="135px" style="font-weight:bold; margin-top: -1.25rem !important;" /></li>
                <li><form-boolean id="initiatePlatformFulfillmentNotification" v-model="dialog.parameters.initiatePlatformFulfillmentNotification" label="When platform fulfillment is initiated, COSMOS will prompt the platform to send an internal notification." width="135px" style="font-weight:bold; margin-top: -1.25rem !important;" /></li>
                <li><form-boolean id="initiatePlatformTrackingUpdateNotification" v-model="dialog.parameters.initiatePlatformTrackingUpdateNotification" label="When a tracking update is sent, COSMOS will prompt the platform to send an internal notification." width="135px" style="font-weight:bold; margin-top: -1.25rem !important;" /></li>
                <li></li>
            </ul>
            <p style="font-weight:bold; color: var(--text-danger)">Please be aware that the request will be queued for download and may run in the background and should appear after some time.</p>
            <p>Do you stilll want to proceed with download?</p>
        </is-dialog>
    </div>
</template>
<script>
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: 'ProductDownloadGrid',
        emits: ['is-loading', 'is-reset'],
        props: {
            parameters: { type: Object, default: () => { } }
        },
        data() {
            return {
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.orderNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.customer': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.address': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.shippingMethod': { value: null, matchMode: FilterMatchMode.CONTAINS }
                },
                selected: null,
                source: {
                    total: 0,
                    data: []
                },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                dialog: {
                    downloadAll: false,
                    downloadSelected: false,
                    identities: [],
                    parameters: {
                        performPlatformFulfillment: true,
                        allowPartialFulfillment: false,
                        closePartialFulfillment: false,
                        initiatePlatformFulfillmentNotification: false,
                        initiatePlatformTrackingUpdateNotification: false
                    }
                },
                activity: {
                    loading: true,
                    downloading: false
                },
            }
        },
        watch: {
            'parameters': {
                handler() {
                    this.$nextTick(() => {
                        this.source = {
                            total: 0,
                            data: []
                        };
                        this.$emit("is-reset");
                    });
                }, deep: true, immediate: true
            }
        },
        computed: {
            parameter() {
                return {
                    order_status: this.parameters.filters.status,
                    payment_status: this.parameters.filters.paymentStatus,
                    fulfillment_status: this.parameters.filters.fulfillmentStatus,
                    created_from: this.parameters.date.from,
                    created_to: this.parameters.date.to
                };
            },
            queryString() {
                return `ppf=${(this.dialog.performPlatformFulfillment ? 1 : 0)}&apf=${(this.dialog.allowPartialFulfillment ? 1 : 0)}&cpf=${(this.dialog.closePartialFulfillment ? 1 : 0)}&pfn=${(this.dialog.initiatePlatformFulfillmentNotification ? 1 : 0)}&ptn=${(this.dialog.initiatePlatformTrackingUpdateNotification ? 1 : 0)}`;
            }
        },
        methods: {
            onChangePage(e) {
                this.page.current = e.page;
                this.page.first = e.first;
                this.page.size = e.rows;
                this.onRefresh();
            },
            onRefresh() {
                this.activity.loading = true;
                this.$nextTick(() => { this.activity.loading = false; });
                //this.result = { data: [], total: 0 };
                //this.getSource().then((response) => {
                //    this.result = response;
                //    this.$nextTick(() => { this.activity.loading = false; });
                //});
            },
            getSource() {
                this.activity.loading = true;
                this.$emit("is-loading", this.activity.loading);

                this.$store.dispatch("store/getURL", { url: `/services/facility/salesorder?foreignkeyid=${this.parameters?.cartID ?? 0}&status=${this.parameters?.filters?.fulfillmentstatus ?? 0}&from=${this.$filters.formatDateWithFormat(this.parameters.date.from, 'yyyy-MM-DD')}&to=${this.$filters.formatDateWithFormat(this.parameters.date.to, 'yyyy-MM-DD')}&page=${this.page.current + 1}&rows=${this.page.size}` })
                    .then((response) => {
                        this.source.data = (response?.result ?? []);
                        this.source.total = response?.result[0]?.total ?? 0;
                        this.activity.loading = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    })
                    .catch(() => {
                        this.activity.loading = false;
                        this.$emit("is-loading", this.activity.loading || this.activity.downloading);
                    });
            },
            onRowDoubleClick(e) {
                this.selected = this.selected.filter((x) => x.mask !== e.data.mask);
                this.onEdit(e.data.mask);
            },
            onEdit(mask) {
                this.$router.push({ name: `salesorder-edit`, params: { mask: mask } });
            },
        },
        async created() {
            this.activity.loading = false;
        },
        async mounted() {
            this.activity.loading = false;
        }
    }
</script>
<style>
    #download-form .p-dialog-content {
        overflow: hidden !important;
    }
</style>