<template>
    <div style="width: 100%">
        <form-input id="Code" v-model="model.code" :required="true" :v$="v$" :disabled="disabled" :showCopy="false" />
        <form-input id="Name" v-model="model.name" :required="true" :v$="v$" :disabled="disabled" :showCopy="false" />
        <!--<form-lookup id="ShippingSolution"
                     type="enum" source="storeendsolution"
                     v-model="model.endSolution"
                     :nullable="false"
                     :disabled="disabled"
                     disabledType="object"
                     :v$="v$"
                     vID="endSolution" />
        <form-lookup v-if="model.endSolution === 300"
                     id="ThirdPartySolution"
                     type="enum"
                     source="storethirdpartysolution"
                     v-model="model.thirdPartySolution"
                     :required="true"
                     :disabled="disabled"
                     disabledType="object"
                     :v$="v$"
                     vID="thirdPartySolution" />-->
        <form-datetime id="verificationDate" type="datetime" :value="model.verificationDate" :disabled="true" disabledType="object" :showCopy="false" />
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, maxLength, helpers } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: "StoreNotificationForm",
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        props: {
            modelValue: { type: Object, default: () => {} },
            modelInvalid: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: {
                    code: null,
                    name: null,
                    endSolution: 200,
                    thirdPartySolution: 100,
                    status: 100,
                    verificationDate: null,
                }
            };
        },
        watch: {
            modelValue() { this.model = this.modelValue ?? { failed: null, success: null } },
            model() { this.onSync(); },
            v$() { this.$emit("update:modelInvalid", this.v$.$error || this.v$.$invalid); }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: {
                    code: { $autoDirty: true, required, maxLength: maxLength(255), regex: helpers.withMessage("The field only accepts alphanumeric characters and limited symbols (#, _, ., /, \\, |, -) without whitespace.", regex("^[a-zA-Z0-9#_./\\|-]{0,255}$")) },
                    name: { $autoDirty: true, required, maxLength: maxLength(255) },
                //    endSolution: { $autoDirty: true, required },
                //    thirdPartySolution: { $autoDirty: true, required }
                }
            };
            return state;
        },
        methods: {
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }
    }
</script>