<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 1rem;">
            <h3 style="margin-bottom: 0;">STEP 1: Credentials</h3>
            <p>This step allows the customer to configure their marketplace credentials.</p>
            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-6">
                    <h4 style="margin-bottom: 2rem;">CREDENTIALS</h4>
                    <form-lookup id="Customer" v-model="model.customerID" :defaultValue="accessScope.customers[0]?.id" :source="accessScope.customers" :required="true" @is-sync="customer = $event" />
                    <credential-form v-model="model.credentials"
                                     v-model:modelPlatform="platform"
                                     v-model:modelInvalid="validation.credential"
                                     v-model:modelPayload="payload" />
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    <instruction-form v-model="model.shopInfo"
                                      v-model:modelPlatform="platform"
                                      v-model:modelAccessScope="accessScope" />
                </div>
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <i></i>
            <pv-button @click="onNextPage()" :loading="activity.loading" :iconPos="'right'" :disabled="validation.credential"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-shop fa-fw')}`" style="margin-right: 0.5rem"></i> {{(activity.loading ? 'Validating...' : 'Shop Info')}} <i class="fa fa-chevron-right fa-fw" style="margin-left: 0.5rem"></i></pv-button>
        </div>
    </div>
</template>
<script>
    import credentialForm from '@/components/facility/marketplace/cart/credential-form.vue';
    import instructionForm from '@/components/facility/ecommerce/instruction-form.vue';
    export default {
        name: "PlatformCredentialStep",
        emits: ["update", "update:modelValue", "isSync", "nextPage"],
        components: {
            'credential-form': credentialForm,
            'instruction-form': instructionForm
        },
        props: {
            modelValue: { type: Array, default: () => [] },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
        },
        data() {
            return {
                model: {
                    id: 0,
                    customerID: 0,
                    basicInfo: {
                        code: null,
                        name: null,
                        endSolution: 200,
                        thirdPartySolution: 100,
                        status: 100,
                        verificationDate: null,
                    },
                    defaults: {
                        warehouseID: 0,
                        currencyID: null,
                        content: null,
                        email: null,
                        telephone: null,
                        packagingType: null,
                        incoterm: null,
                        weightUnit: 100,
                        dimensionUnit: 100
                    },
                    credentials: {
                        platformID: 1,
                        credential_01: null,
                        credential_02: null,
                        credential_03: null,
                        credential_04: null,
                        credential_05: null,
                        credential_06: null,
                        credential_07: null,
                        credential_08: null,
                        credential_09: null,
                        credential_10: null,
                        credential_11: null,
                        credential_12: null,
                        credential_13: null,
                        credential_14: null,
                        credential_15: null,
                        tokenIssued: null,
                        tokenExpiry: null
                    },
                    orderAutomation: {
                        type: 100,
                        webhook: 200,
                        fulfillOrder: false,
                        partialFulfillment: false,
                        notifyUser: false,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    productAutomation: {
                        type: 100,
                        webhook: 200,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    notifications: {
                        failed: null,
                        success: null
                    },
                    shopInfo: {
                        code: null,
                        name: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        country: null,
                        countryID: null,
                        contact: null,
                        telephone: null,
                        email: null,
                        shopDomain: null,
                        storeDomain: null,
                        timezone: null,
                        ianaTimezone: null,
                        supportedCurrencies: null,
                        defaults: {
                            primaryLocationID: null,
                            currency: null,
                            weightUnit: null
                        },
                        taxConfiguration: {
                            taxInclusive: false,
                            taxShipping: false
                        }
                    },
                    environmentID: null,
                    storeID: null,
                    storeKey: null,
                    bridgeKey: null,
                    recordStatus: 0
                },
                accessScope: null,
                validation: {
                    credential: false
                },
                platform: null,
                activity: {
                    loading: false
                },
                payload: null,
                customer: null,
                webhooks: null,
                locations: null,
                printers: [],
                settings: null
            };
        },
        computed: {
            disableNext() { return this.validation.credential; },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            environment() { return this.$config.common.$environment().filter(x => x.id === Number(this.sessionKeys?.environment))[0]; },
            header() {
                return {
                    headers: {
                        "x-client-id": 'mpci_' + this.environment?.mask, //the token is a variable which holds the token
                        "x-client-secret": 'mpcs_' + this.customer?.record?.mask?.toLowerCase(), //the token is a variable which holds the token
                        "x-auth-token": 'mpat_' + this.sessionKeys?.currentUser//the token is a variable which holds the token
                    }
                };
            }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
            model() { this.onSync(); },
            'model.id': {
                handler()
                {
                    this.getSource();
                }
            },
            customer: {
                handler() {
                    this.getPrinters(this.customer?.record?.mask);
                    this.getSetting(this.customer?.record?.mask);
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            async onNextPage() {
                this.$nextTick(() => { this.activity.loading = true; });
                var data = null;
                await this.$axios
                    .post(this.$config.config.endpoint.api + '/api/marketplace/cart?test=true'.replaceAll("//", "/"), this.payload, this.header)
                        .then((response) => {
                            if (response.data.Success && response.data.Data) {
                                data = response.data.Data;
                                if (data && this.model.id === 0) {
                                    this.model.code = data?.ShopID;
                                    this.model.name = data?.Name;
                                    // Map Basic Info
                                    this.model.basicInfo = {
                                        code: data?.ShopID,
                                        name: data?.Name,
                                        endSolution: 200,
                                        thirdPartySolution: 100,
                                        status: 200,
                                        verificationDate: new Date()
                                    };
                                    // Map Shop Info
                                    this.model.shopInfo = {
                                        code: data?.ShopID ?? null,
                                        name: data?.Name ?? null,
                                        address1: data?.Address?.Address1 ?? null,
                                        address2: data?.Address?.Address2 ?? null,
                                        address3: data?.Address?.Address3 ?? null,
                                        city: data?.Address?.City ?? null,
                                        state: data?.Address?.State ?? null,
                                        stateCode: data?.Address?.StateCode ?? null,
                                        postalCode: data?.Address?.PostalCode ?? null,
                                        country: data?.Address?.CountryCode ?? null,
                                        countryID: this.$vm.$countries.filter(x => x.iso2 === data?.Address?.CountryCode ?? '99')[0]?.id,
                                        contact: `${data?.Contact?.FirstName} ${data?.Contact?.LastName ?? ''}`.trim(),
                                        telephone: data?.Contact?.Mobile ?? null,
                                        email: data?.Contact?.Email?.toLowerCase() ?? null,
                                        shopDomain: data?.Domain?.Platform ?? null,
                                        storeDomain: data?.Domain?.Store ?? null,
                                        timezone: data?.Timezone?.Timezone ?? null,
                                        ianaTimezone: data?.Timezone?.IANA_Timezone ?? null,
                                        supportedCurrencies: data?.Defaults?.PresentmentCurrencies ?? [...data?.Defaults?.CurrencyID],
                                        defaults: {
                                            primaryLocationID: data?.Defaults?.LocationID ?? null,
                                            currency: data?.Defaults?.Currency ?? null,
                                            weightUnit: data?.Defaults?.WeightUnit ?? null
                                        },
                                        taxConfiguration: {
                                            taxInclusive: data?.TaxImplementation?.TaxInclusive ?? false,
                                            taxShipping: data?.TaxImplementation?.TaxShipping ?? false
                                        }
                                    };
                                    // Map Defaults
                                    this.model.defaults = {
                                        content: null,
                                        currencyID: this.$vm.$currencies.filter(x => x.code === (this.model.shopInfo.defaults.currency ?? "999"))[0]?.id ?? this.settings.default_CurrencyID,
                                        dimensionUnit: 100,
                                        email: null,
                                        incoterm: null,
                                        packagingType: null,
                                        telephone: null,
                                        warehouseID: 0,
                                        weightUnit: (this.$vm.$enums.weightunit.filter(x => x.code.toLowerCase() == (data?.Defaults?.WeightUnit ?? 'kg') || x.text.toLowerCase() == (data?.Defaults?.WeightUnit ?? 'kg')))[0]?.id
                                    };
                                    this.model.defaults.content = this.model.defaults.content ?? this.settings?.default_ParcelContent;
                                    this.model.defaults.packagingType = this.model.defaults.packagingType ?? this.settings?.default_PackageType;
                                    this.model.defaults.incoterm = this.model.defaults.incoterm ?? this.settings?.default_IncoTerm;

                                    this.model.notifications = { success: null, failed: null };
                                    this.model.storeID = null;
                                    this.model.storeKey = null;
                                    this.model.bridgeKey = null;

                                    // Map Locations
                                    this.model.locations = (data?.Locations ?? []).map(x => ({
                                        code: x.LocationID,
                                        name: x.Name,
                                        storeID: data?.ShopID ?? null,
                                        address1: x.Contact?.Address?.Address1 ?? null,
                                        address2: x.Contact?.Address?.Address2 ?? null,
                                        address3: x.Contact?.Address?.Address3 ?? null,
                                        city: x.Contact?.Address?.City ?? null,
                                        state: x.Contact?.Address?.State ?? null,
                                        stateCode: x.Contact?.Address?.StateCode ?? null,
                                        postalCode: x.Contact?.Address?.PostalCode ?? null,
                                        country: x.Contact?.Address?.CountryCode ?? null,
                                        countryID: this.$vm.$countries.filter(y => y.code === (x?.Contact?.Address?.CountryCode ?? '99'))[0]?.id,
                                        warehouseID: null,
                                        environmentID: null,
                                        isDefault: x.LocationID === data?.Defaults?.LocationID,
                                        isActive: x.IsActive,
                                        isLegacy: x.IsLegacy
                                    }));
                                    // Map Webhooks
                                    this.model.webhooks = (data?.AllowedWebhooks ?? []).map(x => ({
                                        code: `TEMP_${Math.random(1000)}`.replace("_0.", "_").substring(0, 20),
                                        name: x,
                                        storeID: data?.ShopID ?? null,
                                        callback: null,
                                        isActive: false
                                    }));
                                    // Intialize Change Page
                                    this.$emit('next-page', { formData: this.model, platform: this.platform, settings: this.settings, printers: this.printers, header: this.header, payload: this.payload, pageIndex: 0 });
                                }
                                this.$nextTick(() => { this.activity.loading = false; });
                            }
                            else if ((response.data.Errors ?? []).length > 0) {
                                this.$toast.add({ severity: 'error', summary: 'Failed To Validate Credentails', detail: response.data.Errors[0].Message, life: 3000 });
                                this.$nextTick(() => { this.activity.loading = false; });
                            }
                        })
                        .catch((ex) => {
                            data = "EXCEPTION:" + ex?.data ?? ex;
                            //if (data)
                            //    commit("SET_ERROR_DATA", ex.data);
                        });
            },
            getSetting(mask) {
                if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null) {
                    this.$nextTick(() => {
                        this.$axios
                            .get(this.$config.config.endpoint.api + `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this?.customer?.mask)}`.replaceAll("//", "/"))
                            .then((response) => {
                                this.settings = response?.data?.result ?? response?.result ?? null;
                            })
                            .catch((ex) => {
                                console.log(ex);
                            });
                    });
                }
            },
            getPrinters(mask) {
                if (mask)
                    this.$store.dispatch("cart/getURL", { url: `/services/settings/companyprinter/company/${mask}/active` })
                        .then((response) => {
                            this.printers = response?.result ?? [];
                        })
            },
            getSource() {
                this.$store.dispatch("cart/getURL", { url: "/api/marketplace/cart?test=true", payload: this.payload }, )
                    .then((response) => {
                        if (response.Status === "SUCCESS" && response.Data) {
                            var data = response.Data;
                            this.webhooks = data;
                            this.$nextTick(() => { this.activity.loading = false; });
                        }
                        else if ((response.Errors ?? []).length > 0) {
                            this.$toast.add({ severity: 'error', summary: 'Failed To Validate Credentails', detail: response.Errors[0], life: 3000 });
                            this.$nextTick(() => { this.activity.loading = false; });
                        }
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Insert Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.$nextTick(() => { this.activity.loading = false; });
                    });
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("update:modelPlatform", this.platform);
                this.$emit("is-sync", this.model);
            }
        },
        mounted() {
            if (this.customer === null) {
                this.customer = this.accessScope.customers[0];
                this.customerID = this.accessScope.customers[0]?.id;
                this.getPrinters(this.customer?.record?.mask);
                this.getSetting(this.customer?.record?.mask);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
        }
    }
</script>