<template>    
    <pv-grid :id="id"
             :ref="id"
             :value="records.data"
             :totalRecords="records.total"
             :alwaysShowPaginator="true"
             :paginator="true"
             :paginatorPosition="'bottom'"
             :pageLinkSize="5"
             :rowsPerPageOptions="[5, 10, 25, 50, 100]"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :first="page.first"
             :rows="page.size"
             :lazy="true"
             :loading="activity.loading"
             :editMode="'row'"
             :dataKey="'id'"
             :scrollWidth="'flex'"
             :scrolHeight="'flex'"
             :responsive="'scroll'"
             :columnResizeMode="'expand'"
             :stateStorage="'session'"
             :stateKey="`dt-state-${id}`"
             responsiveLayout="scroll"
             autoLayout
             showGridlines
             stripedRows
             v-model:editingRows="editingRecords"
             @row-edit-init="onRowEdit"
             @row-edit-save="onRowSave"
             @row-edit-cancel="onRefresh"
             @page="onChangePage">
        <!-- Group Header Template -->

        <pv-grid-column field="sku" header="Code" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="itemName" header="Name" headerStyle="min-width: 10rem;" style="min-width: 10rem; width: calc(100% - 50%); white-space: unset !important; word-break: break-word !important; flex-wrap: wrap; " />
        <pv-grid-column field="htsCode" header="HS Code"  style="min-width:10rem !important; text-align: right;">
            <template #body="slotProps">
                {{ slotProps.data.htsCode }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-input v-model="data['htsCode']" :allowEmpty="false" autofocus style="min-width: 8rem !important; width:100%" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="manufacturerCountryID" header="COO" :frozen="true" headerStyle="width: 4rem;" style="min-width: 7rem; text-align: right;">
            <template #body="slotProps">
                <i v-if="slotProps.data.manufacturerCountryID" :class="`flag flag-${$vm.$countries.filter(x => x.id == slotProps.data.manufacturerCountryID)[0]?.iso2?.toLowerCase()} ml-2`"></i> &nbsp; {{ $vm.$countries.filter(x => x.id == slotProps.data.manufacturerCountryID)[0]?.altName ?? $vm.$countries.filter(x => x.id == slotProps.data.manufacturerCountryID)[0]?.name }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor" style="display: block;">
                    <pv-dropdown :options="sources.countries" v-model="data['manufacturerCountryID']" optionLabel="text" optionValue="id" :filter="true" :required="true" showClear="false">
                        <template #option="slotProps">
                            <div class="country-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: inline-flex; flex: 1 1 auto; max-width: 480px !important; ">
                                <span :class="`flag flag-${slotProps.option.iso2.toLowerCase()}`" style="width: 30px; height: 20px;"></span>
                                <div class="p-ml-2" style="margin: 1px 0; overflow: hidden; white-space: pre-wrap; "><span v-html="`${slotProps.option['text']?.replace('[','&lt;b&gt;&lsqb;')?.replace(']','&rsqb;&lt;/b&gt;')}`"></span></div>
                            </div>
                        </template>
                    </pv-dropdown>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="quantity" header="Quantity" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatNumber(slotProps.data.quantity, 0) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['quantity']" mode="decimal" :min="0" :minFractionDigits="0" :maxFractionDigits="0" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="unitWeight" header="Unit Weight" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatNumber(slotProps.data.unitWeight, 3) }} {{$vm.$enums['weightunit'].filter((x) => x.id == model?.weightUnit)[0]?.text?.toLowerCase()}}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['unitWeight']" mode="decimal" :min="0" :minFractionDigits="3" :maxFractionDigits="3" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" :suffix="$vm.$enums['weightunit'].filter((x) => x.id == salesOrder?.weightUnit)[0]?.text?.toLowerCase()" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="retailPrice" header="Declared Value" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data.retailPrice, globalization.currency, globalization.country, 2) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['retailPrice']" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" :prefix="currencySymbol" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="discountPrice" header="Discount Value" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data.discountPrice, globalization.currency, globalization.country, 2) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['discountPrice']" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" :prefix="currencySymbol" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="taxPrice" header="Tax Value" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data.taxPrice, globalization.currency, globalization.country, 2) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['taxPrice']" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" :prefix="currencySymbol" />
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dutyPrice" header="Duty Paid Value" style="width: 7rem; text-align: right;">
            <template #body="slotProps">
                {{ $filters.formatCurrency(slotProps.data.dutyPrice, globalization.currency, globalization.country, 2) }}
            </template>
            <template #editor="{ data }">
                <div class="p-editor-full" style="display: block;">
                    <pv-numeric v-model="data['dutyPrice']" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" :showButtons="true" :format="true" :allowEmpty="false" autofocus style="width: 100%;" onfocus="this.select()" :prefix="currencySymbol" />
                </div>
            </template>
        </pv-grid-column>
        <!-- Inline Edit Command -->
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) }]" :rowEditor="true" class="p-operator-save" headerStyle="max-width: 7rem; min-width:3rem" style="max-width: 7rem !important; min-width:3rem !important; width: 3rem; text-align: right; border-right: 0;" :frozen="true" />
        <!-- Operation Column -->
        <pv-grid-column :class="[{'p-field-hidden': (viewOnly) },{'p-operator-cancel' : 'true'}] " class="p-operator-cancel" :rowEditor="true" :headerStyle="`min-width: 3rem; max-width: 3rem; width: 3rem`" style="width: 3rem; border-left: 0; text-align: center; object-position: center; padding: 0 5px; " :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <pv-button :disabled="true" icon="pi pi-trash" iconPos="right" class="p-button-danger" @click="slotProps.data.id" />
            </template>
        </pv-grid-column>
        <!-- Table Header Template -->
        <!-- Empty Record Template -->
        <template #empty>
            No records found.
        </template>
        <!-- Loading Template -->
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <!-- Pagination -->
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSources" />
        </template>
    </pv-grid>
</template>
<script>
    import { FilterMatchMode } from 'primevue/api';
    export default {
        name: 'SalesOrderItemGrid',
        emits: ['is-loading', 'is-reset'],
        props: {
            salesOrder: {type:Object, default: null },
            parameters: { type: Object, default: () => { } },
            value: { type: Object },
            modelValue: { type: Object },
        },
        data() {
            return {
                editingRecords: [],
                records: { data: [], total: 0 },
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.orderNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.customer': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.address': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'platform.shippingMethod': { value: null, matchMode: FilterMatchMode.CONTAINS }
                },
                selected: null,
                sources: {
                    countries: []
                },
                page: {
                    current: 0,
                    first: 0,
                    size: 10
                },
                dialog: {
                    downloadAll: false,
                    downloadSelected: false,
                    identities: [],
                    parameters: {
                        performPlatformFulfillment: true,
                        allowPartialFulfillment: false,
                        closePartialFulfillment: false,
                        initiatePlatformFulfillmentNotification: false,
                        initiatePlatformTrackingUpdateNotification: false
                    }
                },
                activity: {
                    loading: true,
                    downloading: false
                },
                countries: null,
                selectedCountry: null,
                filteredCountries: null
            }
        },
        watch: {
            'parameters': {
                handler() {
                    this.$nextTick(() => {
                        this.source = {
                            total: 0,
                            data: []
                        };
                        this.$emit("is-reset");
                    });
                }, deep: true, immediate: true
            },
            'salesOrder.mask': {
                handler() {
                    this.$nextTick(() => {
                        this.getSources();
                    });
                }, deep: true, immediate: true
            },
            source: {
                handler(e) {
                    this.onSync(e);
                },
                deep: true
            },
            value() {
                this.source.data = this.value ?? [];
                this.source.length = this.source.data?.length ?? 0;
            },
            modelValue() {
                this.source.data = this.modelValue ?? [];
                this.source.length = this.source.data?.length ?? 0;
                this.$emit("update:modelValue", this.source.data);

            }
        },
        computed: {
            viewOnly() {return ((this.salesOrder?.status ?? 0) > 100)},
            globalization() { return { country: this.shipperCountry?.code ?? "GB", currency: this.currencyCode ?? "GBP" } },
            currencySymbol() {
                return this.$vm.$currencies.filter(x => x.id === this.salesOrder?.currencyID)[0]?.symbolText;
            },
            currencyCode() {
                return this.$vm.$currencies.filter(x => x.id === this.salesOrder?.currencyID)[0]?.code;
            },
        },
        methods: {
            onSync() {
                this.$emit("is-sync", this.source.data);
                this.$emit("update:modelValue", this.source.data);
            },
            onRefresh() {
                this.activity.loading = true;
                this.$nextTick(() => { this.activity.loading = false; });
                //this.source = { data: [], total: 0 };
                //this.getSources().then(() => {
                //    this.$nextTick(() => { this.activity.loading = false; });
                //});
            },
            onRowEdit() {
                setTimeout(() => {
                    const kbButtons = document.getElementsByTagName("button");
                    kbButtons.forEach(elem => {
                        elem.setAttribute('tabindex', '-1')
                    });
                }, 100);
            },
            onRowSave(e) {
                let index = e.index;
                let model = e.data;
                this.$store.dispatch("salesorderitem/updateRecord", model).then((response) => {
                    if (response.success) {
                        //this.$toast.add({ severity: 'success', summary: `Additonal Fees Updated`, detail: "The record was updated successfully!", life: 3000 });
                        this.source[index] = this.$filters.init(response.result);
                        this.records.data[index] = this.$filters.init(response.result);
                    }
                    else if ((response.errors ?? []).length > 0) {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: response.errors[0]?.message, life: 3000 });
                    }
                })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Update Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                    });
            },
            getSources() {
                if (this.salesOrder?.mask)
                    this.activity.loading = true;
                    this.$store.dispatch("store/getURL", { url: `/services/facility/salesorderitem/bysalesorder/${this.salesOrder.mask}`.replaceAll("//", "/") })
                        .then((response) => {
                            this.records.data = response?.result ?? [];
                            this.records.total = this.records.data.length;
                        })
                        .finally(() => {
                            this.$nextTick(() => { this.activity.loading = false; });
                        });
            }
           
        },
        created() {
            this.activity.loading = false;
        },
        mounted() {

            this.activity.loading = false;
            this.sources.countries = this.$vm.$countries;
        }
    }
</script>
<style>
    #download-form .p-dialog-content {
        overflow: hidden !important;
    }
</style>