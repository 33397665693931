<template>
    <div style="margin: -0.5rem -1.5rem -2.5rem -1.5rem;">
        <div style="min-height: 10rem; padding: 1rem;">
            <h3 style="margin-bottom: 0;">SUMMARY</h3>
            <p>Platform selection and credential validation.</p>
            <div class="p-grid p-d-flex">
                <div class="p-col-12 p-md-6 p-lg-4 p-as-stretch">
                    <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 100%; border: 1px solid var(--surface-d);">
                        <h4 style="margin-bottom: 2rem; padding: 0 0.5rem;">BASIC INFO</h4>
                        <form-lookup id="Customer" v-model="model.customerID" :source="accessScope.customers" :required="true" :disabled="true" disabledType="object" />
                        <basicinfo-form v-model="model.basicInfo" :disabled="true" />
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-4 p-as-stretch">
                    <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 100%; border: 1px solid var(--surface-d);">
                        <h4 style="margin-bottom: 2rem; padding: 0 0.5rem;">PLATFORM</h4>
                        <credential-form v-model="model.credentials"
                                         v-model:modelPlatform="platform"
                                         :disabled="true" />
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-4 p-as-stretch">
                    <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 100%; border: 1px solid var(--surface-d);">
                        <instruction-form v-model="model.shopInfo"
                                          v-model:modelPlatform="platform" />
                    </div>
                </div>
            </div>
            <div class="p-grid p-d-flex" style="margin-top: 1rem;">
                <div class="p-col-12 p-md-8 p-as-stretch">
                    <div>
                        <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 50%; border: 1px solid var(--surface-d); margin-bottom: 1rem;">
                            <defaults-form :customerID="model.customerID"
                                           v-model="model.defaults"
                                           v-model:modelAccessScope="accessScope"
                                           :disabled="true" />
                        </div>
                        <div class="p-grid p-d-flex" stylex="background: var(--surface-c); border-radius: 5px !important; margin-left: 0rem; margin-right: 0rem; padding: 1rem 0.5rem; height: 40%; border: 1px solid var(--surface-d);">
                            <div class="p-col-12 p-md-6" style="padding-bottom: 0;">
                                <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 100%; border: 1px solid var(--surface-d); margin-bottom: 1rem;">
                                    <printer-form v-if="model.defaults" v-model="model.defaults.warehouseID"
                                                  v-model:modelPrinter="model.printers"
                                                  v-model:modelAccessScope="accessScope"
                                                  :customerID="model.customerID"
                                                  :printers="printerSource"
                                                  :disabled="true"/>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6" style="padding-bottom: 0;">
                                <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 100%; border: 1px solid var(--surface-d); margin-bottom: 1rem;">
                                    <notification-form v-model="model.notifications"
                                                       :disabled="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-as-stretch">
                    <div style="background: var(--surface-c); border-radius: 5px !important; padding: 1rem 0.5rem; height: 100%; border: 1px solid var(--surface-d);">
                        <location-form v-model="model.locations"
                                       v-model:modelPlatform="platform"
                                       v-model:modelAccessScope="accessScope"
                                       :customerID="model.customerID"
                                       :disabled="true" />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid p-nogutter p-justify-between" style="border-top: 2px solid var(--surface-d);padding: 0.5rem 1rem; background: var(--surface-c)">
            <pv-button @click="onPrevPage()" :loading="activity.loading" :iconPos="'right'"><i class="fa fa-chevron-left fa-fw" style="margin-right: 0.5rem"></i> <i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-pen-field fa-fw')}`" style="margin-right: 0.5rem"></i> Defaults</pv-button>
            <pv-button class="p-button-success" @click="onCompletePage()" :loading="activity.loading" :iconPos="'right'" :disabled="false"><i :class="`${(activity.loading ? 'pi pi-spinner pi-spin' : 'fa fa-check-square fa-fw')}`" style="margin-right: 0.5rem"></i> Complete</pv-button>
        </div>
    </div>
</template>
<script>
    import credentialForm from '@/components/facility/marketplace/cart/credential-form.vue';
    import instructionForm from '@/components/facility/marketplace/cart/instruction-form.vue';
    import locationForm from '@/components/facility/marketplace/cart/location-form.vue';
    import basicInfo from '@/components/facility/marketplace/cart/basicinfo-form.vue';
    import defaultForm from '@/components/facility/marketplace/cart/defaults-form.vue';
    import notificationForm from '@/components/facility/marketplace/cart/notification-form.vue';
    import printerForm from '@/components/facility/marketplace/cart/printer-form.vue';
    export default {
        name: "StoreSummaryStep",
        emits: ["update", "update:modelValue", "isSync", "nextPage"],
        components: {
            'credential-form': credentialForm,
            'instruction-form': instructionForm,
            'location-form': locationForm,
            'basicinfo-form': basicInfo,
            'defaults-form': defaultForm,
            'notification-form': notificationForm,
            'printer-form': printerForm
        },
        props: {
            modelValue: { type: Object, default: null },
            modelPlatform: { type: Object, default: null },
            modelAccessScope: { type: Object, default: null },
            printerSource: { type: Array, default: null }
        },
        data() {
            return {
                platform: null,
                accessScope: null,
                model: {
                    id: 0,
                    customerID: 0,
                    basicInfo: {
                        code: null,
                        name: null,
                        endSolution: 200,
                        thirdPartySolution: 100,
                        status: 100,
                        verificationDate: null,
                    },
                    defaults: {
                        warehouseID: 0,
                        currencyID: 0,
                        content: null,
                        email: null,
                        telephone: null,
                        packagingType: 4,
                        incoterm: 16,
                        weightUnit: 100,
                        dimensionUnit: 100
                    },
                    printer: {
                        autoPrinterSetting: 100,
                        labelPrinterID: null,
                        documentPrinterID: null
                    },
                    credentials: {
                        platformID: 1,
                        credential_01: null,
                        credential_02: null,
                        credential_03: null,
                        credential_04: null,
                        credential_05: null,
                        credential_06: null,
                        credential_07: null,
                        credential_08: null,
                        credential_09: null,
                        credential_10: null,
                        credential_11: null,
                        credential_12: null,
                        credential_13: null,
                        credential_14: null,
                        credential_15: null,
                        tokenIssued: null,
                        tokenExpiry: null
                    },
                    orderAutomation: {
                        type: 100,
                        webhook: 200,
                        fulfillOrder: false,
                        partialFulfillment: false,
                        notifyUser: false,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    productAutomation: {
                        type: 100,
                        webhook: 200,
                        schedule: {
                            hourInterval: 1,
                            lastRunDate: null,
                            nextRunDate: null
                        }
                    },
                    notifications: {
                        failed: null,
                        success: null
                    },
                    shopInfo: {
                        code: null,
                        name: null,
                        address1: null,
                        address2: null,
                        address3: null,
                        city: null,
                        state: null,
                        stateCode: null,
                        postalCode: null,
                        country: null,
                        countryID: null,
                        contact: null,
                        telephone: null,
                        email: null,
                        shopDomain: null,
                        storeDomain: null,
                        timezone: null,
                        ianaTimezone: null,
                        supportedCurrencies: null,
                        defaults: {
                            primaryLocationID: null,
                            currency: null,
                            weightUnit: null
                        },
                        taxConfiguration: {
                            taxInclusive: false,
                            taxShipping: false
                        }
                    },
                    environmentID: null,
                    storeID: null,
                    storeKey: null,
                    bridgeKey: null,
                    recordStatus: 0
                },
                activity: {
                    loading: false
                }
            }
        },
        watch: {
            modelValue() { this.model = this.modelValue; },
            modelPlatform() { this.platform = this.modelPlatform; },
            modelAccessScope() { this.accessScope = this.modelAccessScope; },
        },
        methods: {
            onPrevPage() {
                this.$emit('prev-page', { formData: this.model, platform: this.platform, pageIndex: 3 });
            },
            onCompletePage() {
                this.$emit('complete');
            },
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            },
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
            if (this.modelPlatform)
                this.platform = this.modelPlatform;
            if (this.modelAccessScope)
                this.accessScope = this.modelAccessScope;
         }
    }
</script>