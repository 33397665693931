<template>
    <div :id="`area${id}`" :class="[{'is-component': true}, {'p-field': true}, {'p-col-12': true}, {'p-fluid': autoFit}, {'p-input-filled': filled}]">
        <div :class="[{'p-float-label': floatLabel}, {'p-fixed-label': !floatLabel}]">
            <div v-if="!hideLabel" style="overflow: hidden; max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" >
                <label v-if="!hideLabel && !floatLabel" :for="id" :class="[{'p-label-text': true}, {'conditional': conditional}, {'required': required}]"><slot name="label">{{ label ?? $filters.titleize(id) }}</slot></label>
            </div>
            <div class="p-inputgroup">
                <slot name="leftSide" :data="(model.invalid && activity.submitted)"></slot>
                <div class="p-object-group" style="display: inline-flex; width: 100% !important; ">
                    <slot name="default" :data="(model.invalid && activity.submitted)"></slot>
                    <label v-if="!hideLabel && floatLabel" :for="id" :class="[{'p-label-text': true}, {'conditional': conditional}, {'required': required}]"><slot name="label">{{ label ?? $filters.titleize(id) }}</slot></label>
                </div>
                <slot name="rightSide" :data="(model.invalid && activity.submitted)"></slot>
            </div>
        </div>
        <small class="p-error"><span v-html="`${model.error ?? ''}`"></span></small>
    </div>
</template>
<script>
    export default {
        name: 'FormObjectTemplate',
        props: {
            id: { type: String, required: true, validator: (value) => /^[a-zA-Z0-9_]*$/.test(value) },
            // Display Type
            autoFit: { type: Boolean, default: true },
            filled: { type: Boolean, default: false },
            // Label Related
            label: { type: String },
            hideLabel: { type: Boolean, default: false },
            floatLabel: { type: Boolean, default: true },
            // Validation
            validator: { type: Object },
            required: { type: Boolean, default: false },
            conditional: { type: Boolean, default: false },
        },
        data() {
            return {
                activity: {
                    submitted: false
                },
                model: {
                    invalid: false,
                    error: null
                }
            }
        },
        watch: {
            validator() {
                this.model.error = null;
                this.setInvalid(false);
                if (this.$vm.$submitted) {
                    try {
                        let model = this.validator.model[this.id];
                        if (model) {
                            var entity = {};
                            Object.keys(model).forEach((key) => { entity[key.replace("$", "")] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key]; });
                            if (entity.silentErrors && entity.silentErrors.length > 0)
                                this.model.error = entity.silentErrors[0].$message.replace('Value', this.label ?? this.$filters.titleize(this.id));
                            this.setInvalid(entity.invalid || entity.error);
                        }
                    }
                    catch {
                        // No Operation
                    }
                }
            }
        },
        methods: {
            setInvalid(value) {
                this.model.invalid = value;
            }
        },
        updated() {
            this.activity.submitted = this.$vm.$submitted;
        }
    }
</script>
<style>
    small.p-error ul {
        list-style: none;
        margin: 0;
        padding: 0.25rem !important;
    }
</style>