import { createWebHistory, createRouter } from "vue-router";
import generic from '@/router/default.js';
const routes = [
    /* Standard Routes */
    { path: '/:pathMatch(.*)', name: 'not-found', component: generic.notfound },
    { path: "/", name: "Home", redirect: () => "/dashboard" },
    { path: '/dashboard', name: 'dashboard-index', component: generic.Dashboard, meta: { title: 'Dashboard', requireAuth: true, icon: 'p-breadcrumb-home' } },
    { path: '/components', name: 'components-index', component: generic.ObjectTester, meta: { title: 'Dashboard', requireAuth: true, icon: 'p-breadcrumb-home' } },
    { path: "/account/login", name: "Login", component: generic.Login, meta: { title: 'Login' } },
    { path: "/activate/:key", name: "account-activate", component: generic.Activate, meta: { title: 'Activate' } },

    /********** SETTINGS **********/
    // Service Providers
    { path: "/settings/providers", name: "provider-index", component: generic.ServiceProviderGrid, meta: { title: 'Service Providers', subtitle: 'List of service providers integration.', type: null, name: 'provider', module: 'provider' } },
    { path: "/settings/provider/:mask", name: "provider-edit", component: generic.ServiceProviderRecord, meta: { title: 'Service Provider', subtitle: '', type: null, name: 'provider', module: 'provider' } },
    { path: "/settings/integrations", name: "integration-index", component: generic.IntegrationGrid, meta: { title: 'Integration', subtitle: '', type: null, name: 'integration', module: 'integration' } },
    { path: "/settings/integration/create", name: "integration-add", component: generic.IntegrationRecord, meta: { title: 'Integration', subtitle: 'Create New Integration', type: null, name: 'integration', module: 'integration' } },
    { path: "/settings/integration/:mask", name: "integration-edit", component: generic.IntegrationRecord, meta: { title: 'Integration', module: 'integration' } },
    // Tenants
    { path: "/settings/tenants", name: "tenant-index", component: generic.TenantGrid, meta: { title: 'Tenants', subtitle: 'List of domains that hosts the COSMOS system.', module: 'tenant' } },
    { path: "/settings/tenant/create", name: "tenant-create", component: generic.TenantRecord, meta: { title: 'Add New Tenant', module: 'tenant' } },
    { path: "/settings/tenant/:mask", name: "tenant-edit", component: generic.TenantRecord, meta: { title: 'Tenants', module: 'tenant' } },
    // Environments
    { path: "/settings/environments", name: "contract-index", component: generic.CompanyGrid, meta: { title: 'Environments', subtitle: 'List of client environments.', type: 'environment', typeId : 2, module: 'company' } },
    { path: "/settings/environment/create", name: "contract-add", component: generic.CompanyRecord, meta: { title: 'Environments', subtitle: 'Create new client environment.', type: 'environment', module: 'company' } },
    { path: "/settings/environment/:mask", name: "contract-edit", component: generic.CompanyRecord, meta: { title: 'Environments', type: 'environment', module: 'company' } },
    // Customisation
    { path: "/settings/message/errors", name: "customerror-index", component: generic.CustomMessageGrid, meta: { title: 'Error Messages', subtitle: 'List of custom error messages.', type: 'customerror', typeId: 1, module: 'custommessage' } },
    { path: "/settings/message/error/create", name: "customerror-add", component: generic.CustomMessageRecord, meta: { title: 'Errors', subtitle: 'Create new custom error message.', type: 'customerror', module: 'custommessage' } },
    { path: "/settings/message/error/:mask", name: "customerror-edit", component: generic.CustomMessageRecord, meta: { title: 'Errors', type: 'customerror', module: 'custommessage' } },
    { path: "/settings/message/intransit", name: "customintransit-index", component: generic.CustomMessageGrid, meta: { title: 'In Transit Messages', subtitle: 'List of in transit status message update.', type: 'customintransit', typeId: 8, module: 'custommessage' } },
    { path: "/settings/message/intransit/create", name: "customintransit-add", component: generic.CustomMessageRecord, meta: { title: 'In Transit', subtitle: 'Create new custom in transit status message update.', type: 'customintransit', module: 'custommessage' } },
    { path: "/settings/message/intransit/:mask", name: "customintransit-edit", component: generic.CustomMessageRecord, meta: { title: 'In Transit', type: 'customintransit', module: 'custommessage' } },
    { path: "/settings/message/outfordelivery", name: "customoutfordelivery-index", component: generic.CustomMessageGrid, meta: { title: 'Out for Delivery Messages', subtitle: 'List of out for delivery status message update.', type: 'customoutfordelivery', typeId: 4, module: 'custommessage' } },
    { path: "/settings/message/outfordelivery/create", name: "customoutfordelivery-add", component: generic.CustomMessageRecord, meta: { title: 'Out for Delivery', subtitle: 'Create new custom out for delivery status message update.', type: 'customoutfordelivery', module: 'custommessage' } },
    { path: "/settings/message/outfordelivery/:mask", name: "customoutfordelivery-edit", component: generic.CustomMessageRecord, meta: { title: 'Out for Delivery', type: 'customoutfordelivery', module: 'custommessage' } },
    { path: "/settings/message/delivered", name: "customdelivered-index", component: generic.CustomMessageGrid, meta: { title: 'Delivered Messages', subtitle: 'List of Delivered  status update.', type: 'customdelivered', typeId: 2, module: 'custommessage' } },
    { path: "/settings/message/delivered/create", name: "customdelivered-add", component: generic.CustomMessageRecord, meta: { title: 'Delivered', subtitle: 'Create new custom Delivered status message update.', type: 'customdelivered', module: 'custommessage' } },
    { path: "/settings/message/delivered/:mask", name: "customdelivered-edit", component: generic.CustomMessageRecord, meta: { title: 'Delivered', type: 'customdelivered', module: 'custommessage' } },

    /*** Companies ***/
    { path: "/company/:mask", name: "company-edit", component: generic.CompanyRecord, meta: { title: 'Company', type: "company", module: 'company' } },
    // Environments
    { path: "/company/environments", name: "environment-index", component: generic.CompanyGrid, meta: { title: 'Environments', subtitle: 'List of client environments.', type: "environment", typeId: 2, module: 'company' } },
    { path: "/company/environment/create", name: "environment-add", component: generic.CompanyRecord, meta: { title: 'Add New Environment', subtitle: 'Create new client environment.', type: "environment", module: 'company' } },
    { path: "/company/environment/:mask", name: "environment-edit", component: generic.CompanyRecord, meta: { title: 'Environments', type: "environment", module: 'company' } },
    // Forwarders
    { path: "/company/forwarders", name: "forwarder-index", component: generic.CompanyGrid, meta: { title: 'Forwarders', subtitle: 'List of warehouses, distribution centers, and posting locations of the shipper.', type: "forwarder", typeId: 4, module: 'company' } },
    { path: "/company/forwarder/create", name: "forwarder-add", component: generic.CompanyRecord, meta: { title: 'Add New Forwarder', subtitle: 'Create new warehouse, distribution center or posting location.', type: "forwarder", module: 'company' } },
    { path: "/company/forwarder/:mask", name: "forwarder-edit", component: generic.CompanyRecord, meta: { title: 'Forwarder', type: "forwarder", module: 'company' } },
    // Warehouses
    { path: "/company/warehouses", name: "warehouse-index", component: generic.CompanyGrid, meta: { title: 'Warehouses', subtitle: 'List of warehouses, distribution centers, and posting locations of the shipper.', type: "warehouse", typeId: 8, module: 'company' } },
    { path: "/company/warehouse/create", name: "warehouse-add", component: generic.CompanyRecord, meta: { title: 'Add New Warehouse', subtitle: 'Create new warehouse, distribution center or posting location.', type: "warehouse", module: 'company' } },
    { path: "/company/warehouse/:mask", name: "warehouse-edit", component: generic.CompanyRecord, meta: { title: 'Warehouses', type: "warehouse", module: 'company' } },
    // Customers
    { path: "/company/customers", name: "customer-index", component: generic.CompanyGrid, meta: { title: 'Customers', subtitle: 'List of customer companies handled within the environment.', type: "customer", typeId: 16, module: 'company' } },
    { path: "/company/customer/create", name: "customer-add", component: generic.CompanyRecord, meta: { title: 'Add New Customer', subtitle: 'Create new customer company.', type: "customer", module: 'company' } },
    { path: "/company/customer/:mask", name: "customer-edit", component: generic.CompanyRecord, meta: { title: 'Customer', type: "customer", module: 'company' } },
    // Retailers
    { path: "/company/retailers", name: "retailer-index", component: generic.CompanyGrid, meta: { title: 'Retailers', subtitle: 'List of customer retailers.', type: "retailer", typeId: 32, module: 'company' } },
    { path: "/company/retailer/create", name: "retailer-add", component: generic.CompanyRecord, meta: { title: 'Add New Retailer', subtitle: 'Create retailer for a customer.', type: "retailer", module: 'company' } },
    { path: "/company/retailer/:mask", name: "retailer-edit", component: generic.CompanyRecord, meta: { title: 'Retailer', type: "retailer", module: 'company' } },
    // Departments
    { path: "/company/departments", name: "department-index", component: generic.CompanyGrid, meta: { title: 'Departments', subtitle: 'List of departments within a company.', type: "department", typeId: 64, module: 'company' } },
    { path: "/company/department/create", name: "department-add", component: generic.CompanyRecord, meta: { title: 'Add New Department', subtitle: 'Create a new department within a company.', type: "department", module: 'company' } },
    { path: "/company/department/:mask", name: "department-edit", component: generic.CompanyRecord, meta: { title: 'Department', type: "department" } },
    // Teams
    { path: "/company/teams", name: "team-index", component: generic.CompanyGrid, meta: { title: 'Teams', subtitle: 'List of teams within a department.', type: "team", typeId: 128, module: 'company' } },
    { path: "/company/team/create", name: "team-add", component: generic.CompanyRecord, meta: { title: 'Add New Team', subtitle: 'Create a new department team.', type: "team", module: 'company' } },
    { path: "/company/team/:mask", name: "team-edit", component: generic.CompanyRecord, meta: { title: 'Team', type: "team", module: 'company' } },

    /*** Users ***/
    // Global Administrators
    { path: "/users/globaladmins", name: "globaladmins-index", component: generic.UserGrid, meta: { title: 'Global Administrators', subtitle: 'List of users that were granted full access to the COSMOS application.', type: "globaladmins", typeId:0, module: 'user' } },
    { path: "/users/globaladmin/create", name: "globaladmins-add", component: generic.UserRecord, meta: { title: 'Add Global Admin Users', type: "globaladmins", module: 'user' } },
    { path: "/users/globaladmin/:mask", name: "globaladmins-edit", component: generic.UserRecord, meta: { title: 'Update Global Admin User', type: "globaladmins", module: 'user' } },
    // System Administrator
    { path: "/users/sysadmins", name: "sysadmins-index", component: generic.UserGrid, meta: { title: 'System Administrators', subtitle: 'List of specially assigned users to have full access to their environment.', type: "sysadmins", typeId: 2, module: 'user'  } },
    { path: "/users/sysadmin/create", name: "sysadmins-add", component: generic.UserRecord, meta: { title: 'Add System Administrator', type: "sysadmins", module: 'user' } },
    { path: "/users/sysadmin/:mask", name: "sysadmins-edit", component: generic.UserRecord, meta: { title: 'Update System Administrator', type: "sysadmins", module: 'user' } },
    // Local Administrator
    { path: "/users/admins", name: "admins-index", component: generic.UserGrid, meta: { title: 'Administrators', subtitle: 'List of internal administrator users within the environment.', type: "admins", typeId: 3, module: 'user' } },
    { path: "/users/admin/create", name: "admins-add", component: generic.UserRecord, meta: { title: 'Add Administrator', type: "admins", module: 'user' } },
    { path: "/users/admin/:mask", name: "admins-edit", component: generic.UserRecord, meta: { title: 'Update Administrator', type: "admins", module: 'user' } },
    // Standard Users
    { path: "/users/agents", name: "agents-index", component: generic.UserGrid, meta: { title: 'Users', subtitle: 'List of standard users within the environment.', type: "agents", typeId: 4, module: 'user' } },
    { path: "/users/agent/create", name: "agents-add", component: generic.UserRecord, meta: { title: 'Add User', type: "agents", module: 'user' } },
    { path: "/users/agent/:mask", name: "agents-edit", component: generic.UserRecord, meta: { title: 'Update User', type: "agents", module: 'user' } },
    // Customer Administrator
    { path: "/users/custadmins", name: "custadmins-index", component: generic.UserGrid, meta: { title: 'Customer Administrators', subtitle: 'List of customer users to have access to their records.', type: "custadmins", typeId: 5, module: 'user' } },
    { path: "/users/custadmins/create", name: "custadmins-add", component: generic.UserRecord, meta: { title: 'Add Customer Administrator', type: "custadmins", module: 'user' } },
    { path: "/users/custadmins/:mask", name: "custadmins-edit", component: generic.UserRecord, meta: { title: 'Update Customer Administrator', type: "custadmins", module: 'user' } },
    // Customer Users
    { path: "/users/customers", name: "customers-index", component: generic.UserGrid, meta: { title: 'Customers', subtitle: 'List of customer users to have access to their records.', type: "customers", typeId: 5, module: 'user' } },
    { path: "/users/customer/create", name: "customers-add", component: generic.UserRecord, meta: { title: 'Add Customer User', type: "customers", module: 'user' } },
    { path: "/users/customer/:mask", name: "customers-edit", component: generic.UserRecord, meta: { title: 'Update Customer User', type: "customers", module: 'user' } },
    // Retailer Users
    { path: "/users/retailers", name: "retailers-index", component: generic.UserGrid, meta: { title: 'Retailers', subtitle: 'List of retailer users within the customer.', type: "retailers", typeId: 7, module: 'user' } },
    { path: "/users/retailer/create", name: "retailers-add", component: generic.UserRecord, meta: { title: 'Add Retailer User', type: "retailers", module: 'user' } },
    { path: "/users/retailer/:mask", name: "retailers-edit", component: generic.UserRecord, meta: { title: 'Update Retailer User', type: "retailers", module: 'user' } },
    // API Users
    { path: "/users/apis", name: "apis-index", component: generic.UserGrid, meta: { title: 'API Users', subtitle: 'List of users that are used by integrator to communicate with COSMOS.', type: "apis", typeId: 1, module: 'user' } },
    { path: "/users/api/create", name: "apis-add", component: generic.UserRecord, meta: { title: 'Add API User', type: "apis", module: 'user' } },
    { path: "/users/api/:mask", name: "apis-edit", component: generic.UserRecord, meta: { title: 'Update API User', type: "apis", module: 'user' } },
    { path: "/profile/:mask", name: "profile-edit", component: generic.UserProfile, meta: { title: 'Profile', module: 'user' } },
    // Customer Users
    { path: "/users/deleted", name: "deleteduser-index", component: generic.DeletedUserGrid, meta: { title: 'Deleted Users', subtitle: 'List of deleted users.',  module: 'user' } },
    { path: "/users/deleted/:mask", name: "deleteduser-edit", component: generic.UserRecord, meta: { title: 'Deleted User', module: 'user' } },



    /********** TMS **********/
    // Consignment
    { path: '/public/consignment/track/:mask', name: 'public-consignment-track', component: generic.ConsignmentTrack, meta: { title: 'Track', requireAuth: false, icon: 'p-breadcrumb-home' } },
    { path: '/consignment/track/:mask', name: 'base-consignment-track', component: generic.ConsignmentTrack, meta: { title: 'Track', requireAuth: false, icon: 'p-breadcrumb-home' } },
    { path: '/track/:mask', name: 'consignment-track', component: generic.ConsignmentTrack, meta: { title: 'Track', requireAuth: false, icon: 'p-breadcrumb-home' } },
    { path: "/transaction/consignments", name: "consignment-index", component: generic.ConsignmentGridV2, meta: { title: 'Consignments', subtitle: 'List of consignment within environment.', module: 'consignment' } },
    { path: "/transaction/consignment/:code/code", name: "consignment-code-edit", component: generic.ConsignmentCodeEdit, meta: { title: 'Consignment', module: 'consignment' } },
    { path: "/transaction/consignment/:mask", name: "consignment-edit", component: generic.ConsignmentEdit, meta: { title: 'Consignment', module: 'consignment' } },
    {
        path: "/transaction/consignment/create", name: "consignment-add", components: { default: generic.ConsignmentRecordV2, stepper: generic.ConsignmentAddressStep1 }, meta: { title: 'Add Consignnment', subtitle: 'Create new consignment.', module: 'consignment' },
        children: [
            { path: "", name: "consignment-details", components: { stepper: generic.ConsignmentAddressStep1 }, meta: { title: 'Consignment', module: 'consignment' } },
            //{ path: "/transaction/consignment/step/recipient", name: "consignment-recipient", components: { stepper: generic.ConsignmentRecipientStep2 }, meta: { title: 'Consignment', module: 'consignment' } },
            { path: "/transaction/consignment/step/international", name: "consignment-international", components: { stepper: generic.ConsignmentInternationalStep4 }, meta: { title: 'Consignment', module: 'consignment' } },
            { path: "/transaction/consignment/step/package", name: "consignment-package", components: { stepper: generic.ConsignmentPackageStep3 }, meta: { title: 'Consignment', module: 'consignment' } },
            { path: "/transaction/consignment/step/allocation", name: "consignment-allocation", components: { stepper: generic.ConsignmentAllocationStep5 }, meta: { title: 'Consignment', module: 'consignment' } },
            { path: "/transaction/consignment/step/confirmation", name: "consignment-confirmation", components: { stepper: generic.ConsignmentSummaryStep6 }, meta: { title: 'Consignment', module: 'consignment' } }
        ]
    },
    // Collections
    { path: "/transaction/collections", name: "collection-index", component: generic.CollectionGridV2, meta: { title: 'Collections', subtitle: 'List of collections within environment.', module: 'collection' } },
    { path: "/transaction/collections/:mask", name: "collection-edit", component: generic.CollectionRecord, meta: { title: 'Collection', module: 'collection' } },
    { path: "/transaction/return", name: "returns-index", redirect: () => "/dashboard" },
    { path: "/transaction/return/:mask", name: "returns-edit", component: generic.ReturnRecord, meta: { title: 'Return', module: 'returns' } },
    { path: "/transaction/shipments", name: "shipment-index", component: generic.ConsignmentGridREI, meta: { title: 'Consignments', subtitle: 'List of consignment within environment.', module: 'consignment' } },


    /********** REPORTS **********/
    // Static Reports
    { path: "/report/consignment/old", name: "report-consignment-report-old", component: generic.ReportConsignmentReport, meta: { title: 'Consignment Report', module: 'consignment' } },
    { path: "/report/consignment", name: "report-consignment-report", component: generic.ReportConsignmentReport_V2, meta: { title: 'Consignment Report', module: 'consignment' } },
    { path: "/report/consignment/summary", name: "report-consignment-summary", component: generic.ReportConsignmentSummary, meta: { title: 'Consignment Summary', module: 'consignment' } },
    { path: "/report/returns", name: "report-returns-report", component: generic.ReportReturnsReport, meta: { title: 'Returns Report', module: 'returns' } },
    { path: "/report/kpi/bypackage", name: "report-kpi-bypackage", component: generic.ReportKPIByPackage, meta: { title: 'Total Package', module: 'consignment' } },
    { path: "/report/kpi/bypackagecountry", name: "report-kpi-bypackagecountry", component: generic.ReportKPIByPackageCountry, meta: { title: 'Total Package By Each Country', module: 'consignment' } },



    /********** FACILITY **********/

    /** Rate Facility **/
    // Rate Facility -> Rates
    { path: "/facility/rates/zones", name: "providerzone-index", component: generic.ProviderZoneGrid, meta: { title: 'Shipping Zones', subtitle: 'List of address books within environment.', module: 'providerzone' } },
    { path: "/facility/rates/zone/:mask", name: "providerzone-edit", component: generic.ProviderZoneRecord, meta: { title: 'Shipping Zone', subtitle: 'List of address books within environment.', module: 'providerzone' } },
    {
        path: "/facility/rates/zone/create", name: "providerzone-add", components: { default: generic.ProviderZoneCreate, stepper: generic.ProviderZoneSTEP1 }, meta: { title: 'Add Shipping Zones', subtitle: 'Create new shipping zone.', module: 'providerzone' },
        children: [
            { path: "", name: "providerzone-details", components: { stepper: generic.ProviderZoneSTEP1 }, meta: { module: 'providerzone' } },
            { path: "/facility/rates/zone/step/names", name: "providerzone-names", components: { stepper: generic.ProviderZoneSTEP2 }, meta: { module: 'providerzone' } },
            { path: "/facility/rates/zone/step/groups", name: "providerzone-groups", components: { stepper: generic.ProviderZoneSTEP3 }, meta: { module: 'providerzone' } },
            { path: "/facility/rates/zone/step/matrix", name: "providerzone-matrix", components: { stepper: generic.ProviderZoneSTEP4 }, meta: { module: 'providerzone' } },
            { path: "/facility/rates/zone/step/confirmation", name: "providerzone-confirmation", components: { stepper: generic.ProviderZoneSTEP5 }, meta: { module: 'providerzone' } },
        ]
    },
    { path: "/facility/rates/timeintransit", name: "providertimeintransit-index", component: generic.TimeInTransitGrid, meta: { title: 'Shipping Time-In-Transit', subtitle: 'List of address books within environment.', module: 'providertimeintransit' } },
    { path: "/facility/rates/cutoff", name: "providercutoff-index", component: generic.TimeInTransitGrid, meta: { title: 'Shipping Time-In-Transit', subtitle: 'List of address books within environment.', module: 'providerzone' } },
    { path: "/facility/rates/surchargelist", name: "providersurcharge-index", component: generic.SurchargeGrid, meta: { title: 'Carrier Surcharges', subtitle: 'List of address books within environment.', module: 'providersurcharge' } },
    { path: "/facility/rates/forex", name: "exchangerate-index", component: generic.ExchangeRateGrid, meta: { title: 'Foreign Exchange Rate', subtitle: 'List of address books within environment.', module: 'exchangerate' } },
    { path: "/facility/rates/provider", name: "providercontract-index", component: generic.ContractRateGrid, meta: { title: 'Contract Rates', subtitle: 'List of address books within environment.', module: 'providercontract' } },
    { path: "/facility/rates/markup", name: "markupcontract-index", component: generic.MarkupRateGrid, meta: { title: 'Customer Markups', subtitle: 'List of address books within environment.', module: 'markupcontract' } },
    { path: "/facility/rates/surcharges", name: "providersurchargerate-index", component: generic.SurchargeRateGrid, meta: { title: 'Carrier Surcharges', subtitle: 'List of address books within environment.', module: 'providersurchargerate' } },
    { path: "/facility/rates/quote", name: "quote-terminal", component: generic.QuoteTerminal, meta: { title: 'Carrier Surcharges', subtitle: 'List of address books within environment.', module: 'quote' } },

    /** Rule Engine **/
    // Rule Engine --> Service Groups
    { path: "/facility/rule/servicegroups", name: "servicegroup-index", component: generic.ServiceGroupGrid, meta: { title: 'Service Groups', subtitle: 'List of service group within environment.', module: 'servicegroup' } },
    { path: "/facility/rule/servicegroup/:mask", name: "servicegroup-edit", component: generic.ServiceGroupEdit, meta: { title: 'Service Group', module: 'servicegroup' } },
    {
        path: "/facility/rule/servicegroup/create", name: "servicegroup-add", components: { default: generic.ServiceGroupRecord, stepper: generic.ServiceGroupStep1 }, meta: { title: 'Add Service Group', subtitle: 'Create new service group.', module: 'servicegroup' },
        children: [
            { path: "", name: "servicegroup-details", components: { stepper: generic.ServiceGroupStep1 }, meta: { title: 'Service Group', module: 'servicegroup' } },
            { path: "/facility/rule/servicegroup/step/service", name: "servicegroup-service", components: { stepper: generic.ServiceGroupStep2 }, meta: { title: 'Service Group', module: 'servicegroup' } },
            { path: "/facility/rule/servicegroup/step/rule", name: "servicegroup-rule", components: { stepper: generic.ServiceGroupStep3 }, meta: { title: 'Service Group', module: 'servicegroup' } },
            { path: "/facility/rule/servicegroup/step/confirmation", name: "servicegroup-confirmation", components: { stepper: generic.ServiceGroupStep4 }, meta: { title: 'Service Group', module: 'servicegroup' } },

        ]
    },
    // Rule Engine --> Trade Blocs
    { path: "/facility/rule/tradeblocs", name: "ruletradebloc-index", component: generic.RuleEngineTradeBlocGrid, meta: { title: 'Tradeblocs', subtitle: 'List of tradeblocs within environment.', module: 'ruletradebloc' } },
    { path: "/facility/rule/tradebloc/create", name: "ruletradebloc-add", component: generic.RuleEngineTradeBlocRecord, meta: { title: 'Add Tradebloc', subtitle: 'Create new tradebloc.', module: 'ruletradebloc' } },
    { path: "/facility/rule/tradebloc/:mask", name: "ruletradebloc-edit", component: generic.RuleEngineTradeBlocRecord, meta: { title: 'Tradebloc', module: 'ruletradebloc' } },
    // Rule Engine --> Postal Blocs
    { path: "/facility/rule/postalblocs", name: "rulepostalbloc-index", component: generic.RuleEnginePostalBlocGrid, meta: { title: 'Postalblocs', subtitle: 'List of postalbloc within environment.', module: 'rulepostalbloc' } },
    { path: "/facility/rule/postalbloc/create", name: "rulepostalbloc-add", component: generic.RuleEnginePostalBlocRecord, meta: { title: 'Add Postalbloc', subtitle: 'Create new postal.', module: 'rulepostalbloc' } },
    { path: "/facility/rule/postalbloc/:mask", name: "rulepostalbloc-edit", component: generic.RuleEnginePostalBlocRecord, meta: { title: 'Postalbloc', module: 'rulepostalbloc' } },
    // Rule Engine --> Holidays
    { path: "/facility/rule/holidays", name: "ruleholiday-index", component: generic.RuleEngineHolidayGrid, meta: { title: 'Holidays', subtitle: 'List of holidays within environment.', module: 'ruleholiday' } },
    { path: "/facility/rule/holiday/create", name: "ruleholiday-add", component: generic.RuleEngineHolidayRecord, meta: { title: 'Add Holiday', subtitle: 'Create new holiday.', module: 'ruleholiday' } },
    { path: "/facility/rule/holiday/:mask", name: "ruleholiday-edit", component: generic.RuleEngineHolidayRecord, meta: { title: 'Holiday', module: 'ruleholiday' } },
    // Rule Engine --> Simulate
    { path: "/facility/rule/simulate", name: "rule-simulate", component: generic.ServiceGroupSimulate, meta: { title: 'Simulate Rule', module: 'rule' } },
    // Invoice Engine 
    { path: "/facility/invoices", name: "invoice-index", component: generic.InvoiceGrid, meta: { title: 'Invoices', subtitle: 'List of available invoice', module: 'invoice' } },
    { path: "/facility/invoices/create", name: "invoice-add", component: generic.InvoiceRecord2, meta: { title: 'Consignment List', subtitle: 'Create Invoice', module: 'invoice' } },
    { path: "/facility/invoice/:mask", name: "invoice-edit", component: generic.InvoiceView, meta: { title: 'Invoice', module: 'invoice' } },
    { path: "/facility/invoice/reconcile", name: "invoice-reconcile", component: generic.InvoiceReconcileGrid, meta: { title: 'Invoice Reconcile', subtitle: 'Invoice Reconcile', module: 'invoicereconcile' } },
    // Dynamic Report
    { path: "/facility/reports", name: "dynamicreport-index", component: generic.DynamicReportGrid, meta: { title: 'Dynamic Report', subtitle: 'List of dynamic report templates.', module: 'dynamicreport' } },
    { path: "/facility/report/:mask", name: "dynamicreport-edit", component: generic.DynamicReportRecord, meta: { title: 'Dynamic Reports', subtitle: 'List of address books within environment.', module: 'dynamicreport' } },
    {
        path: "/facility/report/create", name: "dynamicreport-add", components: { default: generic.DynamicReportCreate, stepper: generic.DynamicReportSTEP1 }, meta: { title: 'Add Dynamic Report', subtitle: 'Create new dynamic report template.', module: 'dynamicreport' },
        children: [
            { path: "", name: "dynamicreport-fields", components: { stepper: generic.DynamicReportSTEP1 }, meta: { module: 'dynamicreport' } },
            { path: "/facility/report/step/customization", name: "dynamicreport-customization", components: { stepper: generic.DynamicReportSTEP2 }, meta: { module: 'dynamicreport' } },
            { path: "/facility/report/step/configuration", name: "dynamicreport-configuration", components: { stepper: generic.DynamicReportSTEP3 }, meta: { module: 'dynamicreport' } },
            { path: "/facility/report/step/confirmation", name: "dynamicreport-confirmation", components: { stepper: generic.DynamicReportSTEP4 }, meta: { module: 'dynamicreport' } }
        ]
    },
    // E-Commerce Platforms
    //{ path: "/facility/stores", name: "store-index", component: generic.StoreGrid, meta: { title: 'E-Commerce Stores', subtitle: 'List of e-commerce stores.', module: 'store' } },
    //{ path: "/facility/store/shoporders", name: "storeorder-index", component: generic.StoreOrder, meta: { title: 'Download Orders', subtitle: 'List of e-commerce orders.', module: 'store' } },
    //{ path: "/facility/store/shopproducts", name: "storeproduct-index", component: generic.StoreProduct, meta: { title: 'Download Products', subtitle: 'List of e-commerce products.', module: 'store' } },
    //{ path: "/facility/store/salesorders", name: "salesorder-index", component: generic.SalesOrderGrid, meta: { title: 'E-Commerce Products', subtitle: 'List of downloaded e-commerce orders.', module: 'store' } },
    //{ path: "/facility/store/salesorder/:mask", name: "salesorder-edit", component: generic.SalesOrderRecord, meta: { title: 'E-Commerce Products', subtitle: 'List of downloaded e-commerce orders.', module: 'store' } },
    //{ path: "/facility/store/:mask", name: "store-edit", component: generic.StoreRecord, meta: { title: 'E-Commerce Store', module: 'store' } },
    //{
    //    path: "/facility/store/create", name: "store-add", components: { default: generic.StoreCreate, stepper: generic.DynamicReportSTEP1 }, meta: { title: 'Register New Store', subtitle: 'Link new e-commerce store.', module: 'store' },
    //    children: [
    //        { path: "", name: "store-platform", components: { stepper: generic.StoreSTEP1 }, meta: { module: 'store' } },
    //        { path: "/facility/store/step/shopinfo", name: "store-shopinfo", components: { stepper: generic.StoreSTEP2 }, meta: { module: 'store' } },
    //        { path: "/facility/store/step/basicinfo", name: "store-basicinfo", components: { stepper: generic.StoreSTEP3 }, meta: { module: 'store' } },
    //        { path: "/facility/store/step/summary", name: "store-confirmation", components: { stepper: generic.StoreSTEP4 }, meta: { module: 'store' } },
    //    ]
    //},


    // Marketplace Carts
    { path: "/facility/carts", name: "cart-index", component: generic.CartGrid, meta: { title: 'Shopping Carts', subtitle: 'List of shopping carts.', module: 'cart' } },
    { path: "/facility/cart/download/order", name: "cart-orderdownload", component: generic.CartOrder, meta: { title: 'Download Orders', subtitle: 'Manually download orders from the shopping carts.', module: 'cart' } },
    { path: "/facility/cart/salesorders", name: "salesorder-index", component: generic.CartSalesOrderGrid, meta: { title: 'Sales Orders', subtitle: 'List of downloaded orders from the shopping carts', module: 'salesorder' } },
    { path: "/facility/cart/salesorder/:mask", name: "salesoder-edit", component: generic.CartSalesOrderRecord, meta: { title: 'Sales Order', subtitle: 'List of downloaded e-commerce orders.', module: 'salesorder' } },
    { path: "/facility/cart/:mask", name: "cart-edit", component: generic.CartRecord, meta: { title: 'Shopping Cart', module: 'cart' } },
    {
        path: "/facility/cart/create", name: "cart-add", components: { default: generic.CartCreate, stepper: generic.CartSTEP1 }, meta: { title: 'Register New Cart', subtitle: 'Link a new shopping cart.', module: 'cart' },
        children: [
            { path: "", name: "cart-platform", components: { stepper: generic.CartSTEP1 }, meta: { module: 'cart' } },
            { path: "/facility/cart/step/shopinfo", name: "cart-shopinfo", components: { stepper: generic.CartSTEP2 }, meta: { module: 'cart' } },
            { path: "/facility/cart/step/basicinfo", name: "cart-basicinfo", components: { stepper: generic.CartSTEP3 }, meta: { module: 'cart' } },
            { path: "/facility/cart/step/summary", name: "cart-confirmation", components: { stepper: generic.CartSTEP4 }, meta: { module: 'cart' } },
        ]
    },


    /** Import Engine **/
    // Import Engine --> Profile
    { path: "/facility/import/profiles", name: "importprofile-index", component: generic.ImportProfileGrid, meta: { title: 'Import Profiles', subtitle: 'List of available customer specific import profiles..', module: 'importprofile' } },
    { path: "/facility/import/profile/create", name: "importprofile-add", component: generic.ImportProfileRecord, meta: { title: 'Add Import Profile', subTitle: 'Create a new import profile.', module: 'importprofile' } },
    { path: "/facility/import/profile/:mask", name: "importprofile-edit", component: generic.ImportProfileRecord, meta: { title: 'Edit Import Profile', subtitle: 'Edit with a new import file.', module: 'importprofile' } },
    // Import Engine --> Requests
    { path: "/facility/import/requests", name: "importrequest-index", component: generic.ImportRequestGrid, meta: { title: 'Import Requests', subtitle: 'List of request import...', module: 'importrequest' } },

    /** Audit Logs **/
    { path: "/facility/auditlogs", name: "auditlog-index", component: generic.AuditLogGrid, meta: { title: 'Audit Logs', subtitle: 'List of logs of all API and carrier requests...', module: 'auditlog' } },
    { path: "/facility/printjobs", name: "printjob-index", component: generic.PrintJobGrid, meta: { title: 'Print Jobs', subtitle: 'List of print jobs requests...', module: 'printjob' } },

    /** Search **/
    { path: "/facility/search", name: "search-index", component: generic.SearchGrid, meta: { title: 'Search', module: 'search' } },



    /********** ADMIN **********/
    // Address Book
    { path: "/admin/addressbooks", name: "addressbook-index", component: generic.AddressBookGrid, meta: { title: 'Address Books', subtitle: 'List of address books within environment.', module: 'addressbook' } },
    { path: "/admin/addressbook/create", name: "addressbook-add", component: generic.AddressBookRecord, meta: { title: 'Add Address Book', subtitle: 'Create new address book.', module: 'addressbook' } },
    { path: "/admin/addressbook/:mask", name: "addressbook-edit", component: generic.AddressBookRecord, meta: { title: 'Address Book', module: 'addressbook' } },
    // Provider Accounts
    { path: "/admin/provideraccounts", name: "provideraccount-index", component: generic.ProviderAccountGrid, meta: { title: 'Carrier Accounts', subtitle: 'List of service provider accounts.', module: 'provideraccount' } },
    { path: "/admin/provideraccount/:mask", name: "provideraccount-edit", component: generic.ProviderAccountRecord, meta: { title: 'Carrier Account', module: 'provideraccount' } },
    {
        path: "/admin/provideraccount/create", name: "provideraccount-add", components: { default: generic.ProviderAccountCreate, stepper: generic.ProviderAccountSTEP1 }, meta: { title: 'Add Carrier Account', subtitle: 'Create new address book.', module: 'provideraccount' },
        children: [
            { path: "", name: "provideraccount-credential", components: { stepper: generic.ProviderAccountSTEP1 }, meta: { title: 'Credentials', module: 'provideraccount' } },
            { path: "/admin/provideraccount/step/products", name: "provideraccount-products", components: { stepper: generic.ProviderAccountSTEP2 }, meta: { title: 'Select Products', module: 'provideraccount' } },
            { path: "/admin/provideraccount/step/locations", name: "provideraccount-locations", components: { stepper: generic.ProviderAccountSTEP3 }, meta: { title: 'Add Posting Location', module: 'provideraccount' } },
            { path: "/admin/provideraccount/step/customer", name: "provideraccount-customers", components: { stepper: generic.ProviderAccountSTEP4 }, meta: { title: 'Assign to Customer', module: 'provideraccount' } },
            { path: "/admin/provideraccount/step/confirmation", name: "provideraccount-confirmation", components: { stepper: generic.ProviderAccountSTEP5 }, meta: { title: 'Confirm Credentials', module: 'provideraccount' } },
        ]
    },
    // Conversion Factor Matrix
    { path: "/admin/conversionfactormatrix", name: "conversionfactormatrix-index", component: generic.ConversionMatrixGrid, meta: { title: 'Conversion Factor Matrix', subtitle: 'List of conversion matrix for volumetric weight computation.', module: 'conversionfactormatrix' } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = "COSMOS by Integro360 Limited | " + (to.meta.title || "Page not found");
    window.scrollTo(0, 0);
    next();
});

export default router;