<template>
    <form-wrapper title="Sales Orders" sub-title="List of downloaded marketplace orders.">
        <div style="padding: 2rem 0.5rem 0; width: 100%;">
            <div class="p-grid p-nogutter" style="width: 100%;">
                <div class="p-col-12 p-md-6">
                    <form-lookup id="Cart" v-model="model.cart" @is-sync="onSelect($event)" :source="sources.carts" :sorted="false" :required="true" valueField="mask" ph="Select a shopping cart..." :disabled="activity.loading" disabledType="object">
                        <template #value="slotProps">
                            <slot name="value" :data="slotProps" v-if="selectedText">
                                <div class="selected-item" style="margin: 1px 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" v-if="selectedText">
                                    <span v-if="selectedText?.code" v-html="`<b>[${selectedText?.code}]</b> ${selectedText?.name}`"></span>
                                    <span v-else>{{ selectedText?.name }}</span>
                                </div>
                            </slot>
                            <span v-else class="p-dropdown-label p-placeholder" style="width: 100%;">{{ slotProps.data.placeholder }}&nbsp;</span>
                        </template>
                        <template #option="slotProps">
                            <div v-if="(slotProps.data.option?.id ?? 0) <= 0" style="font-weight: bold; opacity: 0.6">
                                Select a shopping cart...
                            </div>
                            <div v-else class="p-d-flex p-jc-between p-ai-center">
                                <div style="white-space: normal;">
                                    <div class="p-d-flex p-jc-start p-ai-center">
                                        <div style="width: 5rem; height: 2rem;"><img :src="`/_assets/_platform/${$vm.$marketplaces.filter(x => x.id === slotProps.data.option.credentials.marketplaceID)[0]?.mask}.png`" style="max-height: 2rem; object-fit: cover; max-width: 5rem;" /></div>
                                        <div style="padding-left: 1rem;">
                                            <b>[{{ slotProps.data.option?.code }}]</b>
                                            {{ slotProps.data.option?.name }}<br />
                                            <!--<a :href="`https://${slotProps.data.option?.shopInfo?.storeDomain ?? slotProps.data.option?.shopInfo?.shopDomain ?? ''}`">https://{{ slotProps.data.option?.shopInfo?.storeDomain ?? slotProps.data.option?.shopInfo?.shopDomain ?? "" }}</a>-->
                                        </div>

                                    </div>
                                </div>
                                <div style="font-weight: bold; opacity: 0.6; padding-left: 2rem;">
                                    {{ getCustomers(slotProps.data.option?.customerID) }}
                                </div>
                            </div>
                        </template>
                    </form-lookup>
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="p-grid p-nogutter">
                        <div class="p-col-4">
                            <form-lookup id="SalesOrderStatus" v-model="model.filters.fulfillmentStatus" :source="$vm.$enums.platformsalesorderstatus" :defaultValue="'Pending'" :sorted="false" :required="false" :disabled="model.cart === '00000000000000000000000000000000' || activity.loading" disabledType="object" />
                        </div>
                        <div class="p-col-4">
                            <form-datetime-utc v-model="model.date.from" type="date" id="CreatedFrom" :max="new Date(new Date().toISOString().split('T')[0])" :showButton="false" :required="true" />
                        </div>
                        <div class="p-col-4">
                            <form-datetime-utc v-model="model.date.to" type="date" id="CreatedTo" :min="new Date(model.date.from)" :max="new Date(new Date().toISOString().split('T')[0])" :showButton="false" :required="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab>
                    <pv-tabpanel header="RESULT">
                        <order-grid :parameters="model" @is-loading="activity.loading = $event" @is-reset="activity.reset = null" />
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
    </form-wrapper>
</template>
<script>
    import orderGrid from '@/components/facility/marketplace/salesorder/salesorder-grid.vue';
    export default {
        name: 'StoreDownloadOrderTemplate',
        components: {
            'order-grid': orderGrid
        },
        data() {
            return {
                model: {
                    cart: "00000000000000000000000000000000",
                    filters: {
                        status: null,
                        paymentStatus: null,
                        fulfillmentStatus: null
                    },
                    date: {
                        from: null,
                        to: null
                    }
                },
                customers: [],
                sources: {
                    carts: [],
                    orderStatus: { default: "Any", source: [{ id: "Any", text: "Any" }] },
                    paymentStatus: { default: "Any", source: [{ id: "Any", text: "Any" }] },
                    fulfillmentStatus: { default: "Any", source: [{ id: "Any", text: "Any" }] }
                },
                activity: {
                    loading: false,
                    reset: null
                }
            }
        },
        computed: {
            parameter() {
                return {
                    order_status: this.model.filters.status,
                    payment_status: this.model.filters.paymentStatus,
                    fulfillment_status: this.model.filters.fulfillmentStatus,
                    created_from: this.model.date.from,
                    created_to: this.model.date.to
                };
            },
            selectedText() {
                return (this.sources.carts ?? []).filter(x => x.mask === this.model.cart)[0];
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
        },
        methods: {
            onSelect(data) {
                let platform = this.$vm.$marketplaces.filter(x => x.id === data?.record?.credentials?.marketplaceID ?? -1000)[0];
                if ((data?.record?.id ?? 0) === 0 || platform === null) {
                    this.sources.orderStatus = { default: "Any", source: [{ id: "Any", text: "Any" }] };
                    this.sources.paymentStatus = { default: "Any", source: [{ id: "Any", text: "Any" }] };
                    this.sources.fulfillmentStatus = { default: "Any", source: [{ id: "Any", text: "Any" }] };
                }
                else {
                    this.sources.orderStatus = platform.orderStatuses ?? { default: "Any", source: [{ id: "Any", text: "Any" }] };
                    this.sources.paymentStatus = platform.orderFinancialStatuses ?? { default: "Any", source: [{ id: "Any", text: "Any" }] };
                    this.sources.fulfillmentStatus = platform.orderFulfillmentStatuses ?? { default: "Any", source: [{ id: "Any", text: "Any" }] };
                }
                this.model.filters.status = this.sources.orderStatus.default;
                this.model.filters.paymentStatus = this.sources.paymentStatus.default;
                this.model.filters.fulfillmentStatus = this.sources.fulfillmentStatus.default;
                this.model.cartID = data?.record?.id;
            },
            getCustomers(id) {
                let customer = (this.customers ?? []).filter(x => x.id === id)[0];
                if (customer)
                    return customer.altName ?? customer.name
                return "";
            },
            getSource() {
                this.customers = this.$config.common.$customer(this.sessionKeys.environment);
                this.$store.dispatch("dynamicreport/getURL", { url: `/services/facility/cart` }).then((response) => {
                    let carts = [{
                        id: 0,
                        mask: "00000000000000000000000000000000",
                        name: "Select a shipping cart..."
                    }];
                    ((response?.result ?? []).filter(x => x.status > 100) ?? []).forEach((x) => carts.push(x));
                    this.sources.carts = carts;
                    this.model.cart = "00000000000000000000000000000000";
                });
            }
        },
        created() {
            if (this.model.date.from == null)
                this.model.date.from = new Date(new Date().toISOString().split('T')[0]);
            if (this.model.date.to == null)
                this.model.date.to = new Date(new Date().toISOString().split('T')[0]);
        },
        mounted() {
            this.model = {
                cart: "00000000000000000000000000000000",
                filters: {
                    status: null,
                    paymentStatus: null,
                    fulfillmentStatus: null
                },
                date: {
                    from: null,
                    to: null
                }
            };
            this.getSource();
        }
    }
</script>