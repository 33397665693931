/* Inteface Tools */
/* 1: ***** PRIME VUE ***** */
// 1.1: Configurations
import PrimeVue from 'primevue/config';
// 1.2: Styles and Icons
import 'primeflex/primeflex.min.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// 1.3: Components
// 1.3.1: Forms
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';
import CascadeSelect from 'primevue/cascadeselect';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import TreeSelect from 'primevue/treeselect';
import TriStateCheckbox from 'primevue/tristatecheckbox';
// 1.3.2: Buttons
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import SpeedDial from 'primevue/speeddial';
// 1.3.3: Data
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import DataView from 'primevue/dataview';
import VirtualScroller from 'primevue/virtualscroller';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import Paginator from 'primevue/paginator';
import PickList from 'primevue/picklist';
import Timeline from 'primevue/timeline';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
// 1.3.4: Panels
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import DeferredContent from 'primevue/deferredcontent';
import Divider from 'primevue/divider';
import Fieldset from 'primevue/fieldset';
import Panel from 'primevue/panel';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import ScrollPanel from 'primevue/scrollpanel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toolbar from 'primevue/toolbar';
// 1.3.5: Overlays
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Dialog from 'primevue/dialog';
import OverlayPanel from 'primevue/overlaypanel';
import Sidebar from 'primevue/sidebar';
import Tooltip from 'primevue/tooltip';
// 1.3.6: Files
import FileUpload from 'primevue/fileupload';
// 1.3.7: Menu
import Breadcrumb from 'primevue/breadcrumb';
import ContextMenu from 'primevue/contextmenu';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import PanelMenu from 'primevue/panelmenu';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Dock from 'primevue/dock';
// 1.3.8: Charts
import Chart from 'primevue/chart';
// 1.3.9: Message
import Message from 'primevue/message';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
// 1.3.10: Media
import Carousel from 'primevue/carousel';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
// 1.3.11: Miscellaneous
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Chip from 'primevue/chip';
import BlockUI from 'primevue/blockui';
import Inplace from 'primevue/inplace';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Ripple from 'primevue/ripple';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import TerminalService from 'primevue/terminalservice';


/* 2: ***** KENDO ***** */
// 2.1. Kendo Tools
import '@progress/kendo-ui/js/kendo.editor';
import '@progress/kendo-ui/js/kendo.upload';
// 2.2. Styles
import '@progress/kendo-theme-default/dist/all.css'
// 2.3. Components
import { Editor, EditorTool, EditorInstaller } from '@progress/kendo-editor-vue-wrapper'
import { Upload, UploadInstaller } from '@progress/kendo-upload-vue-wrapper'

/* 3: ***** FONT AWESOME + MATERIAL DESIGN ICONS ***** */
import '@/assets/_css/font-awesome.min.css';
import '@mdi/font/css/materialdesignicons.min.css';

/* 4: ***** COSMOS TOOLS ***** */
// 4.1: Configuration
const config = require('@/assets/lib/cosmos/_js/config.js');
// 4.2: Styles
import '@/assets/lib/cosmos/_css/cosmos-variables.css';
import '@/assets/lib/cosmos/_css/cosmos.css';
import '@/assets/lib/cosmos/_css/cosmos-layout.css';
import '@/assets/lib/cosmos/_css/cosmos-primevue.css';
import '@/assets/lib/cosmos/_css/cosmos-kendo.css';
import '@/assets/_css/flags.css';
// 4.3: Components
// 4.3.1: Parts
import CosmosHeader from '@/components/shared/templates/header-template.vue';
import CosmosFooter from '@/components/shared/templates/footer-template.vue';
import CosmosContent from '@/components/shared/templates/content-template.vue';
import CosmosEditor from '@/components/shared/templates/editor-template.vue';
import CosmosTask from '@/components/shared/templates/task-template.vue';
// 4.3.2: Editors
import CosmosInput from '@/components/shared/editors/form-input.vue';
import CosmosNumeric from '@/components/shared/editors/form-numeric.vue';
import CosmosDateTime from '@/components/shared/editors/form-datetime.vue';
import CosmosDateTimeUTC from '@/components/shared/editors/form-datetime-utc.vue';
import CosmosBoolean from '@/components/shared/editors/form-boolean.vue';
import CosmosLookup from '@/components/shared/editors/form-lookup.vue';
import CosmosAddress from '@/components/shared/editors/form-address.vue';
import CosmosContact from '@/components/shared/editors/form-contact.vue';
import CosmosFileUpload from '@/components/shared/editors/form-upload.vue';
import CosmosFileUploadAdvance from '@/components/shared/editors/form-uploadadvance.vue';
import CosmosAddressV2 from '@/components/shared/editors/v2/form-address.vue';
import CosmosContactV2 from '@/components/shared/editors/v2/form-contact.vue';
// 4.3.3: Grid Inline Editors
import CosmosGridLookup from '@/components/shared/editors/grid-lookup.vue';

/* 5: ***** DATA HANDLING TOOLS ***** */
// 5.1: String Handling
import properCase from 'titleize';
const pluralize = require('pluralize');
const humanCase = require('humanize-string');
const camelCase = require('camelcase');
const decamelCase = require('decamelize');
import qrcode from 'qrcode.vue';
// 5.2: Date Time Handling
import moment from 'moment';
// 5.3: Secured Local Storage
import SecureLS from "secure-ls";
const ls = new SecureLS({ encryptionSecret: config.secret })
// 5.4: Http Client 
import axios from 'axios';
/*axios.setTimeout(4000);*/
axios.interceptors.request.use(request => {
    request.headers.common["x-cosmos-appid"] = 'cosmos';
    if (ls.get("x-cosmos-token"))
        request.headers.common["Authorization"] = "Bearer " + ls.get("x-cosmos-token").token;
    if (sessionStorage.getItem("activekeys"))
        request.headers.common["x-cosmos-activekeys"] = sessionStorage.getItem("activekeys");
    request.headers.common["Accept"] = "application/json";
    request.headers.common["Content-Type"] = "application/json";
    request.headers.common["Access-Control-Allow-Origin"] = "*";
    if (sessionStorage.getItem("session-id"))
        request.headers.common["x-session-id"] = sessionStorage.getItem("session-id");
    return request;
});
axios.interceptors.response.use(null, err => {
    if (err !== undefined) {
        const error = err.response;
        if (error.status == 401) { 
            try {
                sessionStorage.clear();
                localStorage.clear();
            }
            catch {/**/ }
            //router.push({ path: `/account/login` });
            setTimeout(() => {
                if (location.href.indexOf('/account/login') == -1)
                    location.href = '/account/login';
                else
                    router.push({ path: `/account/login` });
            }, 3000);
        }
        //if (error && location.href.indexOf('/public/') === -1) {
        //    if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
        //        localStorage.removeItem("x-i360-accesstoken");
        //        router.push({ path: `/account/login` })
        //    }
        //    else if (error.status == 404 && error.config && !error.config.__isRetryRequest)
        //        router.push({ name: `notfound` })
        //    else
        //        throw error;
        //}
        //else if (error && location.href.indexOf('/public/') > 0)
        throw error;
    }
});
// 5.5: Stylesheet (CSS) Helper (this is to perform media query per tag)
const elementMediaQueries = require('css-element-queries/src/ElementQueries');





/* Base Configurations */
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
store.$axios = axios;
store.$ls = ls;
import router from './router';
router.$store = store;
const vm = window;
vm.$submitted = false;
vm.$authenticated = false;
vm.$carriers = null;
vm.$session = {
    tenants: [],
    warehouse: [],
    customer: [],
    retailer: []
};
vm.$appSession = {
    environment: { id: -1, name: 'Integro360 Limited' },
    warehouse: { id: -1, name: 'Warehouse' },
    customer: { id: -1, name: 'Customer' },
};
vm.$location = config.default.constant.location[config.default.config.local];
vm.$providers = config.default.common.$integration();
vm.$integrations = config.default.common.$integrationtypes();
vm.$carriers = config.default.common.$integrationcarriers();
vm.$products = config.default.common.$integrationproducts();
vm.$columns = config.default.common.$dynamicreportbasefields();
vm.$platforms = config.default.common.$cartplatform();
vm.$marketplaces = config.default.common.$marketplace();
vm.$tz = config.default.common.$tz();
vm.$currencies = config.default.common.$currency();
vm.$countries = config.default.common.$country();
vm.$languages = config.default.common.$language();
vm.$enums = config.default.tools.$allEnums();
vm.$enums = config.default.tools.$allEnums();
vm.storage = {
    app: {
        common: {
            $domain: config.default.common.$tenant(),
            $currency: config.default.common.$currency(),
            $country: config.default.common.$country()
        },
        source: {
            environment: config.default.common.$environment(),
            warehouse: [],
            $customer: [],
        },
        session: {
            location: config.default.constant.location[config.location],
            tenant: -1,
            environment: -1,
            warehouse: -1,
            customer: -1,
            user: -1
        }
    }
};
// Use Jquery
//vm.$ = vm.jQuery = require('jquery');
window.$ = window.jQuery = require('jquery');
// Use Fetchify
import '@/assets/lib/cosmos/_js/transliteration.min.js';
const fetchify = require('@/assets/lib/cosmos/_js/craftyclicks.js');
var clickToAddress = new fetchify.default({
    accessToken: config.default.config.subscription.fetchify.key,
    domMode: 'name',
    gfxMode: 1,
    style: {
        ambient: 'light', // Use white as main interface color
        accent: 'default' // Use default secondary color for interface
    },
    showLogo: false, // Show Address Auto-Complete logo
    historyTools: true, // Show arrows
    defaultCountry: 'gbr', // Sets the default country to the UK
    getIpLocation: false, // Sets default country based on IP
    countrySelector: true, // Country list is enabled
    enabledCountries: [], // Enabled countries are the UK, USA and Canada
    countryMatchWith: 'iso_3', // enabledCountries uses ISO_3 codes
    countryLanguage: 'en', // Country list displayed in English
    disableAutoSearch: false, // Auto-search is enabled
    limitToMaxLength: {
        enabled: true,
        useEllipsis: false // Note: This feature may not be compatible with certain database systems
    },
    transliterate: true, // Converts non-Latin script search results to Latin script
    useCeremonialCounties: false, // UK only
    texts: {
        // Placeholder to be displayed in the search box
        default_placeholder: 'Start with post/zip code or street',
        // Placeholder to be displayed when searching for a country in search box
        country_placeholder: 'Type here to search for a country',
        // Object containing placeholders specific to a country
        placeholder_country_overrides: {
            usa: "Enter ZIP code",
            gbr: "Enter postcode"
        },
        // Text to be displayed on button to change country in search interface
        country_button: 'Change Country',
        // Text to be displayed when an error occurs
        generic_error: 'An error occurred. Please enter your address manually',
        // Text to be displayed when no results are found
        no_results: 'No results found',
        // Text to be displayed when there are multiple sub-results
        more: '{{value}} more'
    }
});
//// Use Neodynamics
const neodynamics = require('@/assets/lib/cosmos/_js/neodynamics.js');
//neodynamics.default.init();
//new Promise(() => {
//    neodynamics.default.ping();
//    for (var i = 0; i < 10; i++)
//        setTimeout(() => { neodynamics.default.printers(Math.random()); }, (i + 1) * 1000);
//});
/* Application Initialization */
const app = createApp(App);
app.use(store);
app.use(router);
app.use(ls);
app.use(moment);
// PrimeVue
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(ConfirmationService);
app.use(EditorInstaller);
app.use(UploadInstaller);

// Directives
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive("lowercase", {
    updated(el) {
        if (el.value)
            el.value = el.value.toLowerCase();
        else {
            let input = el.getElementsByTagName("input")[0];
            if (input)
                input.value = input.value.toLowerCase();
        }
    }
});
app.directive("code", {
    updated(el) {
        if (el.value) {
            el.value = el.value.toUpperCase();
        }
        else {
            let input = el.getElementsByTagName("input")[0];
            if (input) {
                input.value = input.value.toUpperCase().replace(/[^A-Z0-9-_./\\]/g, '');
            }
        }
    }
});
app.directive("uppercase", {
    updated(el) {
        if (el.value) {
            el.value = el.value.toUpperCase();
        }
        else {
            let input = el.getElementsByTagName("input")[0];
            if (input) {
                input.value = input.value.toUpperCase();
            }
        }
    }
});
app.directive("focus", {
    mounted(el) {
        if (el.value)
            el.focus();
        else {
            let input = el.getElementsByTagName("input")[0];
            if (input)
                input.focus();
        }
    }
});
// PrimeVue Components Registration
// FORMS
app.component('pv-autocomplete', AutoComplete);
app.component('pv-calendar', Calendar);
app.component('pv-cascadeselect', CascadeSelect);
app.component('pv-checkbox', Checkbox);
app.component('pv-chips', Chips);
app.component('pv-color', ColorPicker);
app.component('pv-dropdown', Dropdown);
app.component('pv-inputmask', InputMask);
app.component('pv-numeric', InputNumber);
app.component('pv-switch', InputSwitch);
app.component('pv-input', InputText);
app.component('pv-knob', Knob);
app.component('pv-listbox', Listbox);
app.component('pv-multiselect', MultiSelect);
app.component('pv-password', Password);
app.component('pv-radio', RadioButton);
app.component('pv-rating', Rating);
app.component('pv-selectbutton', SelectButton);
app.component('pv-slider', Slider);
app.component('pv-textarea', Textarea);
app.component('pv-toggle', ToggleButton);
app.component('pv-tree-select', TreeSelect);
app.component('pv-checkbox-tristate', TriStateCheckbox);
// Buttons
app.component('pv-button', Button);
app.component('pv-splitbutton', SplitButton);
app.component('pv-speeddial', SpeedDial);
// Data
app.component('pv-grid', DataTable);
app.component('pv-grid-column', Column);
app.component('pv-grid-column-group', ColumnGroup);
app.component('pv-grid-row', Row);
app.component('pv-dataview', DataView);
app.component('pv-virtualscroller', VirtualScroller);
app.component('pv-orderlist', OrderList);
app.component('pv-organizationchart', OrganizationChart);
app.component('pv-paginator', Paginator);
app.component('pv-picklist', PickList);
app.component('pv-timeline', Timeline);
app.component('pv-tree', Tree);
app.component('pv-treetable', TreeTable);
// Panels
app.component('pv-accordion', Accordion);
app.component('pv-accordion-tab', AccordionTab);
app.component('pv-card', Card);
app.component('pv-deferredcontent', DeferredContent);
app.component('pv-divider', Divider);
app.component('pv-fieldset', Fieldset);
app.component('pv-panel', Panel);
app.component('pv-splitter', Splitter);
app.component('pv-split-panel', SplitterPanel);
app.component('pv-scroll-panel', ScrollPanel);
app.component('pv-tab', TabView);
app.component('pv-tabpanel', TabPanel);
app.component('pv-toolbar', Toolbar);
// Overlays
app.component('pv-confirm', ConfirmDialog);
app.component('pv-confirm-popup', ConfirmPopup);
app.component('pv-dialog', Dialog);
app.component('pv-overlay-panel', OverlayPanel);
app.component('pv-sidebar', Sidebar);
// File Upload
app.component('pv-file', FileUpload);
// Menu
app.component('pv-breadcrumb', Breadcrumb);
app.component('pv-contextmenu', ContextMenu);
app.component('pv-megamenu', MegaMenu);
app.component('pv-menu', Menu);
app.component('pv-menubar', Menubar);
app.component('pv-panelmenu', PanelMenu);
app.component('pv-steps', Steps);
app.component('pv-tabmenu', TabMenu);
app.component('pv-tieredmenu', TieredMenu);
app.component('pv-dock', Dock);
// Chart
app.component('pv-chart', Chart);
// Message
app.component('pv-message', Message);
app.component('pv-toast', Toast);
// Media
app.component('pv-carousel', Carousel);
app.component('pv-image-gallery', Galleria);
app.component('pv-image', Image);
// Miscellaneous
app.component('pv-avatar', Avatar);
app.component('pv-avater-group', AvatarGroup);
app.component('pv-badge', Badge);
app.component('pv-chip', Chip);
app.component('pv-block-ui', BlockUI);
app.component('pv-inplace', Inplace);
app.component('pv-scrolltop', ScrollTop);
app.component('pv-skeleton', Skeleton);
app.component('pv-progress-bar', ProgressBar);
app.component('pv-progress-spinner', ProgressSpinner);
app.component('pv-tag', Tag);
app.component('pv-terminal', Terminal);
app.component('pv-terminal-service', TerminalService);


// COSMOS Components Registration
import LoadingStatus from '@/components/shared/helpers/preloader.vue';
import DialogTemplate from '@/components/shared/helpers/dialog-template.vue';
import FormTemplate from '@/components/shared/layouts/form-template.vue';
import ImageUploadTemplate from '@/components/shared/editors/editor-imageupload.vue';
import AddressLookup from '@/components/shared/tools/address-lookup.vue';
import AddressBookLookup from '@/components/shared/tools/addressbook-lookup.vue';
import GridTemplate from '@/components/shared/editors/editor-grid.vue';
import ColumnTemplate from '@/components/shared/editors/editor-column.vue';
app.component('is-loader', LoadingStatus);
app.component('is-form', FormTemplate);
app.component('is-dialog', DialogTemplate);
app.component('is-imageupload', ImageUploadTemplate);
app.component('is-addresslookup', AddressLookup);
app.component('is-addressbooklookup', AddressBookLookup);

app.component('is-grid', GridTemplate);
app.component('is-column', ColumnTemplate);
// FINAL OBJECTS
import FormObjectTemplate from '@/components/shared/layouts/object-template.vue';
import FormGridTemplate from '@/components/shared/editors/form/form-grid.vue';
import FormGridTemplate2 from '@/components/shared/editors/form/form-grid-newstore.vue';
import FormGridTester from '@/components/shared/editors/form/form-grid-filtertest.vue';
import RecordFormTemplate from '@/components/shared/layouts/record-template.vue';
import WrapperTemplate from '@/components/shared/layouts/wrapper-template.vue';
import RecordToolbarTemplate from '@/components/shared/helpers/menu-template.vue';
app.component('is-form-object', FormObjectTemplate);
app.component('form-grid', FormGridTemplate);
app.component('cosmos-grid', FormGridTemplate2);
app.component('cosmos-grid-filtered', FormGridTester);
app.component('form-record', RecordFormTemplate);
app.component('form-toolbar', RecordToolbarTemplate);
app.component('form-wrapper', WrapperTemplate);
app.component('qr-code', qrcode);
app.component('k-editor', Editor);
app.component('k-editor-tool', EditorTool);
app.component('k-upload', Upload);




/* Register COSMOS objects */
app.component('cosmos-header', CosmosHeader);
app.component('cosmos-footer', CosmosFooter);
app.component('cosmos-content', CosmosContent);
app.component('cosmos-task', CosmosTask);
app.component('form-object', CosmosEditor);
app.component('form-input', CosmosInput);
app.component('form-numeric', CosmosNumeric);
app.component('form-datetime', CosmosDateTime);
app.component('form-datetime-utc', CosmosDateTimeUTC);
app.component('form-boolean', CosmosBoolean);
app.component('form-lookup', CosmosLookup);
app.component('form-address', CosmosAddress);
app.component('form-contact', CosmosContact);
app.component('form-file', CosmosFileUpload);
app.component('form-fileadvance', CosmosFileUploadAdvance);
app.component('grid-lookup', CosmosGridLookup);
app.component('form-address-info', CosmosAddressV2);
app.component('form-contact-info', CosmosContactV2);

// Global Properties
app.config.globalProperties.$ls = ls;
app.config.globalProperties.$vm = vm;
app.config.globalProperties.$httpClient = config.default.httpClient;
app.config.globalProperties.$config = config.default;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$fetchify = clickToAddress;
app.config.globalProperties.$print = (sid, printer, file, tenant, folder) => { neodynamics.default.print(`sid=${sid}&pid=0&printer=${(printer ?? 'Use Default Printer')}&file=${file}&tenant=${(tenant ?? 'cosmos')}&folder=${(folder ?? "consignment/documents")}`); };
// Prime Vue Related Configurations

// Get cached data globally
// TO TRIGGER CHANGES ON ACTIVE KEYS GLOBALY/ FOR WATCH VUE FUNCTION
app.config.globalProperties.resetSetItem = function (key, newVal) {
    if (key === 'activekeys') {
        //  Create a StorageEvent event
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);

                //  Initialize the created event
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

                //  Distribution object
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
};


// CLEANED FINAL COMPONENTS
app.config.globalProperties.$filters = {
    location() {
        return vm.$location;
    },
    cutText(value, length) { return (value ?? "").length <= length ? value : value.substring(0, length) + "..."; },
    titleize(value) { return value ? properCase(humanCase(value)) : value; },
    humanize(value) { return humanCase(value); },
    camelize(value) { return camelCase(value); },
    decamelize(value) { return decamelCase(value); },
    pluralize(value) { return pluralize(value); },
    singularize(value) { return pluralize.singular(value); },
    quantifiedDisplay(value, number) { return pluralize(value, number === 0 ? 1 : number); },
    padLeft(str, lenght, placeholder) {
        return (str + "").padStart(lenght, placeholder);
    },
    todayLocalTime() {
        return moment(moment.utc().toDate()).local().format('ddd, h:mm:ss A')
    },
    todayUTCTime() {
        return moment.utc().format('ddd, h:mm:ss A')
    },
    toDate(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('dddd, MMMM DD YYYY, h:mm:ss a');
        return "";
    },
    toDateOnly(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('dddd, MMMM DD YYYY');
        return "";
    },
    toDateFileName(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('YYYYDDMMHHmmssSSSS');
        return "";
    },
    toDateTimeDisplay(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('DD/MMM/YYYY hh:mm a');
        return "";
    },
    toDateFullTimeDisplay(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('DD/MMM/YYYY HH:mm:ss');
        return "";
    },
    toDateDisplay(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('DD/MMM/YYYY');
        return "";
    },
    toDate24HTimeDisplay(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('DD/MMM/YYYY HH:mm');
        return "";
    },
    toTimeDisplay(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('hh:mm A');
        return "";
    },
    formatDate(value) {
        if (value)
            return moment(moment.utc(value).toDate()).local().format('YYYY-MM-DDTHH:mm:ssZ');
        return "";
    },
    formatDateWithFormat(value, format) {
        //'YYYY-MM-DDTHH:mm:ssZ'
        if (value)
            return moment(moment.utc(value).toDate()).local().format(format);
        return "";
    },
    formatDateUTCFormat(value, format) {
        if (value)
            return moment.utc(value).format(format);
        return "";
    },
    //getRelative(value) { return moment(moment.utc(value).toDate()).startOf('mm').fromNow() },
    //toUTCDate(value) { return moment.utc(value, "YYYY-MM-DD hh:ss:mm a") },
    telephone(value) {
        return value;
    },
    getFlags: (value) => {
        let selected = [];
        var converted = Array.from(value.toString(2)).reverse();
        var multiplier = 1;
        for (let i = 0; i < converted.length; i++) {
            if (converted[i] === "1")
                selected.push(multiplier);
            multiplier *= 2;
        }
        return selected;
    },
    setFlag: (array) => {
        let total = 0;
        array.forEach((value) => total += Number(value));
        return total;
    },
    init(model) {
        var entity = {};
        if (model) {
            Object.keys(model).forEach((key) => {
                //entity[key] = model[key];
                entity[key] = typeof (model[key]) === 'boolean' ? model[key] === "on" ? true : model[key] : model[key];
            });
        }
        return entity;
    },
    //map(form, model) {
    //    var values = $(form).serializeArray();
    //    $.map(values, function (n) {
    //        // model[n['name']] = n['value'];
    //        model[n['name']] = typeof (model[n['name']]) === 'boolean' ? n['value'] === "on" ? true : n['value'] : n['value'];
    //    });
    //    return model;
    //},
    formatNumber(value, decimal, country) {
        return new Intl.NumberFormat('en-' + (country ?? 'GB'), { minimumFractionDigits: decimal }).format(value);
    },
    formatCurrency(value, currencyCode, country, decimal) {
        if (value == null || value == undefined)
            return null;
        if (currencyCode)
            return new Intl.NumberFormat('en-' + (country ?? 'GB'), {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: decimal ?? 2
            }).format(value);
        else
            return new Intl.NumberFormat('en-' + (country ?? 'GB'), { minimumFractionDigits: 2 }).format(value);
    },
    isDomestic(originCountry, originPostalCode, destinationCountry, destinationPostalCode) {
        originCountry = (originCountry ?? 'GB').toUpperCase();
        originPostalCode = (originPostalCode ?? '').toUpperCase();
        destinationCountry = (destinationCountry ?? 'GB').toUpperCase();
        destinationPostalCode = (destinationPostalCode ?? '').toUpperCase();
        originPostalCode;
        destinationPostalCode;
        return originCountry === destinationCountry;
        //if (originCountry === "GB" && destinationCountry === "GB") {
        //    return (!(
        //        (originPostalCode.indexOf("BT") !== destinationPostalCode.indexOf("BT") && (originPostalCode.indexOf("BT") !== -1 || destinationPostalCode.indexOf("BT") !== -1))
        //        //||
        //        //(originPostalCode.indexOf("IM") !== destinationPostalCode.indexOf("IM") && (originPostalCode.indexOf("IM") !== -1 || destinationPostalCode.indexOf("IM") !== -1))
        //        //||
        //        //(originPostalCode.indexOf("JE") !== destinationPostalCode.indexOf("JE") && (originPostalCode.indexOf("JE") !== -1 || destinationPostalCode.indexOf("JE") !== -1))
        //        //||
        //        //(originPostalCode.indexOf("GY") !== destinationPostalCode.indexOf("GY") && (originPostalCode.indexOf("GY") !== -1 || destinationPostalCode.indexOf("GY") !== -1))
        //    ));
        //    //if (!(originPostalCode.startsWith("BT")) && destinationPostalCode.startsWith("BT"))
        //    //    return false;
        //    //if ((originPostalCode.startsWith("BT")) && !(destinationPostalCode.startsWith("BT")))
        //    //    return false;
        //    //return (!(originPostalCode.startsWith("BT")) && !(destinationPostalCode.startsWith("BT")))
        //}
        //else {
        //    return originCountry === destinationCountry;
        //}

    },
    formatString(template, ...args) {
        return (template ?? '').replace(/{([0-9]+)}/g, function (match, index) {
            return typeof args[index] === 'undefined' ? match : args[index];
        });
    },
    // Local Time
    $today: () => moment.utc().local().format('DD/MMM/YYYY HH:mm:ss'),
    $shortDate: (date) => moment(moment.utc(date).toDate()).local().format('DD/MMM/YYYY'),
    $shortDateCode: (date) => moment(moment.utc(date).toDate()).local().format("YYYY-MM-DD"),
    $dateTime24H: (date) => moment(moment.utc(date).toDate()).local().format("DD/MMM/YYYY HH:mm:ss"),
    $dateTime12H: (date) => moment(moment.utc(date).toDate()).local().format("DD/MMM/YYYY hh:mm:ss A"),
    $dateTimeCode: (date) => moment(moment.utc(date).toDate()).local().format("YYYY-MM-DDTHH:mm:ssZ"),
    $shortTime24H: (date) => moment(moment.utc(date).toDate()).local().format('HH:mm'),
    $shortTime12H: (date) => moment(moment.utc(date).toDate()).local().format('hh:mm A'),
    $longTime24H: (date) => moment(moment.utc(date).toDate()).local().format('HH:mm:ss'),
    $longTime12H: (date) => moment(moment.utc(date).toDate()).local().format('hh:mm:ss A'),
    $dayOfWeek: (date) => moment(moment.utc(date).toDate()).local().format('dddd'),
    $monthOfYear: (date) => moment(moment.utc(date).toDate()).local().format('MMMM'),
    $format: (date, format) => moment(moment.utc(date).toDate()).local().format(format),
    $epoch: (date) => moment(moment.utc(date).toDate()).local().valueOf(),
    $toLocal: (date) => moment(moment.utc(date).toDate()).local(),
    //$toLocalTZ: (date) => moment(moment.utc(date).toDate()).local().tz(tz).format('dddd, MMM DD YYYY, h:mm A zz'),

    // UTC Time
    $todayUTC: () => moment.utc().format('DD/MMM/YYYY HH:mm:ss'),
    $shortDateUTC: (date) => moment(moment.utc(date).toDate()).utc().format('DD/MMM/YYYY'),
    $shortDateCodeUTC: (date) => moment(moment.utc(date).toDate()).utc().format("YYYY-MM-DD"),
    $dateTime24HUTC: (date) => moment(moment.utc(date).toDate()).utc().format("DD/MMM/YYYY HH:mm:ss"),
    $dateTime12HUTC: (date) => moment(moment.utc(date).toDate()).utc().format("DD/MMM/YYYY hh:mm:ss A"),
    $dateTimeCodeUTC: (date) => moment(moment.utc(date).toDate()).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
    $shortTime12HUTC: (date) => moment(moment.utc(date).toDate()).utc().format('hh:mm A'),
    $shortTime24HUTC: (date) => moment(moment.utc(date).toDate()).utc().format('HH:mm'),
    $longTime12HUTC: (date) => moment(moment.utc(date).toDate()).utc().format("hh:mm:ss A"),
    $longTime24HUTC: (date) => moment(moment.utc(date).toDate()).utc().format("HH:mm:ss"),
    $dayOfWeekUTC: (date) => moment(moment.utc(date).toDate()).utc().format('dddd'),
    $monthOfYearUTC: (date) => moment(moment.utc(date).toDate()).utc().format('MMMM'),
    $formatUTC: (date, format) => moment(moment.utc(date).toDate()).utc().format(format),
    $epochUTC: (date) => moment(moment.utc(date).toDate()).utc().valueOf(),
    $toUTC: (date) => moment(moment.utc(date).toDate()).utc(),
    // $toUTCTZ: (date) => moment(moment.utc(date).toDate()).local().tz("Etc/UTC").format('dddd, MMM DD YYYY, h:mm A zz'),
    convertDimension(value, unit, sourceUnit, decimal) {
        let CM = 100, MM = 200,IN = 300, MT= 500;
        value = (value ?? 0.00);
        decimal = decimal ?? 2;
        if (value <= 0.00)
            return value;
        switch (unit) {
            case CM:
                switch (sourceUnit) {
                    case IN:
                        return parseFloat((value * 2.54).toFixed(decimal));
                    case MM:
                        return parseFloat((value * 0.1).toFixed(decimal));
                    case MT:
                        return parseFloat((value * 100).toFixed(decimal));
                    default:
                        return value;
                }
            case IN:
                switch (sourceUnit) {
                    case CM:
                        return parseFloat((value * 0.393701).toFixed(decimal));
                    case MM:
                        return parseFloat((value * 0.0393701).toFixed(decimal));
                    case MT:
                        return parseFloat((value * 39.3701).toFixed(decimal));
                    default:
                        return value;
                }
            case MM:
                switch (sourceUnit) {
                    case CM:
                        return parseFloat((value * 10).toFixed(decimal));

                    case IN:
                        return parseFloat((value * 25.4).toFixed(decimal));
                    case MT:
                        return parseFloat((value * 1000).toFixed(decimal));
                    default:
                        return value;
                }
            case MT:
                switch (sourceUnit) {
                    case CM:
                        return parseFloat((value * 0.01).toFixed(decimal));
                    case MM:
                        return parseFloat((value * 0.0001).toFixed(decimal));
                    case IN:
                        return parseFloat((value * 0.0254).toFixed(decimal));
                    default:
                        return value;
                }
        }
        return 0;

    },
    convertVolume(unit, sourceUnit, length, width, height){
        let CM3 = 100, M3 = 200, IN3 = 300, FT3 = 400;
        let CM = 100, IN = 300, FT= 400, MT = 500;
        let convertedLength = length ?? 0.00;
        let convertedWidth = width ?? 0.00;
        let convertedHeight = height ?? 0.00;
        switch (unit) {
            case CM3:
                convertedLength = this.convertDimension(convertedLength, CM, sourceUnit);
                convertedWidth = this.convertDimension(convertedWidth, CM, sourceUnit);
                convertedHeight = this.convertDimension(convertedHeight, CM, sourceUnit);
                break;
            case M3:
                convertedLength = this.convertDimension(convertedLength, MT, sourceUnit,10);
                convertedWidth = this.convertDimension(convertedWidth, MT, sourceUnit, 10);
                convertedHeight = this.convertDimension(convertedHeight, MT, sourceUnit, 10);
                break;
            case IN3:
                convertedLength = this.$filters.convertDimension(convertedLength, IN, sourceUnit, 10);
                convertedWidth = this.$filters.convertDimension(convertedWidth, IN, sourceUnit, 10);
                convertedHeight = this.$filters.convertDimension(convertedHeight, IN, sourceUnit, 10);
                break;
            case FT3:
                convertedLength = this.$filters.convertDimension(convertedLength, FT, sourceUnit, 10);
                convertedWidth = this.$filters.convertDimension(convertedWidth, FT, sourceUnit, 10);
                convertedHeight = this.$filters.convertDimension(convertedHeight, FT, sourceUnit, 10);
                break;
        }
        return (convertedLength * convertedWidth * convertedHeight).toFixed(4);
    }


};
app.mount('#cosmos');
elementMediaQueries.listen();
elementMediaQueries.init();
