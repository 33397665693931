<template>
    <is-dialog header="SERVICE PROVIDER DETAILS" :visible="dialog.allocate.show" @is-confirm="onAllocateConfirm('salesOrder')" @is-cancel="{dialog.allocate.show = false;}" :confirmDisabled="action.processing" :cancelDisabled="action.processing" :breakpoints="{'360px': '75vw', '340px': '100vw'}" :style="{width: '35vw'}">
        <div class="confirmation-content p-grid p-d-flex">
            <div v-if="!action.processing" class="p-col-12">
                <div style="text-align:left; padding:0.25rem 0.5rem;" class="p-code-text">You are about to commit this salesorder to the following service provider:</div>
                <div class="card" :style="`background:var(--surface-a); width:calc(100%)`">
                    <table style="width: calc(100% + 1rem); padding: 0.25rem 0.5rem; margin-left:-0.5rem;" cellspacing="0">
                        <tr>
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">COLLECTION INFO</h4></th>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Collection Date</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.collectionDate,'DD/MMM/YYYY')}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Ready Time</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.readyTime, 'HH:mm')}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Closing Time</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatDateWithFormat(model?.closingTime, 'HH:mm')}`"></div></td>
                        </tr>
                        <tr>
                            <th colspan="3" class="p-code-text" style="text-align: center; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); background: var(--surface-d) "><h4 style="margin: -0.25rem -0.5rem">SHIPPING INFO</h4></th>

                        </tr>
                        <tr v-if="model?.shippingInfo?.serviceGroup?.code" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service Group</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(shippingInfoServiceGroupDS ?? []).filter(x => x.code == model.shippingInfo.serviceGroup.code)[0]?.text  ?? ''}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Carrier</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(shippingInfoCarrierDS ?? []).filter(x => x.Code == model.shippingInfo.carrier.code)[0]?.Text  ?? ''}`"></div></td>
                        </tr>
                        <tr style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Service</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(shippingInfoProductDS ?? []).filter(x => x.Code == model.shippingInfo.product.code)[0]?.Text ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model.shippingInfo.option?.code)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Option</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(shippingInfoOptionDS ?? []).filter(x => x.Code == model.shippingInfo.option.code)[0]?.Text  ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Customs Data</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(model.shippingInfo?.customization?.generateCustomsData ?? false) ? 'Y' : 'N'}`"></div></td>
                        </tr>
                        <tr v-if="model.shippingInfo.additional?.code" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Additional Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model.shippingInfo.additional?.code ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="model.shippingInfo.release.code" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Release Code</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="white-space: nowrap; text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model.shippingInfo.release?.code ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="model.shippingInfo.accesspoint?.code && !(model?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Access Point Location</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(shipingInfoAccessPointDS ?? []).filter(x => x.Code === model.shippingInfo.accesspoint.code)[0]?.FullDescription ?? ''}`"></div></td>
                        </tr>
                        <tr v-if="(model?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)" style="border-bottom:thin; border:0.01rem;">
                            <th class="p-code-text" style="white-space: nowrap; text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); ">Alternate Address</th>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; width:0.25rem; border-bottom: 0.01rem solid var(--surface-d); ">:</td>
                            <td class="p-code-text" style="text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${model.shippingInfo.accesspoint?.alternateDelivery?.address?.company ?? ''} ${model.shippingInfo.accesspoint?.alternateDelivery?.address?.fullDescription ?? ''}`"></div></td>
                        </tr>
                    </table>
                </div>
                <div style="text-align:center; vertical-align:middle; padding: 0.5rem -0.5rem; margin: 1rem;" class="p-code-text">Are you sure you still want to proceed?</div>
            </div>
            <div v-else class="p-d-flex p-grid p-col-12">
                <div class="p-col-12" style="text-align:center;">
                    <pv-progress-spinner style="padding:3rem;">PLEASE WAIT</pv-progress-spinner>
                </div>
                <div class="p-col-12" style="text-align:center;">
                    <span class="p-code-text" style="text-align:center"><b>...Allocating salesorder to carrier... Please Wait....</b></span>
                </div>

            </div>
        </div>
    </is-dialog>
    <div class="p-col-12 p-md-12">
        <div class="p-fluid p-grid">
            <div class="p-col-12 p-md-6">
                <h6><span>COLLECTION INFO</span></h6>
                <form-datetime id="collectionDate" :required="true" :value="model?.collectionDate ?? new Date()" type="date" @is-sync="onCollectionDate($event)" label="Collection Date" :v$="v$"></form-datetime>
                <form-datetime id="readyTime" :value="model?.readyTime ?? new Date(2018, 1, 1, 16, 0, 0)" type="time" @is-sync="onReadyTime($event)" :timeFormat="24" label="Ready Time" :v$="v$"></form-datetime>
                <form-datetime id="closingTime" :value="model?.closingTime ?? new Date(2018, 1, 1, 18, 0, 0)" type="time" @is-sync="onClosingTime($event)" :timeFormat="24" label="Closing Time" :v$="v$"></form-datetime>
            </div>
            <div class="p-col-12 p-md-6">
                <h6><span>SHIPPING INFO</span></h6>
                <form-lookup id="serviceGroupID" label="Service Group" :required="false" :showLoadingWhenEmpty="processing.shippingInfo.serviceGroup" :source="shippingInfoServiceGroupDS ?? []" sourceType="array" :value="model.shippingInfo.serviceGroup.code" v-model="model.shippingInfo.serviceGroup.code" :v$="v$" :textField="'text'" :valueField="'code'" :sorted="false" />
                <form-lookup id="serviceProviderAccountID" :v$="v$" vID="serviceProviderAccountID" vBase="shippingInfoModelValidation" :label="`${(!processing.shippingInfo.provider ? 'Carrier' : 'Evaluating valid carriers. Please wait...')}`" :required="false" :disabled="processing.shippingInfo.product" :source="shippingInfoCarrierDS ?? []" sourceType="array" :value="model.shippingInfo.carrier.code" v-model="model.shippingInfo.carrier.code" :showLoadingWhenEmpty="processing.shippingInfo.provider" :textField="'Text'" :valueField="'Code'" :sorted="false" :showCopy="false">
                    <template #option="slotProps">
                        <div class="p-d-flex" style="width:100%">
                            <div class="picklist-list-detail">
                                <div class="p-d-flex" style="padding: 0;">
                                    <div style="padding:0;width:100%">
                                        <div class="p-d-flex" style=" z-index: 100;">
                                            <div style="margin-right:5px">
                                                <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                            </div>
                                            <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.CarrierCode}}] </span>{{ slotProps.data.option.CarrierName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form-lookup>
                <form-lookup id="serviceProviderProductAccountID" :v$="v$" vID="serviceProviderProductAccountID" vBase="shippingInfoModelValidation" :label="`${(!processing.shippingInfo.product ? 'Service' : 'Evaluating valid services. Please wait...')}`" :required="false" :showCopy="false" :disabled="(model.shippingInfo?.carrier?.code == null || model.shippingInfo?.carrier?.code == undefined)" :source="shippingInfoProductDS ?? []" sourceType="array" :showLoadingWhenEmpty="(model?.shippingInfo.carrier?.code != null) && processing.shippingInfo.product" :value="model.shippingInfo.product.code" v-model="model.shippingInfo.product.code" @is-sync="{model.shippingInfo.product.configuration = $event?.record?.Configuration ?? null;}" :textField="'Text'" :valueField="'Code'" :sorted="false">
                    <template #option="slotProps">
                        <div class="p-d-flex" style="width:100%">
                            <div class="picklist-list-detail">
                                <div class="p-d-flex" style="padding: 0;">
                                    <div style="padding:0;width:100%">
                                        <div class="p-d-flex" style=" z-index: 100;">
                                            <div style="margin-right:5px">
                                                <img style="width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.option.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" />
                                            </div>
                                            <div class="p-d-flex p-jc-between" style="width:calc(100%)">
                                                <span><span class="p-code-text" style="font-weight: 600;">[{{ slotProps.data.option.Code}}] </span>{{ slotProps.data.option.Name }}</span>
                                                <spam class="p-jc-end">
                                                    <span class="p-code-text">
                                                        <span class="product-badge status-instock p-code-text" style="margin-left:0.75rem">{{$filters.formatCurrency(slotProps.data.option?.Quotation?.ShippingCost?.Price?.Amount ?? 0.00,slotProps.data.option?.Quotation?.ShippingCost?.Price?.Currency?.toUpperCase() ?? 'GBP',country?.code?.toUpperCase(),2)}}</span>
                                                        <i class="fa-duotone fa-gas-pump" v-if="slotProps.data.option?.Quotation?.ItemizedCharges?.filter(x => x.Name === 'Fuel Surcharge')[0]?.Name !== null" style="padding-left:0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`With Fuel Surcharge`"></i>
                                                        <i class="fa-duotone fa-gas-pump-slash" v-else style="padding-left: 0.5rem; font-size: 1rem; --fa-primary-color: gray; --fa-secondary-color: darkred; --fa-secondary-opacity: 1.0;" v-tooltip.top="`Without Fuel Surcharge`"></i>
                                                    </span>
                                                </spam>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form-lookup>
                <form-lookup v-if="(shippingInfoOptionDS ?? []).length > 0 || (model?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false)" :required="(model?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false)" id="serviceProviderOptionAccountID" label="Option" :source="shippingInfoOptionDS ?? []" sourceType="array" v-model="model.shippingInfo.option.code" :value="model.shippingInfo.option.code" :v$="v$" :textField="'Text'" :valueField="'Code'" :sorted="false" />
                <form-boolean v-if="(model?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false)" id="isShippingGenerateCustomsData" @is-sync="model.shippingInfo.customization.generateCustomsData = $event.value" :value="model.shippingInfo.customization.generateCustomsData" type="toggle" label="Customs Data?" textPosition="left"></form-boolean>
                <form-input v-if="(model?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false)" :required="(model?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false)" id="serviceProviderAdditionalCodeID" label="Additional Code" :v$="v$" v-model="model.shippingInfo.additional.code" :value="model.shippingInfo.additional.code" />
                <form-lookup v-if="(model?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false) && !model.shippingInfo.accesspoint.alternateDelivery.enable" :showLoadingWhenEmpty="(model?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false) && processing.shippingInfo.accesspoint" :required="(model?.shippingInfo?.product?.configuration?.RequireAccessPoint ?? false)" id="serviceProviderAccessPointAccountID" :v$="v$" vID="serviceProviderAccessPointAccountID" vBase="shippingInfoModelValidation" :label="`${(!processing.shippingInfo.accesspoint ? 'Access Point Location' : 'Retrieving valid access points. Please wait...')}`" :source="shipingInfoAccessPointDS ?? []" v-model="model.shippingInfo.accesspoint.code" :value="model.shippingInfo.accesspoint.code" :textField="'FullDescription'" :valueField="'Code'" :sorted="false" />
                <form-input v-if="(model?.shippingInfo?.product?.configuration?.ReleaseCode ?? false)" id="serviceProviderReleaseCodeID" label="Parcel Release Code" :v$="v$" v-model="model.shippingInfo.release.code" :value="model.shippingInfo.release.code" />
                <form-boolean v-if="(model?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false)" id="isShippingAlternateAddress" :value="model.shippingInfo.accesspoint.alternateDelivery.enable ?? false" type="toggle" label="Use Alternate Address?" @is-sync="{model.shippingInfo.accesspoint.alternateDelivery.enable = $event.value; model.shippingInfo.accesspoint.alternateDelivery.address.countryID = destinationAddress.countryID}" textPosition="left"></form-boolean>
                <h6 v-if="model.shippingInfo.accesspoint.alternateDelivery.enable" style="padding: 0.25rem"><span>SHIPPING ALTERNATE ADDRESS</span></h6>
                <form-address-info v-if="model.shippingInfo.accesspoint.alternateDelivery.enable" id="shippingInfoAlternateAddress" v-model="model.shippingInfo.accesspoint.alternateDelivery.address" :value="model.shippingInfo.accesspoint.alternateDelivery.address" :useCompany="true" :showLookup="true" :disabled="!model.shippingInfo.accesspoint.alternateDelivery.enable" @is-sync="onPopulateAlternateAddress($event,false)"  disabledView="perline" />
            </div>

        </div>
        <div class="p-md-12">
            <div class="p-d-flex p-a-end p-jc-end">
                <pv-button label="Allocate" style="width:unset !important;" @click="this.dialog.allocate.show = true" icon="mdi mdi-shuffle-variant" class="p-button" :disabled="!isValidAllocate" />
            </div>
        </div>
        <!--<div class="content-section">

        </div>-->
    </div>    
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    import {  helpers } from '@vuelidate/validators';
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: 'GuideTemplate',
        props: {
            salesOrderParameter: { type: Object },
            originAddress: { type: Object },
            destinationAddress: { type: Object },
            packages: { type: Object },
            lookupSource: { type: Object },
            customerID: { type: Number, default: null },
            warehouseID: { type: Number, default: null },
            packageType: { type: Number, default: 4 },
            weightUnit: { type: Number, default: 100 },
            lengthUnit: { type: Number, default: 100 },
            currency: { type: Number, default: 1 },
            shippingServiceName: { type: String, default: null },
            isReturnable: { type: Boolean, default: false },
            showAllocate: { type: Boolean, default: false },
            isValid: { type: Boolean, default: true },
        },
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        data() {
            return {
                shippingInfoModelValidation: {
                    serviceProviderAccountID: null,
                    serviceProviderProductAccountID: null,
                    serviceProviderAccessPointAccountID: null,
                },
                model: {  
                    collectionDate: null,
                    readyTime: null,
                    closingTime: null,
                    shippingInfo: {
                        serviceGroup: {
                            serviceGroupID: null,
                            code: null,
                            text: null
                        },
                        carrier: {
                            serviceProviderAccountID: null,
                            code: null,
                            text: null,
                            error: null
                        },
                        product: {
                            serviceProviderProductAccountID: null,
                            code: null,
                            text: null,
                            configuration: null,
                            error:null
                        },
                        option: {
                            serviceProviderOptionAccountID: null,
                            code: null,
                            text: null,
                        },
                        customization: {
                            generateCustomsData: false
                        },
                        accesspoint: {
                            serviceProviderAccessPointAccountID: null,
                            code: null,
                            text: null,
                            address: null,
                            error: null,
                            alternateDelivery: {
                                enable: false,
                                address: {
                                    address1: null,
                                    address2: null,
                                    address3: null,
                                    city: null,
                                    state: null,
                                    postalCode: null,
                                    countryID: null,
                                    company: null,
                                    establishmentType: null,
                                    fullDescription: null
                                },
                                contact: {

                                }
                            }
                        },
                        release: {
                            code: null
                        },
                        additional: {
                            code: null
                        },
                    },
                    returnInfo: {
                        serviceGroup: {
                            serviceGroupID: null,
                            code: null,
                            text: null
                        },
                        carrier: {
                            serviceProviderAccountID: null,
                            code: null,
                            text: null
                        },
                        product: {
                            serviceProviderProductAccountID: null,
                            code: null,
                            text: null,
                            configuration: null
                        },
                        option: {
                            serviceProviderOptionAccountID: null,
                            code: null,
                            text: null,
                        },
                        accesspoint: {
                            serviceProviderAccessPointAccountID: null,
                            code: null,
                            text: null,
                            record: null,
                            alternateDelivery: {
                                enable: false,
                                address: {
                                    address1: null,
                                    address2: null,
                                    address3: null,
                                    city: null,
                                    state: null,
                                    postalCode: null,
                                    countryID: null,
                                    companyName: null,
                                    establishmentType: null,
                                    fullDescription: null
                                },
                                contact: {

                                }
                            }

                        },
                        release: {
                            code: null
                        },
                        additional: {
                            code: null
                        },
                        providers: {
                            isBinded: false,
                            result: []
                        },
                        services: {
                            isBinded: false,
                            result: [],
                        },
                        productServices: [],
                        options: [],
                        accesspointLocations: [],
                    }
                },
                carrier: {
                    code: null,
                    text: null,
                },
                source: {
                    shippingInfo: {
                        serviceGroupDS: {
                            result: []
                        },
                        providers: {
                            isBinded: false,
                            result:[]
                        },
                        services: {
                            isBinded: false,
                            result: [],
                        },
                        productServices: [],
                        accesspointLocations: [],
                    },
                    returnInfo: {
                        providers: {
                            isBinded: false,
                            result: []
                        },
                        services: {
                            isBinded: false,
                            result: [],
                        },
                        productServices: [],
                        options: [],
                        accesspointLocations: [],
                    }
                    ,companyInfo: {
                        generalSetting: null,
                        accountCustomer: []
                    }
                },
                dialog: {
                    allocate: {
                        show: false
                    }
                },
                action: {
                    processing: false
                },
                processing: {
                    shipping: false,
                    return: false,
                    shippingInfo: {
                        servicegroup: false,
                        provider: false,
                        product: false,
                        accesspoint: false
                    },
                    returnInfo: {
                        provider: false,
                        product: false,
                        accesspoint: false

                    },
                    companyInfo: {
                        generalSetting: false,
                        accountCustomer: false
                    }

                },

            }
        },
        validations() {
            let validator = {
                shippingInfoModelValidation: {
                    serviceProviderProductAccountID: { $autoDirty: true },
                    serviceProviderAccessPointAccountID: { $autoDirty: true },
                    serviceProviderAccountID: { $autoDirty: true },
                }
            };
            if ((this.model.shippingInfo.carrier.error?.length ?? 0) > 0)
                validator.shippingInfoModelValidation.serviceProviderAccountID = { $autoDirty: true, regex: helpers.withMessage(this.model.shippingInfo.carrier.error, regex(this.model.shippingInfo.carrier.error)) };
            if ((this.model.shippingInfo.product.error?.length ?? 0) > 0)
                validator.shippingInfoModelValidation.serviceProviderProductAccountID = { $autoDirty: true, regex: helpers.withMessage(this.model.shippingInfo.product.error, regex(this.model.shippingInfo.product.error)) };
            if ((this.model.shippingInfo.accesspoint.error?.length ?? 0) > 0)
                validator.shippingInfoModelValidation.serviceProviderAccessPointAccountID = { $autoDirty: true, regex: helpers.withMessage(this.model.shippingInfo.accesspoint.error, regex(this.model.shippingInfo.accesspoint.error)) };
            return validator;
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'customerID': {
                handler() {
                    this.$nextTick(() => {
                        this.getServiceGroup();
                       // this.onReset();
                    });
                }, deep: true
            },
            //'formData.packages': {
            //    handler() {
            //        this.onReset();
            //    }, deep: true
            //},
            //'formData.isReturnable': {
            //    handler() {
            //        this.rebindAllocationCarriers();
            //    }, deep: true
            //},
            //'source.returnInfo.carrier.code': {
            //    handler() {
            //        this.$nextTick(() => {
            //            if (this.source.returnInfo.carrier.code != null) {
            //                this.getConsignmentService(true).then(() => {
            //                    this.source.returnInfo.services.isBinded = true;
            //                });

            //            }
            //        });
            //    }, deep: true
            //},
            //'source.returnInfo.product.code': {
            //    handler() {
            //        this.$nextTick(() => {
            //            this.source.returnInfo.accesspointLocations = [];
            //            if (this.source.returnInfo.product.code != null) {
            //                this.getAccessPoints(true);
            //            }
            //        });
            //    }, deep: true
            //},
            'model.shippingInfo.carrier.code': {
                handler() {
                    this.$nextTick(() => {
                        if (this.ShippingCarrierFilter != null && this.ShippingCarrierFilter != "") {
                            this.getConsignmentService(false);
                        }
                        else {
                            this.onProduct_ShippingInfoReset();
                        }
                    });
                }, deep: true
            },
            'model.shippingInfo.product.code': {
                handler() {
                    this.$nextTick(() => {
                        this.source.shippingInfo.accesspointLocations = [];
                        if (this.ShippingProductFilter != null) {
                            this.getAccessPoints(false);
                        }
                    });
                }, deep: true
            },
            'model.collectionDateValue': {
                handler() {
                    this.rebindAllocationCarriers();
                }, deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            //companySettingRecord() {
            //    return this.source.companyInfo.generalSetting;
            //},
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.lookupSource?.customers ?? [];
            },
            scopeWarehouseDS() {
                return this.lookupSource?.warehouses ?? [];
            },
            warehouse() {
                return this.scopeWarehouseDS.filter(x => x.id == this.warehouseID)[0];
            },
            OriginCountry() {
                return this.$vm.$countries.filter(x => x.id === this.originAddress?.countryID)[0];
            },
            OriginISO2() {
                return this.OriginCountry?.code;
            },
            DestinationCountry() {
                return this.$vm.$countries.filter(x => x.id === this.destinationAddress?.countryID)[0];
            },
            DestinationISO2() {
                return this.DestinationCountry?.code;
            },
            isDomestic() {
                return this.$filters.isDomestic(this.OriginISO2, this.model?.originAddress.postalCode ?? '', this.DestinationISO2, this.destinationAddress?.postalCode ?? '');
            },
            columnNo() {
                let isActive = 0;
                if ((this?.isReturnable ?? false))
                    isActive++;
                if ((this.model?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false))
                    isActive++;
                if ((this.model?.returnInfo?.accesspoint?.alternateDelivery?.enable ?? false))
                    isActive++;
                return (isActive > 1) ? ((isActive > 2) ? "6" : "6"): "6";
            },
            isValidAllocate() {                
                return (
                    (((this.model?.shippingInfo.carrier?.code?.length ?? 0) == 0) ? ((this.model?.shippingInfo?.serviceGroup.code?.length ?? 0) > 0) : false) ||
                    ((this.model?.shippingInfo.carrier?.code?.length ?? 0) > 0 ? (((this.model?.shippingInfo.carrier?.code?.length ?? 0) > 0) && ((this.model?.shippingInfo?.product?.code?.length ?? 0) > 0) && ((this.model?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.model?.shippingInfo.option?.code?.length ?? 0) > 0) : true) && ((this.model?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? ((this.model?.shippingInfo.additional?.code ?? "").length > 0) : true)) : false)
                )
                    &&
                    (
                        (this.isReturnable ?? false) ?
                            (
                                (this.model?.returnInfo.carrier?.code?.length ?? 0) > 0 ?
                                    (((this.model?.returnInfo.carrier?.code?.length ?? 0) > 0) && ((this.model?.returnInfo?.product?.code?.length ?? 0) > 0) && ((this.model?.returnInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.model?.returnInfo.option?.code?.length ?? 0) > 0) : true) && ((this.model?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? ((this.model?.returnInfo.additional?.code ?? "").length > 0) : true))
                                    : true)
                            : true

                    ) && this.isValid;

                
            },
            cdnUrl() {
                return this.$config.config.endpoint.cdn;
            },
            //SHIPPING INFO DROPDOWN OBJECTS
            shippingInfoServiceGroupDS() {
                return this.source.shippingInfo.serviceGroupDS.result ?? [];
            },
            shippingInfoCarrierDS() {
                return this.source.shippingInfo.providers.result ?? [];
            },
            shippingInfoProductDS() {
                if (this.ShippingCarrierFilter !== null)
                    return this.source.shippingInfo.services.result.filter(x => x.CarrierCode === this.ShippingCarrierFilter)[0]?.AvailableServices ?? [];
                let availalbleService = [];
                (this.source.shippingInfo.services.result ?? []).forEach(x => {
                    (x.AvailableServices ?? []).forEach(av => {
                        availalbleService.push(av);
                    })
                });
                return availalbleService;
            },
            shipingInfoAccessPointDS() {
                return this.source.shippingInfo.accesspointLocations ?? [];
            },
            shippingInfoOptionDS() {
                if (this.ShippingCarrierFilter !== null && this.ShippingProductFilter !== null)
                    return this.source.shippingInfo.services.result.filter(x => x.CarrierCode === this.ShippingCarrierFilter)[0]?.AvailableOptions ?? [];
                return [];
            },
            //shippingInfoAlternateAddress() {
            //    return {
            //        companyName: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName,
            //        address1: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
            //        address2: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
            //        address3: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
            //        city: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
            //        state: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
            //        postalCode: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
            //        countryID: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID,
            //        establishmentType: 200

            //    };
               
            //},

            // RETURN INFO DROPDWON OBJECTS
            returnInfoCarrierDS() {
                return this.source.returnInfo.providers.result ?? [];
            },
            returnInfoProductDS() {
                if (this.ReturnCarrierFilter !== null)
                    return this.source.returnInfo.services.result.filter(x => x.CarrierCode === this.ReturnCarrierFilter)[0]?.AvailableServices ?? [];
                let availalbleService = [];
                (this.source.returnInfo.services.result ?? []).forEach(x => {
                    (x.AvailableServices ?? []).forEach(av => {
                        availalbleService.push(av);
                    })
                });
                return availalbleService;
            },
            returnInfoAccessPointDS() {
                return this.source.returnInfo.accesspointLocations ?? [];
            },
            returnInfoOptionDS() {
                if (this.ReturnCarrierFilter !== null && this.ReturnProductFilter !== null)
                    return this.source.returnInfo.services.result.filter(x => x.CarrierCode === this.ReturnCarrierFilter)[0]?.AvailableOptions ?? [];
                return []
                
                //let availableOption = [];
                //(this.source.returnInfo.services.result ?? []).forEach(x => {
                //    (x.AvailableOptions ?? []).forEach(av => {
                //        availableOption.push(av);
                //    })
                //});
                //return availableOption;
            },
            returnInfoAlternateAddress() {
                return {
                    companyName: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                    address1: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.address1,
                    address2: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.address2,
                    address3: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.address3,
                    city: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.city,
                    state: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                    postalCode: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                    countryID: this.model?.returnInfo?.accesspoint?.alternateDelivery?.address?.countryID,
                    establishmentType: 200
                };

            },
            customer() {
                return this.scopeCustomerDS.filter(x => x.id == (this.model?.customerID ?? this.$props?.formData?.customerID ?? this.$props?.recordformData?.customerID))[0];
            },
            country() {
                return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipFrom_CountryID)[0];
            },
            consignmentRecord() { return this.$store.getters["consignment/record"]; },
            ReturnCarrierFilter() { return this.model.returnInfo.carrier.code ?? null },
            ReturnProductFilter() { return this.model.returnInfo.product.code ?? null },
            ShippingCarrierFilter() { return this.model.shippingInfo.carrier.code ?? null },
            ShippingProductFilter() { return this.model.shippingInfo.product.code ?? null },
            accespointAddress() {
                let _selectedAccessPoint = (this.shipingInfoAccessPointDS ?? []).filter(x => x.Code === this.model?.shippingInfo?.accesspoint?.code)[0];
                let _address = _selectedAccessPoint?.Address;
                let _fullAddress = null;
                let _accesspointAddress = [];
                if (_address != null && _address !== undefined) {
                    _fullAddress = (_address?.Address1 ?? "") + " " + (_address?.Address2 ?? "") + (_address?.Address3 ?? "");
                    // Loop through the input string with a step size of 35
                    if (_fullAddress) {
                        for (let i = 0; i < _fullAddress.length; i += 35) {
                            // Use substring to get the next 35 characters and push it to the result array
                            _accesspointAddress.push(_fullAddress.substring(i, i + 35));
                        }
                    }
                }
                return _accesspointAddress;
            },
            //apiManageConsignment() {
            //    let packages = [];
            //    (this.model?.packages ?? []).forEach((_package) => {
            //        let _items = [];
            //        (_package?.items ?? []).forEach(_item => {
            //            _items.push({
            //                Product: {
            //                    Code: _item?.code ?? null,
            //                    Name: _item?.name ?? null,
            //                    Attribute: _item?.attribute ?? null,
            //                    SKU: _item?.sku ?? null,
            //                    GTIN: _item?.gtin ?? null,
            //                    Image: ((_item?.image?.length ?? 0) > 0) ? {
            //                        Type: "IMAGE",
            //                        FileURL: this.cdnUrl + '/' + _item.image[0].url,
            //                        //Base64File: _item.image[0].file,
            //                        FileName: _item.image[0].filename,
            //                        ContentType: _item.image[0]?.contentType
            //                    } : null
            //                },
            //                Commodity: ((_item?.htsCode ?? null) !== null || (_item?.manufacturerCountryID > 0)) ? {
            //                    HSCode: _item?.htsCode ?? null,
            //                    Country: this.$vm.$countries.filter(x => x.id === _item?.manufacturerCountryID ?? -100)[0]?.code ?? null
            //                } : null,
            //                Quantity: (_item?.quantity ?? 0),
            //                Weight: {
            //                    GrossWeight: _item.weight,
            //                    NetWeight: _item.weight//,
            //                    //VolumetricWeight: 0.00,
            //                },
            //                Declaration: {
            //                    DeclaredValue: _item?.consignmentValue ?? 0.00,
            //                    CustomsValue: _item?.customsValue ?? 0.00,
            //                    DiscountValue: _item?.discountValue ?? 0.00,
            //                    InsuranceValue: _item?.insuranceValue ?? 0.00,
            //                    DutyPaidValue: _item?.dutyValue ?? 0.00,
            //                    TaxValue: _item?.taxValue ?? 0.00,
            //                    TaxRate: _item?.taxRate ?? 0.00
            //                },
            //                Dimension: (((_item?.length ?? 0) > 0) || ((_item?.width ?? 0) > 0) || ((_item?.height ?? 0) > 0) || ((_item?.volume ?? 0) > 0)) ? {
            //                    Length: (_item?.length ?? 0),
            //                    Width: (_item?.width ?? 0),
            //                    Height: (_item?.height ?? 0),
            //                    VolumeCBM: (_item?.volume ?? 0),
            //                    VolumeAutoCompute: (_item?.volumeAutoCompute ?? true)
            //                } : null,
            //                HazMatForm: ((this.model?.isHazardous ?? false) && (_item?.dG_HazmatID > 0)) ? {
            //                    HazMatInfo: {
            //                        ContentID: "DG" + _item?.dG_HazmatID.toString(),
            //                        UNCode: _item?.dG_UnCode ?? null,
            //                    },
            //                    DryIceInfo: _item?.dG_HazmatID === 1202 ? {
            //                        NetWeight: _item?.dG_DryIceNetWeight,
            //                        WeightUnit: this.$vm.$enums['dg_weightunit'].filter((x) => x.id == _item?.dG_DryIceUnitWeight)[0]?.code ?? null
            //                    } : null,
            //                    BatteryInfo: null,//{ BatteryType: "" },
            //                    RegulatedCode: this.$vm.$enums['dg_regulationtype'].filter((x) => x.id == _item?.dG_RegulatedCode)[0]?.code ?? null
            //                } : null
            //            })
            //        });
            //        //if ()
            //        packages.push({
            //            CartonNumber: _package.code,
            //            Weight: {
            //                GrossWeight: _package?.weight ?? 0.00,
            //                NetWeight: _package.netWeight ?? 0.00,
            //                VolumetricWeight: 0.00
            //            },
            //            Declaration: {
            //                DeclaredValue: _package?.consignmentValue ?? 0.00,
            //                CustomsValue: _package?.customsValue ?? 0.00,
            //                DiscountValue: _package?.discountValue ?? 0.00,
            //                InsuranceValue: _package?.insuranceValue ?? 0.00,
            //                DutyPaidValue: _package?.dutyValue ?? 0.00,
            //                TaxValue: _package?.taxValue ?? 0.00,
            //                TaxRate: _package?.taxRate ?? 0.00
            //            },
            //            Dimension: (((_package?.length ?? 0) > 0) || ((_package?.width ?? 0) > 0) || ((_package?.height ?? 0) > 0) || ((_package?.volume ?? 0) > 0)) ? {
            //                Length: (_package?.length ?? 0),
            //                Width: (_package?.width ?? 0),
            //                Height: (_package?.height ?? 0),
            //                VolumeCBM: (_package?.volume ?? 0),
            //                VolumeAutoCompute: (_package?.volumeAutoCompute ?? true)

            //            } : null,
            //            AdditionalReferences: (_package?.ssccNumber) ? {
            //                SSCCNumber: _package.ssccNumber,
            //            } : null,
            //            AdditionalHandling: this.$vm.$enums['packageadditionalhandlingtype'].filter(x => x.id === _package?.additionalHandling ?? -100)[0]?.code ?? null,
            //            Customs: (((_package?.name ?? "").length > 0) || ((_package?.cpcNumber ?? "").length > 0)) ? {
            //                Content: ((_package?.name ?? "").length > 0) ? _package?.name : null,
            //                CPCNumber: ((_package?.cpcNumber ?? "").length > 0) ? _package?.cpcNumber : null
            //            } : null,
            //            Markings: {
            //                IsPallet: _package?.isPallet ?? false,
            //                IsFragile: _package?.isFragile ?? false,
            //                HasLiquid: _package?.hasLiquid ?? false,
            //                UseTailLift: _package?.useTailLift ?? false
            //            },
            //            Items: _items.length > 0 ? _items : null
            //        });
            //    });
            //    var senderCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]);
            //    var recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);

            //    return {
            //        Source: "Web",
            //        Request: {
            //            TradeType: this.getTradeType(),
            //            Customer: (this.model?.customerCode ?? this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code ?? null),
            //            Warehouse: this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0]?.code,
            //            Sender: {
            //                Address: {
            //                    Company: this.model?.shipFrom_Company ?? "",
            //                    Address1: this.model?.shipFrom_Address1 ?? "",
            //                    Address2: this.model?.shipFrom_Address2 ?? "",
            //                    Address3: this.model?.shipFrom_Address3 ?? "",
            //                    City: this.model?.shipFrom_City ?? "",
            //                    //State: this.model?.shipFrom_State ?? "",
            //                    State: (senderCountry?.stateRequired ?? false) ? ((senderCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipFrom_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipFrom_State ?? "")) : (this.model?.shipFrom_State ?? ""),
            //                    PostalCode: this.model.shipFrom_PostalCode ?? "",
            //                    Country: this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]?.code ?? "",
            //                    EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipFrom_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS",
            //                    AtRisk: this.model.shipFrom_AtRisk ?? false,
            //                },
            //                Contact: {
            //                    FirstName: this.model?.shipFrom_FirstName ?? "",
            //                    LastName: this.model?.shipFrom_LastName ?? "",
            //                    Email: this.model?.shipFrom_Email ?? "",
            //                    Telephone: this.model?.shipFrom_Telephone ?? "",
            //                    Mobile: this.model?.shipFrom_Mobile ?? "",
            //                },
            //                Identity: {
            //                    VAT: this.model?.shipFrom_TaxID ?? "",
            //                    EORI: this.model?.shipFrom_EORI ?? "",
            //                    IOSS: this.model?.shipFrom_IOSSNumber ?? "",
            //                    UKIMS: this.model?.shipFrom_UKIMS ?? "",
            //                    PersonalID: (this.model?.shipFrom_IDentityNumber) ? {
            //                        Number: this.model?.shipFrom_IDentityNumber ?? "",
            //                        Type: this.$vm.$enums['identitynumbertype'].filter(x => x.id === this.model?.shipFrom_IDentityNumberType ?? -100)[0]?.code ?? "PASSPORT"
            //                    } : null
            //                },
            //                SaveToAddressBook: this.model?.shipFrom_SaveAddressBook ?? false
            //            },
            //            Recipient: {
            //                Address: {
            //                    Company: this.model?.shipTo_Company ?? "",
            //                    Address1: this.model?.shipTo_Address1 ?? "",
            //                    Address2: this.model?.shipTo_Address2 ?? "",
            //                    Address3: this.model?.shipTo_Address3 ?? "",
            //                    City: this.model?.shipTo_City ?? "",
            //                    State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipTo_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipTo_State ?? "")) : (this.model?.shipTo_State ?? ""),
            //                    PostalCode: this.model.shipTo_PostalCode ?? "",
            //                    Country: this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]?.code ?? "",
            //                    EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipTo_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS",
            //                    AtRisk: this.model.shipTo_AtRisk ?? false,
            //                },
            //                Contact: {
            //                    FirstName: this.model?.shipTo_FirstName ?? "",
            //                    LastName: this.model?.shipTo_LastName ?? "",
            //                    Email: this.model?.shipTo_Email ?? "",
            //                    Telephone: this.model?.shipTo_Telephone ?? "",
            //                    Mobile: this.model?.shipTo_Mobile ?? "",
            //                },
            //                Identity: {
            //                    VAT: this.model?.shipTo_TaxID ?? "",
            //                    EORI: this.model?.shipTo_EORI ?? "",
            //                    IOSS: this.model?.shipTo_IOSSNumber ?? "",
            //                    UKIMS: this.model?.shipTo_UKIMS ?? "",
            //                    PersonalID: (this.model?.shipTo_IDentityNumber) ? {
            //                        Number: this.model?.shipTo_IDentityNumber ?? "",
            //                        Type: this.$vm.$enums['identitynumbertype'].filter(x => x.id === this.model?.shipTo_IDentityNumberType ?? -100)[0]?.code ?? "PASSPORT" //identitynumbertype
            //                    } : null
            //                },
            //                SaveToAddressBook: this.model?.shipTo_SaveAddressBook ?? false
            //            },
            //            TransactionID : this.model?.code,
            //            Order: {
            //                Reference1: (!(this.model?.autoNumber ?? true) || (this.model?.customerReference ?? "").length > 0) ? this.model?.customerReference : "cosmos-autonumber",
            //                Reference2: this.model?.customerReference2,
            //                Reference3: this.model?.customerReference3,
            //                Reference4: this.model?.customerReference4,
            //                Reference5: this.model?.customerReference5,
            //                Reference6: this.model?.customerReference6,
            //                Reference7: this.model?.customerReference7,
            //                Instruction: this.model?.deliveryInstruction,
            //                SafePlace: this?.model?.safePlace,
            //                PackageType: this.$vm.$enums['serviceproviderproductpackagingtype'].filter(x => x.id === this.model?.packageType ?? -100)[0]?.code ?? "PACKAGE",
            //                Currency: this.$vm.$currencies.filter(x => x.id === this.model?.currencyID ?? -100)[0]?.code ?? "GBP",
            //                WeightUnit: this.$vm.$enums['weightunit'].filter(x => x.id === this.model?.weightUnit ?? -100)[0]?.code ?? "KG",
            //                DimensionUnit: this.$vm.$enums['lengthunit'].filter(x => x.id === this.model?.dimensionUnit ?? -100)[0]?.code ?? "CM",
            //                Notification: {
            //                    SMS: (this.$filters.getFlags(this.model?.notification ?? 0) ?? []).filter(x => x === 1)[0] > 0,
            //                    Email: (this.$filters.getFlags(this.model?.notification ?? 0) ?? []).filter(x => x === 2)[0] > 0,
            //                },
            //                InternationalForm: !this.isDomestic ? {
            //                    Customs: {
            //                        Content: this.model?.packageContent,
            //                        CPCNumber: this.model?.cpcNumber
            //                    },
            //                    Incoterm: this.$vm.$enums['incotermtype'].filter(x => x.id === this.model?.incoterm ?? -100)[0]?.code ?? "DDU",
            //                    CustomsCategory: this.$vm.$enums['customscategorytype'].filter(x => x.id === this.model?.customsCategoryType ?? -100)[0]?.code ?? "SAL",
            //                    ExportReason: this.$vm.$enums['exportreasontype'].filter(x => x.id === this.model?.exportReason ?? -100)[0]?.code ?? "SAL"
            //                } : null,
            //                InvoiceForm: {
            //                    Type: this.$vm.$enums['invoicetype'].filter(x => x.id === this.model?.invoiceType ?? -100)[0]?.code ?? "COMMERCIAL",
            //                    Date: this.$filters.formatDateWithFormat(this.model.invoiceDate ?? new Date(), "YYYY-MM-DD"),
            //                    Reference: this.model?.invoiceNumber ?? null,
            //                    Notes: this.model?.invoiceNote ?? null,
            //                    ShippingCost: this.model?.shippingCost ?? 0
            //                },
            //                HazMatContactInfo: (this.model?.isHazardous ?? false) ? {
            //                    Contact: this.model?.dG_EmergencyContact,
            //                    Telephone: this.model?.dG_EmergencyTelephone
            //                } : null,
            //                Packages: packages
            //            }
            //        }
            //    };
            //},
            //apiAllocateConsignment() {
            //    let _accesspointAddress = this.accespointAddress;
            //    return {
            //        Source: "WEB",
            //        Request: {
            //            TransactionID: this?.model?.code,
            //            ReferenceOnly: true,
            //            BookingForm: {
            //                ShippingInfo: {
            //                    PickupInfo: {
            //                        Type: this.$vm.$enums['pickuptype'].filter(x => x.id === this.model?.pickupType ?? -100)[0]?.code ?? "DROPOFF",
            //                        Date: this.$filters.formatDateWithFormat(this.model.request_CollectionDate, "YYYY-MM-DD"),
            //                        ReadyTime: this.$filters.formatDateWithFormat(this.model.request_ReadyTime, "HH:mm"),
            //                        ClosingTime: this.$filters.formatDateWithFormat(this.model.request_ClosingTime, "HH:mm"),
            //                    },
            //                    ServiceGroup: this.source?.shippingInfo?.serviceGroup?.code,
            //                    ProviderInfo:  this.source?.shippingInfo?.carrier?.code !== null ? {
            //                        Carrier: this.source?.shippingInfo?.carrier?.code,
            //                        Service: this.source?.shippingInfo?.product?.code,
            //                        Option: this.source?.shippingInfo?.option?.code
            //                    } : null,
            //                    AccessPointInfo: (this.source?.shippingInfo?.accesspoint?.code || (this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)) ? {
            //                        Code: !(this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? this.source?.shippingInfo?.accesspoint?.code : null,
            //                        ReleaseCode: this.source?.shippingInfo?.release?.code,
            //                        Address: !(this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? {
            //                            Company: this.source?.shippingInfo?.accesspoint?.record?.CompanyName,
            //                            //Address1: this.source?.shippingInfo?.accesspoint?.record?.Address?.Address1,
            //                            //Address2: this.source?.shippingInfo?.accesspoint?.record?.Address?.Address2,
            //                            //Address3: this.source?.shippingInfo?.accesspoint?.record?.Address?.Address3,
            //                            Address1: _accesspointAddress[0],
            //                            Address2: _accesspointAddress.length > 1 ? _accesspointAddress[1] : null,
            //                            Address3: _accesspointAddress.length > 2 ? _accesspointAddress[2] : null,
            //                            City: this.source?.shippingInfo?.accesspoint?.record?.Address?.City,
            //                            State: null,
            //                            PostalCode: this.source?.shippingInfo?.accesspoint?.record?.Address?.PostalCode,
            //                            Country: this.source?.shippingInfo?.accesspoint?.record?.Address?.Country
            //                        } : {
            //                                Company: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName,
            //                                Address1: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
            //                                Address2: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
            //                                Address3: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
            //                                City: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
            //                                State: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
            //                                PostalCode: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
            //                                Country: this.$vm.$countries.filter(x => x.id === this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID)[0]?.code
            //                            }
            //                    } : null,
            //                    GenerateCustomsData: (this.source?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false) ? (this.source?.shippingInfo?.customization?.generateCustomsData ? "Y" : "N") : null,
            //                    AdditionalCode: (this.source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ?  this.source?.shippingInfo?.additional?.code : null
            //                },
            //                IsReturnable: (this.model?.isReturnable ?? false),
            //                ReturnsInfo: ((this.model?.isReturnable ?? false) && (this.source?.returnInfo?.carrier?.code !== null)) ? {
            //                    ProviderInfo: this.source?.returnInfo?.carrier?.code !== null ?{
            //                        Carrier: this.source?.returnInfo?.carrier?.code,
            //                        Service: this.source?.returnInfo?.product?.code,
            //                        Option: this.source?.returnInfo?.option?.code,
            //                    } : null,
            //                    Address: null,
            //                    AccessPointInfo: null,
            //                    //AccessPointInfo: this.source?.returnInfo?.accesspoint?.code ? {
            //                    //    Code: this.source?.returnInfo?.accesspoint?.code,
            //                    //    ReleaseCode: this.source?.returnInfo?.release?.code,
            //                    //    Address: null
            //                    //} : null,
            //                    AdditionalCode: (this.source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? this.source?.returnInfo?.additional?.code : null
            //                } : null
            //            },
            //            GenerateForm: !this.isDomestic ? {
            //                Invoice: true,
            //                Customs: [
            //                    {
            //                        Type: "CN22",
            //                        Customs: "CONSIGNMENT"
            //                    }
            //                ]
            //            } : null,
            //        }
            //    }
            //},
            apiServiceRequest() {
                let packages = [];
                // let _content = [];
                (this?.model?.packages ?? this.packages ?? []).forEach((_package) => {
                    packages.push({
                        Weight: _package?.weight ?? 0.00,
                        Declaration: {
                            DeclaredValue: _package?.consignmentValue ?? 0.00,
                            CustomsValue: _package?.customsValue ?? 0.00,
                            DiscountValue: _package?.discountValue ?? 0.00,
                            InsuranceValue: _package?.insuranceValue ?? 0.00,
                            DutyPaidValue: _package?.dutyPaidValue ?? 0.00,
                            TaxValue: _package?.taxValue ?? 0.00,
                        },
                        Dimension: (((_package?.length ?? 0) > 0) || ((_package?.width ?? 0) > 0) || ((_package?.height ?? 0) > 0)) ? {
                            Length: (_package?.length ?? 0),
                            Width: (_package?.width ?? 0),
                            Height: (_package?.height ?? 0)
                        } : null,
                    });
                });
                var senderCountry = (this.$vm.$countries.filter(x => x.id === this.originAddress?.countryID ?? -100)[0]);
                var recipientCountry = (this.$vm.$countries.filter(x => x.id === this.destinationAddress?.countryID ?? -100)[0]);

                return {
                    Source: "WEB",
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this.customerID)[0]?.code,
                        Warehouse: this.warehouse?.code,
                        ShippingType: "SHIPPING",
                        Consignment: {
                            Order: {
                                Sender: {
                                    City: this.originAddress?.city ?? "",
                                    State: (senderCountry?.stateRequired ?? false) ? ((senderCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.originAddress?.state ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.originAddress?.state ?? "")) : (this.originAddress?.state ?? ""),
                                    PostalCode: this.originAddress?.postalCode ?? "",
                                    Country: senderCountry?.code ?? "",
                                    EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.originAddress?.establishmentType ?? 300)[0]?.code ?? "BUSINESS"
                                },
                                Recipient: {
                                    City: this.destinationAddress?.city ?? "",
                                    State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.destinationAddress?.state ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.destinationAddress?.state ?? "")) : (this.destinationAddress?.state ?? ""),
                                    PostalCode: this.destinationAddress?.postalCode ?? "",
                                    Country: recipientCountry?.code ?? "",
                                    EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.destinationAddress?.establishmentType ?? 300)[0]?.code ?? "BUSINESS"
                                },
                                PackageType: this.$vm.$enums['serviceproviderproductpackagingtype'].filter(x => x.id === this.packageType ?? -100)[0]?.code ?? "PACKAGE",
                                CollectionDate: this.$filters.formatDateWithFormat(this.model?.collectionDate ?? new Date(), "YYYY-MM-DD"),
                                WeightUnit: this.$vm.$enums['weightunit'].filter(x => x.id === this.weightUnit)[0]?.code ?? "KG",
                                DimensionUnit: this.$vm.$enums['lengthunit'].filter(x => x.id === this.lengthUnit)[0]?.code ?? "CM",
                                Currency: this.$vm.$currencies.filter(x => x.id === this.model?.currencyID ?? -100)[0]?.code ?? "GBP",
                            },
                            Packages: packages
                        },
                        Provider: {
                            Carrier: null,
                            Service: null
                        }
                    }
                }
            },
            apiProviderRequest() {
                return {
                    Source: "WEB",
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this.customerID)[0]?.code,
                        Warehouse: this.warehouse?.code,
                        ShippingType: "SHIPPING",
                        TradeType: this.$vm.$enums['tradetype'].filter(x => x.id === this?.tradeType ?? -100)[0]?.code ?? "EXPORT",
                        SenderCountry: this.$vm.$countries.filter(x => x.id === this.originAddress?.countryID ?? -100)[0]?.code ?? "",
                        RecipientCountry: this.$vm.$countries.filter(x => x.id === this.destinationAddress?.countryID ?? -100)[0]?.code ?? "",
                    }
                }
            },
            apiAccessPointRequest() {
                var recipientCountry = (this.$vm.$countries.filter(x => x.id === this.destinationAddress?.countryID ?? -100)[0]);
                return {
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this?.customerID)[0]?.code,
                        Carrier: "",
                        Address: {
                            Company: this.destinationAddress?.company ?? "",
                            Address1: this.destinationAddress?.address1 ?? "",
                            Address2: this.destinationAddress?.address2 ?? "",
                            Address3: this.destinationAddress?.address3 ?? "",
                            City: this.destinationAddress?.city ?? "",
                            State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.destinationAddress?.state ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.destinationAddress?.state ?? "")) : (this.destinationAddress?.state ?? ""),
                            PostalCode: this.destinationAddress.postalCode ?? "",
                            Country: recipientCountry?.code ?? ""
                        },
                        MaxDistance: 150,
                        DistanceUnit: "KM",
                        MaxResult: 100
                    }
                }
            },
            apiAllocateSalesOrder() {
                    let _selectedAccessPoint = (this.shipingInfoAccessPointDS ?? []).filter(x => x.Code === this.model?.shippingInfo?.accesspoint?.code)[0];
                    let _accesspointAddress = this.accespointAddress;
                    let _alternateAddressCountry = this.$vm.$countries.filter(x => x.id === this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID)[0]
                    var _recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);
                    return {
                        ShippingInfo: {
                            PickupInfo: {
                                Date: this.$filters.formatDateWithFormat(this.model.collectionDate, "YYYY-MM-DD"),
                                ReadyTime: this.$filters.formatDateWithFormat(this.model.readyTime, "HH:mm"),
                                ClosingTime: this.$filters.formatDateWithFormat(this.model.closingTime, "HH:mm"),
                            },
                            ServiceGroup: this.model?.shippingInfo?.serviceGroup?.code,
                            ProviderInfo: this.model?.shippingInfo?.carrier?.code !== null ? {
                                Carrier: this.model?.shippingInfo?.carrier?.code,
                                Service: this.model?.shippingInfo?.product?.code,
                                Option: this.model?.shippingInfo?.option?.code
                            } : null,
                            AccessPointInfo: (this.model?.shippingInfo?.accesspoint?.code || (this.model?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false)) ? {
                                Code: !(this.model?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? this.model?.shippingInfo?.accesspoint?.code : null,
                                ReleaseCode: this.model?.shippingInfo?.release?.code,
                                Address: !(this.model?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false) ? {
                                    Company: _selectedAccessPoint?.CompanyName,
                                    Address1: _accesspointAddress[0],
                                    Address2: _accesspointAddress.length > 1 ? _accesspointAddress[1] : null,
                                    Address3: _accesspointAddress.length > 2 ? _accesspointAddress[2] : null,
                                    City: _selectedAccessPoint?.Address?.City,
                                    State: (_recipientCountry?.stateRequired ?? false) ? ((_recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (_selectedAccessPoint?.Address?.State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (_selectedAccessPoint?.Address?.State ?? "")) : (_selectedAccessPoint?.Address?.State ?? ""),
                                    PostalCode: _selectedAccessPoint?.Address?.PostalCode,
                                    Country: _selectedAccessPoint?.Address?.Country
                                } : {
                                    Company: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.company,
                                        Address1: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
                                        Address2: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
                                        Address3: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
                                        City: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
                                        //State: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                                        State: (_alternateAddressCountry?.stateRequired ?? false) ? ((_alternateAddressCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "")) : (this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? ""),
                                        PostalCode: this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                                        Country: this.$vm.$countries.filter(x => x.id === this.model?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID)[0]?.code
                                }
                            } : null,
                            GenerateCustomsData: (this.model?.shippingInfo?.product?.configuration?.GenerateCustomsDataFlag ?? false) ? (this.model?.shippingInfo?.customization?.generateCustomsData ? "Y" : "N") : null,
                            AdditionalCode: (this.model?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? this.model?.shippingInfo?.additional?.code : null
                        },
                        IsReturnable: (this.isReturnable ?? false),
                        ReturnsInfo: ((this.isReturnable ?? false) && (this.model?.returnInfo?.carrier?.code !== null)) ? {
                            ProviderInfo: this.model?.returnInfo?.carrier?.code !== null ? {
                                Carrier: this.model?.returnInfo?.carrier?.code,
                                Service: this.model?.returnInfo?.product?.code,
                                Option: this.model?.returnInfo?.option?.code,
                            } : null,
                            Address: null,
                            AccessPointInfo: null,
                            AdditionalCode: (this.model?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? this.model?.returnInfo?.additional?.code : null
                        } : null
                    }
            },
            soAllocateHeader() {
                return {
                    headers: {
                        "x-client-id": 'mpci_' + this.salesOrderParameter?.environment?.mask, //the token is a variable which holds the token
                        "x-client-secret": 'mpcs_' + this.salesOrderParameter?.customer?.mask?.toLowerCase(), //the token is a variable which holds the token
                        "x-auth-token": 'mpat_' + this.sessionKeys?.currentUser//the token is a variable which holds the token
                    }
                };
            }
        },
        methods: {
            getTradeType() {
                var warehouse = this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0];
                var warehouseAddress = (warehouse.address1 ?? "") + "" + (warehouse.address2 ?? "") + "" + (warehouse.address3 ?? "") + "" + (warehouse.city ?? "") + "" + "" + warehouse.postalCode + "" + warehouse.countryID;
                warehouseAddress = warehouseAddress.replace(/^a-zA-Z0-9 ]/g, '');
                var recipientAddress = (this.model?.shipTo_Address1 ?? "") + "" + (this.model?.shipTo_Address2 ?? "") + "" + (this.model?.shipTo_Address3 ?? "") + "" + (this.model?.shipTo_City ?? "") + "" + this.model?.shipTo_PostalCode + "" + this.model?.shipTo_CountryID;
                recipientAddress = recipientAddress.replace(/^a-zA-Z0-9 ]/g, '');
                var senderAddress = (this.model?.shipFrom_Address1 ?? "") + "" + (this.model?.shipFrom_Address2 ?? "") + "" + (this.model?.shipFrom_Address3 ?? "") + "" + (this.model?.shipFrom_City ?? "") + "" + this.model?.shipFrom_PostalCode + "" + this.model?.shipFrom_CountryID;
                senderAddress = senderAddress.replace(/^a-zA-Z0-9 ]/g, '');

                if (senderAddress !== warehouseAddress && recipientAddress !== warehouseAddress)
                    return "THIRDCOUNTRY";
                else if (senderAddress !== warehouseAddress && recipientAddress === warehouseAddress)
                    return "IMPORT";
                else
                    return "EXPORT";
            },
            getSetting(mask) {
                if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null) {
                    let isProcessing = false;
                    if (this.customer?.id !== this.companySettingRecord?.companyID)
                        isProcessing = (this.processing.companyInfo.generalSetting ?? false);
                    if (!isProcessing) {
                        this.processing.companyInfo.generalSetting = true;
                        this.$nextTick(() => {
                            this.$axios
                                .get(this.$config.config.endpoint.api + `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this?.customer?.mask)}`.replaceAll("//", "/"))
                                .then((response) => {
                                    this.source.companyInfo.generalSetting = response?.data?.result ?? response?.result ?? null;
                                    this.processing.companyInfo.generalSetting = false;
                                    this.manageDefaultSetting();
                                })
                                .catch((ex) => {
                                    this.errors = ex.data;
                                    this.processing.companyInfo.generalSetting = false;
                                });
                        });
                    }
                }
            },
            getAccountCustomer() {
                let isProcessing = false;
                isProcessing = (this.processing.accountCustomer ?? false);
                if (!isProcessing) {
                    this.$nextTick(() => {
                        if ((this.customer?.id ?? 0) != 0) {
                            this.processing.accountCustomer = true;
                            this.$axios.get(this.$config.config.endpoint.api + `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.customer?.id}`.replaceAll("//", "/")).then((result) => {
                                this.source.companyInfo.accountCustomer = result?.data?.result ?? result?.result ?? [];
                                this.processing.accountCustomer = false;
                            });
                        }
                        else {
                            this.processing.accountCustomer = false;
                        }
                    });
                }
            },
            async getEntityRecord(mask) { return await this.$store.dispatch("consignment/getRecord", mask); },
            async getEntityRecordSalesOrder(mask) { return await this.$store.dispatch("salesorder/getRecord", mask); },

            async allocate() {
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/` + this?.model?.code + `/allocate`.replaceAll("//", "/"), this.apiAllocateConsignment)
            },
            async manage() {
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment`.replaceAll("//", "/"), this.apiManageConsignment)
            },
            async consignmentService(shippingType) {
                if (shippingType === null || shippingType === undefined || shippingType === '')
                    shippingType = "SHIPPING";
                let apiRequest = this.apiServiceRequest;
                apiRequest.Request.ShippingType = shippingType;
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/service`.replaceAll("//", "/"), apiRequest)
            },
            async allocate_SO() {
                return await this.$axios.post(this.$config.config.endpoint.api + `/jobs/` + this?.salesOrderParameter?.cart?.mask + `/so-` + this?.salesOrderParameter?.salesOrder?.mask + ``.replaceAll("//", "/"), this.apiAllocateSalesOrder, this.soAllocateHeader)
            },
            onCollectionDate(e) {
                this.model.collectionDateValue = e.value;
                this.model.collectionDate = (e.record.data ?? new Date());
            },
            onReadyTime(e) {
                this.model.readyTimeValue = e.value;
                this.model.readyTime = (e.record.data ?? new Date());
            },
            onClosingTime(e) {
                this.model.closingTimeValue = e.value;
                this.model.closingTime = (e.record.data ?? new Date());
            },
             manageDefaultSetting() {
                this.$nextTick(() => {
                    let _setting = this.companySettingRecord;
                    if (_setting != null) {
                        if (this.source.shippingInfo.serviceGroup.serviceGroupID == null)
                            this.source.shippingInfo.serviceGroup.serviceGroupID = _setting.default_ServiceGroupID;
                        //this.source.shippingInfo.provider.serviceProviderAccountID = _setting.default_AccountCustomerID ?? null;
                        //this.model.serviceGroupID = _setting.default_ServiceGroupID;
                        //this.model.serviceProviderAccountID = _setting.default_AccountCustomerID;
                    }
                });

            },
             getPackages() {
                //if ((this.model?.packages ?? this.packages ?? []).length === 0 && (this.$props.formData?.id ?? 0) > 0) {
                //    this.$axios.get(this.$config.config.endpoint.api + `/services/tms/package/consignment/id/` + this.$props.formData?.id + ''.replaceAll("//", "/"))
                //        .then((response) => {
                //            this.packages = (response?.data?.result ?? null);
                //        })
                //        .catch(() => {
                //            //this.activity.serviceloading = false;
                //            //this.activity.loading = false;
                //        });
                //}
            },
            rebindAllocationCarriers() {
                this.$nextTick(() => {
                    if (this?.model?.collectionDate !== null) {
                        this.$nextTick(() => {
                            this.getConsignmentProvider(false).then(() => {
                                this.$nextTick(() => {
                                    if (this.model.shippingInfo.carrier.code != null) {
                                        this.getConsignmentService(false);
                                    }
                                });
                            });
                        });
                        //this.$nextTick(() => {
                        //    if ((this.model.isReturnable ?? false))
                        //        this.$nextTick(() => {
                        //            this.getConsignmentProvider(true).then(() => {
                        //                this.$nextTick(() => {
                        //                    if (this.source.returnInfo.carrier.code != null) {
                        //                        this.getConsignmentService(true).then(() => {
                        //                            this.source.returnInfo.services.isBinded = true;
                        //                        });
                        //                    }
                        //                });
                        //            });
                        //        });
                        //});
                    }
                });
            },
            async getServiceGroup() {
                let isProcessing = false;
                isProcessing = (this.processing.shippingInfo.servicegroup ?? false);
                if (!isProcessing) {
                    this.processing.shippingInfo.servicegroup = true;
                    this.$nextTick(() => {
                        if (this.customerID > 0) {
                            this.$axios.get(this.$config.config.endpoint.api + `/services/facility/servicegroup/customer/id/${this.customerID}`.replaceAll("//", "/")).then((result) => {
                                this.source.shippingInfo.serviceGroupDS.result = result?.data?.result ?? [];
                                //this.$nextTick(() => {
                                //    if ((this.companySettingRecord?.id ?? 0) == 0)
                                //        this.getSetting()
                                //    this.manageDefaultSetting();
                                //});
                            });
                        }
                    }).finally(() => {
                        this.processing.shippingInfo.servicegroup = false;
                    });
                }
            },
            async getConsignmentProvider(isReturn) {
                let isProcessing = false;
                isProcessing = !isReturn ? (this.processing.shippingInfo.provider ?? false) : (this.processing.returnInfo.provider ?? false);
                if (!isProcessing) {
                    if (!isReturn) {
                        this.source.shippingInfo.services.result = [];
                        this.processing.shippingInfo.provider = true;
                    }
                    else {
                        this.source.returnInfo.services.result = [];
                        this.processing.returnInfo.provider = true;
                    }
                    this.$nextTick(() => {
                        if (isReturn === null || isReturn === undefined)
                            isReturn = false
                        if ((this.packages?.length ?? 0) > 0) {
                            let shippingType = isReturn ? 'RETURNS' : 'SHIPPING';
                            let apiRequest = this.apiProviderRequest;
                            apiRequest.Request.ShippingType = shippingType;
                            this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/serviceprovider`.replaceAll("//", "/"), apiRequest).then((result) => {
                                var warningErrors = "";
                                this.model.shippingInfo.carrier.error = null;
                                if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                    (result.data.Errors ?? []).forEach((x) => {
                                        warningErrors += `<li><b>${x.Source} ${x.Severity}</b>: ${x.Message} </li> `;
                                    });
                                }
                                if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                    (result.data.Faults ?? []).forEach((x) => {
                                        warningErrors += `<li><b>${x.Source} ${x.Severity}</b>: ${x.Message}</li> `;
                                    });
                                }

                                if ((warningErrors ?? "").length > 0) {
                                    //this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Service Failed!', detail: warningErrors, life: 30000 });
                                    this.model.shippingInfo.carrier.error = ("<ul>" + warningErrors + "</ul>");
                                }
                                else if (((result?.data?.Response?.Customers ?? [])[0]?.Carriers ?? []).length <= 0) {
                                    this.model.shippingInfo.carrier.error = "No valid carrier found.";
                                }

                                if (!isReturn) {
                                    if ((result?.data?.Response?.Customers ?? []).length > 0) {
                                        let carrierIndex = 0;
                                        let availServiceIndex = 0;
                                        let availOptionIndex = 0;
                                        let customer = result?.data?.Response?.Customers[0];
                                        let serviceResult = customer?.Carriers ?? [];
                                        (serviceResult ?? []).forEach(x => {
                                            carrierIndex++;
                                            x.Id = carrierIndex;
                                            x.CarrierCode = x.Code,
                                            x.CarrierName = x.Name
                                            x.Text = '[' + x.Code + '] ' + x.Name;
                                            (x.AvailableServices ?? []).forEach(av => {
                                                availServiceIndex++;
                                                av.Id = availServiceIndex;
                                                av.Text = '[' + av.Code + '] ' + av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                                av.Logo = x.Logo;

                                            });
                                            (x.AvailableOptions ?? []).forEach(av => {
                                                availOptionIndex++;
                                                av.Id = availOptionIndex;
                                                av.Text = '[' + av.Code + '] ' + av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                            });
                                        });
                                        this.source.shippingInfo.providers.result = serviceResult;
                                        //this.$nextTick(() => {
                                        //    let i = 100;
                                        //    do {
                                        //        if ((this.companySettingRecord?.companyID == this.customer?.id)) {
                                        //            i = -1000;
                                        //        }
                                        //        this.getSetting();
                                        //        i--;
                                        //    } while ((this.companySettingRecord?.companyID != this.customer?.id) && i > 0);
                                        //    setTimeout(() => {
                                        //        if ((this.companySettingRecord?.companyID == this.customer.id)) {
                                        //            let defaultProvider = (this.source.companyInfo.accountCustomer ?? []).filter(x => x.id == this.companySettingRecord.default_AccountCustomerID)[0];
                                        //            if (defaultProvider != null && defaultProvider != undefined) {
                                        //                this.source.shippingInfo.carrier.serviceProviderAccountID = (this.source.shippingInfo.providers.result ?? []).filter(x => x.CarrierCode == defaultProvider.code)[0]?.Id ?? null;
                                        //            }
                                        //        }
                                        //    }, 1000);
                                            
                                        //});
                                    }
                                }
                                else {
                                    if ((result?.data?.Response?.Customers ?? []).length > 0) {
                                        let carrierIndex = 0;
                                        let availServiceIndex = 0;
                                        let availOptionIndex = 0;
                                        let customer = result?.data?.Response?.Customers[0];
                                        let serviceResult = customer?.Carriers ?? [];
                                        (serviceResult ?? []).forEach(x => {

                                            carrierIndex++;
                                            x.Id = carrierIndex;
                                            x.CarrierCode = x.Code,
                                                x.CarrierName = x.Name
                                            x.Text = '[' + x.Code + '] ' + x.Name;
                                            (x.AvailableServices ?? []).forEach(av => {
                                                availServiceIndex++;
                                                av.Id = availServiceIndex;
                                                av.Text = '[' + av.Code + '] ' + av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                                av.Logo = x.Logo;
                                            });
                                            (x.AvailableOptions ?? []).forEach(av => {
                                                availOptionIndex++;
                                                av.Id = availOptionIndex;
                                                av.Text = av.Name;
                                                av.ParentID = x.Id;
                                                av.ParentText = x.Text;
                                                av.ParentCode = x.CarrierCode;
                                                av.ParentText = x.Text;
                                            });
                                        });
                                        this.source.returnInfo.providers.result = serviceResult;
                                    }
                                }

                                if (!isReturn) {
                                    this.processing.shippingInfo.provider = false;
                                }
                                else {

                                    this.processing.returnInfo.provider = false;
                                }
                            })
                                .catch(() => {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.provider = false;
                                    }
                                    else {

                                        this.processing.returnInfo.provider = false;
                                    }
                                })
                                .finally(() => {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.provider = false;
                                    }
                                    else {

                                        this.processing.returnInfo.provider = false;
                                    }
                                });
                        }
                        else {
                            if (!isReturn) {
                                this.processing.shippingInfo.provider = false;
                            }
                            else {

                                this.processing.returnInfo.provider = false;
                            }
                        }
                    });
                }
            },
            async getConsignmentService(isReturn) {
                let isProcessing = false;
                isProcessing = !isReturn ? (this.processing.shippingInfo.product ?? false) : (this.processing.returnInfo.product ?? false);
                if (!isProcessing) {
                    if (!isReturn) {
                        this.processing.shippingInfo.product = true;
                        this.source.shippingInfo.services.result = [];
                    }
                    else {
                        this.source.returnInfo.services.result = [];
                        this.processing.returnInfo.product = true;
                    }
                    this.$nextTick(() => {
                        if (isReturn === null || isReturn === undefined)
                            isReturn = false
                        let apiRequest = this.apiServiceRequest;
                        if ((apiRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                            setTimeout(() => { 
                                let shippingType = isReturn ? 'RETURNS' : 'SHIPPING';
                                let apiRequest = this.apiServiceRequest;
                                apiRequest.Request.ShippingType = shippingType;
                                apiRequest.Request.Provider.Carrier = (isReturn ? this.ReturnCarrierFilter : this.ShippingCarrierFilter) ?? null;
                                if (apiRequest.Request.Provider.Carrier) {
                                    this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/service?additionalinfo=quote`.replaceAll("//", "/"), apiRequest).then((result) => {
                                        var warningErrors = "";
                                        this.model.shippingInfo.product.error = null;
                                        if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                            (result.data.Errors ?? []).forEach((x) => {
                                                warningErrors += `<li><b>${x.Source} ${x.Severity}</b>: ${x.Message} </li> `;
                                            });
                                        }
                                        if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                            (result.data.Faults ?? []).forEach((x) => {
                                                warningErrors += `<li><b>${x.Source} ${x.Severity}</b>: ${x.Message}</li> `;
                                            });
                                        }

                                        if ((warningErrors ?? "").length > 0) {
                                            //this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Service Failed!', detail: warningErrors, life: 30000 });
                                            this.model.shippingInfo.product.error =  ("<ul>" +warningErrors + "</ul>");
                                        }
                                        else if ((result?.data?.Response?.Data ?? []).length <= 0) {
                                            this.model.shippingInfo.product.error = "No valid service found.";
                                        }
                                        if (!isReturn) {
                                            if ((result?.data?.Response?.Data ?? []).length > 0) {
                                                let carrierIndex = 0;
                                                let availServiceIndex = 0;
                                                let availOptionIndex = 0;
                                                let serviceResult = result.data?.Response?.Data ?? [];
                                                //this.source.shippingInfo.services.result = result.data.Response.Data;
                                                (serviceResult ?? []).forEach(x => {
                                                    carrierIndex++;
                                                    x.Id = carrierIndex;
                                                    x.Text = '[' + x.CarrierCode + '] ' + x.CarrierName;
                                                    (x.AvailableServices ?? []).forEach(av => {
                                                        availServiceIndex++;
                                                        av.Id = availServiceIndex;
                                                        av.Text = '[' + av.Code + '] ' + av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                        av.Logo = x.Logo;

                                                    });
                                                    (x.AvailableOptions ?? []).forEach(av => {
                                                        availOptionIndex++;
                                                        av.Id = availOptionIndex;
                                                        av.Text = '[' + av.Code + '] ' + av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                    });
                                                });
                                                this.source.shippingInfo.services.result = serviceResult;
                                            }
                                        }
                                        else {
                                            if ((result?.data?.Response?.Data ?? []).length > 0) {
                                                let carrierIndex = 0;
                                                let availServiceIndex = 0;
                                                let availOptionIndex = 0;
                                                let serviceResult = result.data?.Response?.Data ?? [];
                                                //this.source.returnInfo.services.result = result.data.Response.Data;
                                                (serviceResult ?? []).forEach(x => {
                                                    carrierIndex++;
                                                    x.Id = carrierIndex;
                                                    x.Text = '[' + x.CarrierCode + '] ' + x.CarrierName;
                                                    (x.AvailableServices ?? []).forEach(av => {
                                                        availServiceIndex++;
                                                        av.Id = availServiceIndex;
                                                        av.Text = '[' + av.Code + '] ' + av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                        av.Logo = x.Logo;

                                                    });
                                                    (x.AvailableOptions ?? []).forEach(av => {
                                                        availOptionIndex++;
                                                        av.Id = availOptionIndex;
                                                        av.Text = av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                    });
                                                });
                                                this.source.returnInfo.services.result = serviceResult;
                                            }
                                        }
                                        if (!isReturn) {
                                            this.processing.shippingInfo.product = false;
                                        }
                                        else {

                                            this.processing.returnInfo.product = false;
                                        }
                                    })
                                        .catch(() => {
                                            
                                        })
                                        .finally(() => {
                                            if (!isReturn) {
                                                this.processing.shippingInfo.product = false;
                                            }
                                            else {

                                                this.processing.returnInfo.product = false;
                                            }
                                        });
                                        
                                }
                                else {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.product = false;
                                    }
                                    else {

                                        this.processing.returnInfo.product = false;
                                    }
                                    }
                            }, 500);
                        }
                        else {
                            if (!isReturn) {
                                this.processing.shippingInfo.product = false;
                            }
                            else {

                                this.processing.returnInfo.product = false;
                            }
                        }
                    });
                }
            },
            async getAccessPoints(isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                let isProcessing = false;
                isProcessing = !isReturn ? (this.processing.shippingInfo.accesspoint ?? false) : (this.processing.returnInfo.accesspoint ?? false);
                if (!isProcessing) {
                    let isAllowAccessPoint = false;
                    if (!isReturn) {
                        this.processing.shippingInfo.accesspoint = true;
                        isAllowAccessPoint = (this.model?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false);
                    }
                    else {
                        this.processing.returnInfo.accesspoint = true;
                        isAllowAccessPoint = (this.model?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false);
                    }
                    if (isAllowAccessPoint) {
                        this.$nextTick(() => {
                            let apiRequest = this.apiAccessPointRequest;
                            if ((this.apiServiceRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                                apiRequest.Request.Carrier = (!isReturn ? this.model.shippingInfo?.carrier?.code : this.model.returnInfo?.carrier?.code);
                                if (apiRequest.Request.Carrier) {
                                    this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/tools/accesspoint`.replaceAll("//", "/"), apiRequest).then((result) => {
                                        var warningErrors = "";
                                        this.model.shippingInfo.accesspoint.error = null;
                                        if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                            (result.data.Errors ?? []).forEach((x) => {
                                                warningErrors += `<li><b>${x.Source} ${x.Severity}</b>: ${x.Message} </li> `;
                                            });
                                        }
                                        if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                            (result.data.Faults ?? []).forEach((x) => {
                                                warningErrors += `<li><b>${x.Source} ${x.Severity}</b>: ${x.Message}</li> `;
                                            });
                                        }

                                        if ((warningErrors ?? "").length > 0) {
                                            //this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Service Failed!', detail: warningErrors, life: 30000 });
                                            this.model.shippingInfo.accesspoint.error = ("<ul>" + warningErrors + "</ul>");
                                        }
                                        else if ((result?.data?.Response ?? []).length <= 0) {
                                            this.model.shippingInfo.accesspoint.error = "No valid access point found.";
                                        }
                                        if (!isReturn) {
                                            if ((result?.data?.Response ?? []).length > 0) {
                                                this.source.shippingInfo.accesspointLocations = result.data?.Response ?? [];
                                            }
                                        }
                                        else {
                                            if ((result?.data?.Response ?? []).length > 0) {
                                                this.source.returnInfo.accesspointLocations = result.data?.Response ?? [];
                                            }
                                        }

                                        if (!isReturn) {
                                            this.processing.shippingInfo.accesspoint = false;
                                        }
                                        else {

                                            this.processing.returnInfo.accesspoint = false;
                                        }

                                    })
                                        .catch(() => {
                                            if (!isReturn) {
                                                this.processing.shippingInfo.accesspoint = false;
                                            }
                                            else {

                                                this.processing.returnInfo.accesspoint = false;
                                            }

                                        })
                                        .finally(() => {
                                            if (!isReturn) {
                                                this.processing.shippingInfo.accesspoint = false;
                                            }
                                            else {
                                                this.processing.returnInfo.accesspoint = false;
                                            }
                                        });
                                }
                                else {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.accesspoint = false;
                                    }
                                    else {

                                        this.processing.returnInfo.accesspoint = false;
                                    }
                                }
                            }
                            else {
                                if (!isReturn) {
                                    this.processing.shippingInfo.accesspoint = false;
                                }
                                else {

                                    this.processing.returnInfo.accesspoint = false;
                                }

                            }
                        });
                    }
                }
            },
            async onSelectServiceGroup(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false

                if (!isReturn) {
                    this.source.shippingInfo.serviceGroup.serviceGroupID = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.serviceGroup.code = event?.record?.code ?? null;
                        this.source.shippingInfo.serviceGroup.text = event?.record?.code ?? null;
                    }
                    else {
                        this.source.shippingInfo.serviceGroup.code = null;
                        this.source.shippingInfo.serviceGroup.text = null;
                    }
                    
                }
                else {
                    this.source.returnInfo.serviceGroup.serviceGroupID = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.serviceGroup.code = event?.record?.code ?? null;
                        this.source.returnInfo.serviceGroup.text = event?.record?.code ?? null;
                    }
                    else {
                        this.source.returnInfo.serviceGroup.code = null;
                        this.source.returnInfo.serviceGroup.text = null;
                    }
                }
            },
            async onSelectCarrier(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false

                if (!isReturn) {
                    this.source.shippingInfo.carrier.serviceProviderAccountID = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.carrier.code = event?.record?.CarrierCode ?? null;
                        this.source.shippingInfo.carrier.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.shippingInfo.carrier.code =  null;
                        this.source.shippingInfo.carrier.text =  null;
                        
                    }
                }
                else {
                    this.source.returnInfo.carrier.serviceProviderAccountID = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.carrier.code = event?.record?.CarrierCode ?? null;
                        this.source.returnInfo.carrier.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.returnInfo.carrier.code = null;
                        this.source.returnInfo.carrier.text = null;
                    }
                }
            },
            async onSelectProduct(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    if (event?.value !== null && event?.value !== undefined) {
                        this.source.shippingInfo.product.serviceProviderProductAccountID = event.value;
                        if (this.source.shippingInfo.carrier.serviceProviderAccountID === null) {
                            this.source.shippingInfo.carrier.serviceProviderAccountID = event.record.ParentID;
                            this.source.shippingInfo.carrier.code = event.record.ParentCode;
                            this.source.shippingInfo.carrier.text = event.record.ParentText;
                        }
                        this.source.shippingInfo.product.code = event.record?.Code ?? null;
                        this.source.shippingInfo.product.text = event.record?.Text ?? null;
                        this.source.shippingInfo.product.configuration = event.record?.Configuration ?? null;
                       // this.source.shippingInfo
                        //this.source.shippingInfo.product.apiCode = event.record.APICode;

                    }
                    else {
                        this.source.shippingInfo.product.serviceProviderProductAccountID = null;
                        if (this.source.shippingInfo.carrier.serviceProviderAccountID == null) {
                            this.source.shippingInfo.carrier.code = null;
                            this.source.shippingInfo.carrier.text = null;
                        }
                        this.source.shippingInfo.product.code = null;
                        this.source.shippingInfo.product.text = null;
                        this.source.shippingInfo.product.configuration = null;
                        
                        //this.source.shippingInfo.product.apiCode = null;

                    }
                }
                else {
                    if (event?.value !== null && event?.value !== undefined) {
                        this.source.returnInfo.product.serviceProviderProductAccountID = event.value;
                        if (this.source.returnInfo.carrier.serviceProviderAccountID === null) {
                            this.source.returnInfo.carrier.serviceProviderAccountID = event.record.ParentID;
                            this.source.returnInfo.carrier.code = event.record.ParentCode;
                            this.source.returnInfo.carrier.text = event.record.ParentText;
                        }
                        this.source.returnInfo.product.code = event.record?.Code ?? null;
                        this.source.returnInfo.product.text = event.record?.Text ?? null;
                        this.source.returnInfo.product.configuration = event.record?.Configuration ?? null;
                        this.source.returnInfo
                        this.source.returnInfo.product.apiCode = event.record.APICode;
                    }
                    else {
                        this.source.returnInfo.product.serviceProviderProductAccountID = null;
                        if (this.source.returnInfo.carrier.serviceProviderAccountID == null) {
                            this.source.returnInfo.carrier.code = null;
                            this.source.returnInfo.carrier.text = null;
                        }
                        this.source.returnInfo.product.code = null;
                        this.source.returnInfo.product.text = null;
                        this.source.returnInfo.product.apiCode = null;
                        this.source.returnInfo.product.configuration = null;
                    }
                }
            },
            async onSelectOption(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    this.source.shippingInfo.option.serviceProviderOptionAccountID = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.option.code = event?.record?.Code ?? null;
                        this.source.shippingInfo.option.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.shippingInfo.option.code = null;
                        this.source.shippingInfo.option.text = null;
                    }
                }
                else {
                    this.source.returnInfo.option.serviceProviderOptionAccountID = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.option.code = event?.record?.Code ?? null;
                        this.source.returnInfo.option.text = event?.record?.Text ?? null;
                    }
                    else {
                        this.source.returnInfo.option.code = null;
                        this.source.returnInfo.option.text = null;
                    }
                }
            },
            async onSelectAccessPoint(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    this.source.shippingInfo.accesspoint.code = event?.value;
                    if (event?.value) {
                        this.source.shippingInfo.accesspoint.code = event?.record?.Code ?? null;
                        this.source.shippingInfo.accesspoint.text = event?.record?.FullDescription ?? null;
                        this.source.shippingInfo.accesspoint.record = event?.record ?? null;
                    }
                    else {
                        this.source.shippingInfo.accesspoint.code = null;
                        this.source.shippingInfo.accesspoint.text = null;
                        this.source.shippingInfo.accesspoint.record = null;
                    }
                }
                else {
                    this.source.returnInfo.accesspoint.code = event?.value;
                    if (event?.value) {
                        this.source.returnInfo.accesspoint.code = event?.record?.Code ?? null;
                        this.source.returnInfo.accesspoint.text = event?.record?.FullDescription ?? null;
                        this.source.returnInfo.accesspoint.record = event?.record ?? null;
                    }
                    else {
                        this.source.returnInfo.accesspoint.code = null;
                        this.source.returnInfo.accesspoint.text = null;
                        this.source.returnInfo.accesspoint.record = null;
                    }
                }
            },
            onPopulateAlternateAddress(event, isReturn) {
                if (isReturn === null || isReturn === undefined)
                    isReturn = false
                if (!isReturn) {
                    this.model.shippingInfo.accesspoint.alternateDelivery.address.fullDescription = (event.address1 ?? '') + (event.address2 ? (', ' + event.address2) : '') + (event.address3 ? (', ' + event.address3) : '') + (event.city ? (', ' + event.city) : '') + (event.state ? (', ' + event.state) : '') + (event.postalCode ? (', ' + event.postalCode) : '') + (event.countryID ? (', ' + this.$vm.$countries.filter(x => x.id === event.countryID)[0]?.code) : '')
                }
                else {
                    this.model.returnInfo.accesspoint.alternateDelivery.address.fullDescription = (event.address1 ?? '') + (event.address2 ? (', ' + event.address2) : '') + (event.address3 ? (', ' + event.address3) : '') + (event.city ? (', ' + event.city) : '') + (event.state ? (', ' + event.state) : '') + (event.postalCode ? (', ' + event.postalCode) : '') + (event.countryID ? (', ' + this.$vm.$countries.filter(x => x.id === event.countryID)[0]?.code) : '')
                }
            },
            onAllocateConfirm(_module) {
                let _self = this;
                _self.action.processing = true;
                if (_module === 'consignment') {
                    this.manage().then((recordResponse) => {
                        if (recordResponse.status === 200) {
                            if (recordResponse.data.Status === "SUCCESS") {
                                this.action.processing = true;
                                this.allocate().then((allocResponse) => {
                                    this.action.processing = false;
                                    if (allocResponse.status === 200) {
                                        try {
                                            var warningErrors = "";
                                            if (allocResponse.data.Errors !== null && allocResponse.data.Errors !== undefined) {
                                                (allocResponse.data.Errors ?? []).forEach((x) => {
                                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                                });
                                            }
                                            if (allocResponse.data.Faults !== null && allocResponse.data.Faults !== undefined) {
                                                (allocResponse.data.Faults ?? []).forEach((x) => {
                                                    warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                                });
                                            }
                                            if (warningErrors.length > 0) {
                                                _self.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: warningErrors, life: 10000 });
                                                this.$emit('is-refresh');
                                            }
                                            else if ((allocResponse?.data?.Response?.PackageReferences?.length ?? 0) > 0) {
                                                _self.getEntityRecord(this.model?.mask).then(() => {
                                                    this.dialog.allocate.show = false;
                                                    _self.$toast.add({ severity: 'success', summary: 'Record Allocate Success!', detail: 'The record was allocated successfully.', life: 6000 });
                                                    _self.$router.push({ name: 'consignment-edit', params: { mask: _self.consignmentRecord.mask, allocated: true } });
                                                    //setTimeout(() => _self.$emit("is-allocate", _self.consignmentRecord), 100);
                                                })
                                            }
                                        }
                                        catch {
                                            this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: allocResponse.data.Status, life: 6000 });
                                            this.$emit('is-refresh');
                                        }
                                    }
                                }).catch((err) => {
                                    this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 10000 });
                                    this.$emit('is-refresh');
                                });
                            }
                            else {
                                let errorMessage = "";
                                if (recordResponse.data.Errors !== null && recordResponse.data.Errors !== undefined) {
                                    (recordResponse.data.Errors ?? []).forEach((x) => {
                                        errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                    });
                                }
                                if (recordResponse.data.Faults !== null && recordResponse.data.Faults !== undefined) {
                                    (recordResponse.data.Faults ?? []).forEach((x) => {
                                        errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                    });
                                }

                                if ((errorMessage ?? "").length > 0) {
                                    _self.$toast.add({ severity: 'error', summary: 'Manage Consignment Failed!', detail: errorMessage, life: 30000 });
                                    this.$emit('is-refresh');
                                }
                                _self.action.processing = false;

                            }
                        }
                        else {
                            let errorMessage = "";
                            if (recordResponse.data.Errors !== null && recordResponse.data.Errors !== undefined) {
                                (recordResponse.data.Errors ?? []).forEach((x) => {
                                    errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }
                            if (recordResponse.data.Faults !== null && recordResponse.data.Faults !== undefined) {
                                (recordResponse.data.Faults ?? []).forEach((x) => {
                                    errorMessage += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                });
                            }

                            if ((errorMessage ?? "").length > 0) {
                                _self.$toast.add({ severity: 'error', summary: 'Manage Consignment Failed!', detail: errorMessage, life: 30000 });
                                this.$emit('is-refresh');
                            }
                            _self.action.processing = false;
                        }
                    }).catch(() => {
                        _self.action.processing = false;
                        this.$emit('is-refresh');
                        //this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: err, life: 6000 });
                    });
                }
                else if (_module === 'salesOrder') {
                    this.allocate_SO().then((allocResponse) => {
                        if (allocResponse.status === 200) {
                            try {
                                var warningErrors = "";
                                if (allocResponse.data.Errors !== null && allocResponse.data.Errors !== undefined) {
                                    (allocResponse.data.Errors ?? []).forEach((x) => {
                                        warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                    });
                                }
                                if (allocResponse.data.Faults !== null && allocResponse.data.Faults !== undefined) {
                                    (allocResponse.data.Faults ?? []).forEach((x) => {
                                        warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                    });
                                }
                                if (warningErrors.length > 0) {
                                    _self.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: warningErrors, life: 10000 });
                                }
                                else if ((allocResponse?.data?.Data?.ConsignmentReference?.length ?? 0) > 0) {
                                    this.dialog.allocate.show = false;
                                    this.$emit('is-refresh');
                                }
                            }
                            catch {
                                this.$toast.add({ severity: 'error', summary: 'Record Allocate Failed!', detail: allocResponse.data.Status, life: 6000 });
                            }
                        }
                        
                    }).catch((err) => {
                        console.log(err);
                        this.$toast.add({ severity: 'error', summary: 'Catch Record Allocate Failed!', detail: err, life: 10000 });
                        
                    }).finally(() => {
                        this.action.processing = false;
                    });
                }
               

               
            },
            onProduct_ShippingInfoReset() {
                this.$nextTick(() => {
                    this.model.shippingInfo.product.code = null;
                    this.model.shippingInfo.product.text = null;
                    this.model.shippingInfo.product.configuration = null;
                    this.model.shippingInfo.product.error = null;
                    this.model.shippingInfo.option.code = null;
                    this.model.shippingInfo.option.text = null;
                    this.model.shippingInfo.accesspoint.code = null;
                    this.model.shippingInfo.accesspoint.text = null;
                    this.model.shippingInfo.accesspoint.record = null;
                });
            },
            onReset() {
                this.$nextTick(() => {
                    this.getAccountCustomer().then(() => {
                        this.getServiceGroup().then(() => {
                            if (this.source.shippingInfo.serviceGroup.serviceGroupID == null) {
                                this.getSetting();
                            }
                        });
                        this.source.shippingInfo.providers.isBinded = false;
                        this.source.returnInfo.providers.isBinded = false;
                        if (((this.model?.packages?.length ?? 0) === 0) && ((this.packages?.length ?? 0) === 0)) {
                            this.getPackages();
                        }
                        this.rebindAllocationCarriers();
                    });
                    
                });
            }
        },
        async created() {
            //this.model.request_CollectionDate = new Date();
            //this.model.request_CollectionDateValue = new Date();
            //this.model.request_ReadyTime = new Date(2018, 1, 1, 16, 0, 0);
            //this.model.request_ReadyTimeValue = new Date(2018, 1, 1, 16, 0, 0);
            //this.model.request_ClosingTime = new Date(2018, 1, 1, 18, 0, 0);
            //this.model.request_ClosingTimeValue = new Date(2018, 1, 1, 18, 0, 0);

        },
        mounted() {
            this.$nextTick(() => {
                //this.model = this.$props.formData;
                //this.model.request_CollectionDate = new Date();
                //this.model.request_CollectionDateValue = new Date();
                //this.model.request_ReadyTime = new Date(2018, 1, 1, 16, 0, 0);
                //this.model.request_ReadyTimeValue = new Date(2018, 1, 1, 16, 0, 0);
                //this.model.request_ClosingTime = new Date(2018, 1, 1, 18, 0, 0);
                //this.model.request_ClosingTimeValue = new Date(2018, 1, 1, 18, 0, 0);
                //this.$nextTick(() => {
                   // this.getPackages();
                  //  ///this.getConsignmentService();
                //});
            });

            this.$nextTick(() => {
                this.getServiceGroup().then(() => {
                    if (this.shippingServiceName !== null) {
                        setTimeout(() => {
                            this.model.shippingInfo.serviceGroup.code = (this.shippingInfoServiceGroupDS ?? []).filter(x => x.code.toUpperCase() == this.shippingServiceName.toUpperCase())[0]?.code ?? this.shippingInfoServiceGroupDS.filter(x => x.name.toUpperCase() == this.shippingServiceName.toUpperCase())[0]?.code;
                        }, 3000);
                    }
                });
                this.getConsignmentProvider();
                // this.onReset();
            });
           
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>