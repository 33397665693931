<template>
    <div style="width: 100%">
        <h4 style="margin-bottom: 2rem;">NOTIFICATION DETAILS</h4>
        <form-input id="ErrorRecipients" v-model="model.failed" :required="true" :disabled="disabled" :v$="v$" vID="failed" :showCopy="false" v-lowercase />
        <form-input id="SuccessRecipients" v-model="model.success" :disabled="disabled" :v$="v$" vID="success" :showCopy="false" v-lowercase />
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    export default {
        name: "StoreNotificationForm",
        setup: () => ({ v$: useVuelidate({ $scope: true }) }),
        props: {
            modelValue: { type: Object, default: () => { } },
            modelInvalid: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                model: {
                    failed: null,
                    success: null
                }
            };
        },
        watch: {
            modelValue() { this.model = this.modelValue ?? { failed: null, success: null } },
            model() { this.onSync(); },
            v$() { this.$emit("update:modelInvalid", this.v$.$error || this.v$.$invalid); }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let state = {
                model: {
                    failed: { $autoDirty: true, email, required },
                    success: { $autoDirty: true, email }
                }
            };
            return state;
        },
        methods: {
            onSync() {
                this.$emit("update:modelValue", this.model);
                this.$emit("is-sync", this.model);
            }
        },
        created() {
            if (this.modelValue)
                this.model = this.modelValue;
        }
    }
</script>